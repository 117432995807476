import React, { useRef } from 'react';
import * as FeatherIcons from 'react-icons/fi';
import * as Ionicons from 'react-icons/io5'; 
import * as MaterialIcons from 'react-icons/md';
import * as HeroIcons from 'react-icons/hi';
import * as IconoirIcons from 'iconoir-react';

import { getIconName, iconMap } from './iconMap'; /* replace to 'import { getIconName, iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap)

type IconProps = {
    icon?: string;
    library?: 'feather' | 'ionic' | 'material' | 'heroicons' | 'iconoir';
    color?: string;
    size?: 'auto' | string;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    attributes?: React.HTMLAttributes<HTMLDivElement>;
    listeners?: React.DOMAttributes<HTMLDivElement>;
    junoProps?: any; /* remove line */
  };

export default function Icon({
        icon = 'add',
        library,
        color,
        size = 'auto',
        className = '',
        onClick,

        attributes,
        listeners, 
        junoProps, /* remove line */
      }: IconProps) {

    const { junoAttributes, outlineStyle} = junoProps || {} /* remove line */

    const colorStyles = (color == 'auto' || color == 'none' || !color) ? '' : `text-${color}`
    let wrapperClasses = `${colorStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    const iconRef = useRef(null)
    const globalIconSet = iconRef?.current ? getComputedStyle(iconRef.current).getPropertyValue('--iconset').trim() : 'iconoir'
    
    const fallbackIconSet = 'iconoir'
    const libraryMap = {
        feather: FeatherIcons,
        ionic: Ionicons,
        material: MaterialIcons,
        heroicons: HeroIcons,
        iconoir: IconoirIcons,
    };
    
    const defaultIconSet = libraryMap[globalIconSet] ? globalIconSet : fallbackIconSet;
    
    const useLibrary =  (!library || libraryMap[library] === undefined) ? defaultIconSet : library;
    
    const mappedIconName = getIconName(icon, useLibrary);
    
    
    const IconLibrary = libraryMap[useLibrary];
    
    const IconComponent = IconLibrary[mappedIconName] || null

    const sizeInt = parseInt(size, 10);
    
    if (!IconComponent) return null;
    const iconSize = size == 'auto' ? `1.5em` : sizeInt
    return (
        <div
        className={wrapperClasses}
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        /* replace to 'onClick={onClick}' */
        >
        <div className='absolute w-0 h-0 invisible' ref={iconRef} />
        <IconComponent size={iconSize} color={color} className={className} />
        </div>
    );
}



/* ignore rest */

Icon.definitions = {
    apiName: 'Icon',
    displayName: 'Icon',
    description: 'Dynamic icon that can switch between libraries',
    ai_instructions: 'icon component that can switch between libraries',
    type: 'media',
    acceptedChildren: 'none',
    relativeSize: 'small',
    status: 'stable', 
    package: 'Starter',
    propDefinitions: {
        icon: {
            type: 'icon',
            options: allIconNames,
            displayName: 'Icon',
            default: 'add'
        },
        color: {
            type: 'colors',
            options: ['base-100', 'base-200', 'base-300', 'primary', 'accent', 'base-content', 'info', 'warning', 'success', 'error', ],
            displayName: 'Color',
            default: null,
        },
        size: {
            type: 'oneOf',
            options: ['auto', '12px', '16px', '20px', '24px', '32px'],
            displayName: 'Size',
            default: 'auto'
        },
        library: {
            type: 'oneOf', 
            options: ['feather', 'ionic', 'material', 'heroicons', 'iconoir'], 
            displayName: 'Library',
            ai_instructions: `dont change this prop unless user is requesting a specific library`,
            default: null, 
        }
    }
};

/*
Icon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf(allIconNames)]),
    color: PropTypes.oneOf(['auto', 'base-100', 'base-200', 'base-300', 'primary', 'accent', 'base-content', 'info', 'warning', 'success', 'error']),
    size: PropTypes.oneOf(['auto', '12px', '16px', '20px', '24px', '32px']),
    library: PropTypes.oneOf(['feather', 'ionic', 'material', 'heroicons', 'iconoir']), 
    className: PropTypes.string,
};*/