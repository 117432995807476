import React, { useContext, useEffect, useRef, useState } from 'react';
import * as IconoirIcons from 'iconoir-react';
import { EditorContext } from '../../EditorContext';

export default function ZoomCanvas() {
    const { panState, setPanState } = useContext(EditorContext);
    const zoomLevels = [25, 30, 40, 50, 75, 80, 90, 100, 110, 125, 150, 200];
    const currentZoom = panState.zoom || 100;

    // Find the closest zoom level below and above the current zoom level
    const closestZoomBelow = zoomLevels.reduce((prev, curr) => (curr < currentZoom ? curr : prev), 25);
    const closestZoomAbove = zoomLevels.reduce((prev, curr) => (curr > currentZoom ? (prev === currentZoom ? curr : Math.min(curr, prev)) : prev), currentZoom);

    const canZoomOut = currentZoom > 24;
    const canZoomIn = currentZoom < 200;

    const handleZoomIn = (event) => {
        event.stopPropagation(); // Prevent the event from bubbling up to the dropdown div
        if (canZoomIn) {
            setPanState({ ...panState, zoom: closestZoomAbove, fitCanvas: false });
        }
    };

    const handleZoomOut = (event) => {
        event.stopPropagation(); // Prevent the event from bubbling up to the dropdown div
        if (canZoomOut) {
            setPanState({ ...panState, zoom: closestZoomBelow, fitCanvas: false});
        }
    };

    const handleZoomTo = (zoomLevel) => {
        setPanState({ ...panState, zoom: zoomLevel, fitCanvas: false });
    };


    const [isOpen, setIsOpen] = useState(false);  // State to manage dropdown visibility
    
    const dropdownRef = useRef(null);  // Ref to help with detecting outside clicks

    useEffect(() => {
        // Function to handle clicking outside the dropdown
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            // Attach the listener if the dropdown is open
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Clean up
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const fitCanvas = panState?.fitCanvas;

    const viewportWidth = 1440
    const viewportHeight = 900

    const canvasWidth = panState.canvasDimensions?.width
    const canvasHeight = panState.canvasDimensions?.height

    // Calculate scale factors
    const scaleByWidth = canvasWidth / viewportWidth
    const scaleByHeight = canvasHeight / viewportHeight;

    // Choose the smaller scale factor and ensure it does not exceed 1
    const scale = Math.min(scaleByWidth, scaleByHeight, 1);
    const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-normal
    w-[120px] bg-gray-100 text-gray-800 ring-1 ring-gray-300 font-medium rounded-md px-2 py-1 text-2xs z-50 text-center
    -bottom-3 transform translate-y-full transition-all left-1/2 -translate-x-1/2 duration-300`
    // ${!isOpen ? ' hover:bg-white ring-transparent' : 'bg-white ring-base-300 '}  cursor-pointer ring-inset ring-1 hover:ring-base-300
    return (
        <div 
        ref={dropdownRef}
        
        className={`relative h-7 group transition-all duration-150 rounded-lg flex-shrink-0 justify-between flex flex-row items-center font-normal
        select-none
        `}
        
        >
            <span // onClick={() => !isOpen && setPanState({...panState, fitCanvas: !panState.fitCanvas})}
            className='rounded-lg h-full items-center justify-center flex px-2'
            >
            
            {fitCanvas ? Math.round(scale * 100) : Math.round(panState.zoom)}%
            <span className={`${tooltipStyle}`}>
                Viewport 1440x820 scaled to fit canvas
            </span> 
            </span>
            
            {/*<button className={`flex items-center justify-center h-full rounded-full  transition-all`}
            onClick={() => setIsOpen(!isOpen)}
            >
                <IconoirIcons.NavArrowDown height={16} width={16} className={`${isOpen ? 'mt-1' : 'hover:mt-1'} transition-all -ml-1 mr-1  duration-150`} />
            </button>*/}
            {isOpen && (  
                <div className='absolute -bottom-2 translate-y-full min-w-full bg-base-0 right-0 flex flex-col p-1 rounded-xl
                border border-base-300 text-gray-800 font-medium'
                style={{zIndex: 1001}}
                >
                    <button className={`whitespace-nowrap px-3 py-1.5  rounded-lg text-center
                    ${panState.fitCanvas ? 'bg-base-50' : 'hover:bg-base-50'}
                    `}
                    onClick={() => setPanState({...panState, fitCanvas: !panState.fitCanvas})} 
                    >Fit Canvas
                    </button>
                    <button className='whitespace-nowrap px-3 py-1.5 hover:bg-base-100 rounded-lg text-center'
                    onClick={() => handleZoomTo(50)}>
                        Zoom to 50%
                    </button>
                    <button className='whitespace-nowrap px-3 py-1.5 hover:bg-base-100 rounded-lg text-center'
                    onClick={() => handleZoomTo(100)}>
                        Zoom to 100%
                    </button>
                    <button className='whitespace-nowrap px-3 py-1.5 hover:bg-base-100 rounded-lg text-center'
                    onClick={() => handleZoomTo(150)}>
                        Zoom to 150%
                    </button>
                    <div className='flex flex-row items-center justify-center'>
                    <button className={`whitespace-nowrap px-3 py-1.5 rounded-lg flex flex-row gap-2 items-center text-center w-full justify-center
                    ${canZoomOut ? 'hover:bg-base-100' : 'text-gray-400'}`}
                    onClick={(e) => handleZoomOut(e)}>
                        <IconoirIcons.ZoomOut height={16} width={16} />
                    </button>
                    <button className={`whitespace-nowrap px-3 py-1.5 rounded-lg flex flex-row gap-2 items-center text-center w-full justify-center
                    ${canZoomIn ? 'hover:bg-base-100' : 'text-gray-400'}`}
                    onClick={(e) => handleZoomIn(e)}>
                        <IconoirIcons.ZoomIn height={16} width={16} />
                    </button>
                    </div>
                </div>
            )}
        </div>
    );
}
