import { useContext, useEffect, useState, useRef } from "react";
import UserContext from "../../UserContext";
import * as helpers from "../../utilities/helpers";
import { prepareProjectCopy } from "./helpers";
import { ProjectName } from "./ProjectName";
import { ArrowRight, Bookmark, BookmarkSolid, Heart, HeartSolid, MoreHoriz, Star, StarSolid } from "iconoir-react";
import { Logo } from "../../ui-kit/exports/react";

export default function ProjectCard({
  project,
  archiveProject,
  renameProject,
  openProject,
  restoreProject, 
  updateFavoriteStatus,
  addProject
}) {

  const { user } = useContext(UserContext); // Access user from UserContext

  const getUserToken = async () => {
    if (user) {
      return user.token;
    }
    return null; 
  };  
  
  async function duplicateProject() {
    try {
      const token = await getUserToken();
      const response = await fetch('/api/project/copy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({ projectId: project.id }) // Send projectId in the request body
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      
      // Assuming responseData contains the project data
      const copiedProject = prepareProjectCopy(user.id, responseData);
      addProject(copiedProject, null, false); 
  
    } catch (error) {
      console.error('Error duplicating project:', error);
    }
  }

  function handleArchive(e, projectId) {
    e.stopPropagation();

    if (project.is_archived) {
        restoreProject(e, projectId);
    } else {
        archiveProject(e, projectId);
    }
}

function handleFavoriteToggle(e) {
  e.stopPropagation();
  const newFavoriteStatus = !project.is_favorite; // Toggle current favorite status
  updateFavoriteStatus(project.id, newFavoriteStatus); // Call the passed function with the new status
}

  const projectAssets = project?.assets || [];

  const [menuOpen, setMenuOpen] = useState(false);
  // Use effect to manage body class for preventing scroll
  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    // Cleanup to remove the class when component unmounts
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [menuOpen]);
  
  
  
  // sample images for testing locally 
  
  /*const images = [
    "https://storage.googleapis.com/juno-v1.appspot.com/frame-c90700e0-16f6-11ef-8192-07072dbb67fb.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=H88xsOA68q50h%2Fr6%2BCGHSnzTroJifufmKqyGrps7opBP5uT%2BEuabvcr0U4ZsIAc3TAkq%2Fnyq4FWNRXVSs%2FiAeVeTFgrFvoVFEjoPp8RvUk%2BXkwS2nF1sMMpdmDissg5GNm%2BOzQ8I91HD1O9zW7MkaJmsrYdobCblZSxIkFbFMy%2F9IY%2F%2BkAyfAXSGMAJnXBghLmumbo50KOW4Q03hJVQxu4UhSBPgkkbR%2Bv%2FVlsnNEN5YxYvV49W7kGSNy8TjBNensZXvnyShFWYieWO7EPRPgWv80nA2zvBCoBVVMJgF9vrZvxIsYpCOh4taorfEB46g3NO7lz4aKrAvEWcrUMrwMQ%3D%3D&timestamp=1720635731140",
    "https://storage.googleapis.com/juno-v1.appspot.com/frame-439afe00-163a-11ef-bb7a-7b8cf5cdf797.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=NAk1y06e8sqK3KkfDkjx%2FM4J4IfHFP10%2BeHUxfaoBAZHODKFSYi7i6TlFdMsGpoYE%2BDjEnZiCKyKXmmnKbdNZNdyHHXE5XQcp0MET5UC5bv7mV0wS5nvhFXYITYG37rOB%2F6O6xS1ObJT4INOQvO9X2IhdM98XX6lZ136i4bbrL52J3FmDTzS%2BSvDfOig0dvKd4eDffhUeYPMPj14%2Fb382QjJa6sCdBKNUmN4cSq1yxX3pTWEgdMDXkJEoiUs%2BjG6b0WCIwdgWalCtSOKq2HlYgbukwby36uOj4Tr3j603X5dLbpq6VEtTo6Pi6z3z69J5O3F9T60BOjjZTdgKcVUHw%3D%3D&timestamp=1720635580610",
    "https://storage.googleapis.com/juno-v1.appspot.com/frame-1305e850-1896-11ef-92f2-7376a55ef776.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=6eWOn99MVf%2FsUsFGLXavyjJeAu8ebN9PL4qZ8UtZFo1T%2BH3Fvu%2By6z3J%2BTEnbRob4SYmpqJoAqJnqKE%2Fam%2BIBUW8hvoNPEuVHA93Z9%2FOcAEq05%2F1fbiYL5C8AHToMWY75Ur%2BBwFr6kQPgZK7w39cjzdPaDreugamFXdvkBzHK7pVh3uUxaL381cQftQVxoGdiXK6wgKtXYPdHR%2BDYajm3SIq%2FqsnMHfywyyejyk9JoEJxyBIj%2Fqmoj%2FoRLpPn0YwFIpnaSJi4BIwBWROXK6mJBj7daqKRKcXEV%2B7FO23damjWRH0qx0B4Niat9S3uI1rPdch473zwnIhcs5T%2Bj4HcQ%3D%3D&timestamp=1720635519217"
  ] */

  
  const images = project?.frames.filter(frame => frame.is_favorite && !frame.is_archived).map(frame => frame.thumbnail) || [];
  
  return (
    <div className="flex flex-col w-full gap-3 mb-3 relative group">
      <div className={`relative group aspect-video rounded-3xl
      ${menuOpen ? ' bg-base-50 ' : 'group-hover:bg-base-50 '}
      
      overflow-hidden flex flex-col justify-end items-start p-4 transition-all select-none`}
      onClick={(e) => openProject(e, project.id)}>        
      
          { images.length > 1 ? <ImageSlider images={images} /> :
            <OneImage imageUrl={images[0]} />}
          
      </div> 
      <div className="flex flex-row gap-3 items-start justify-start w-full transition-all relative pl-4">
      <div 
      className="w-10 h-10 rounded-xl flex-shrink-0 transition-all duration-75 overflow-hidden"
      >

        <Logo size={'40px'} type='symbol' assets={projectAssets} />
      </div>
      <div className="flex flex-col items-start justify-between flex-grow">
      
          <h2 className="font-medium text-sm">
            <ProjectName project={project} renameProject={renameProject}/>
          </h2>
          <div className="text-xs opacity-60 font-normal">
            created {helpers.timeAgo(project.created_at)}
          </div>
      </div>
      <div className={`absolute right-0 top-0 h-10 flex flex-row gap-1 items-start pl-2 pr-4
      ${menuOpen? 'opacity-100' : 'opacity-0 group-hover:opacity-100'} transition-all duration-0`}>
          
          
          <Menu 
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            project={project}
            duplicateProject={duplicateProject}
            handleArchive={handleArchive}
            openProject={openProject}
            restoreProject={restoreProject}


          />
          <div className="p-1 hover:bg-base-100 rounded-md flex items-center justify-center">
          {project.is_favorite ?
          <StarSolid height={20} width={20} 
             onClick={handleFavoriteToggle}
             className="text-primary"
          />: 
          <Star height={20} width={20}
             onClick={handleFavoriteToggle} />}
          </div>
      </div>
      </div>
    </div>
  );

}

function Menu({
  project,
  menuOpen,
  setMenuOpen, 
  duplicateProject,
  handleArchive,
  restoreProject, 
  openProject,
}) {
  const menuRef = useRef(null);
  
  const actions = [
    {name: 'Open', onClick: e => {e.stopPropagation(); openProject(e, project.id)}},
    {name: 'Duplicate', onClick: e => {e.stopPropagation(); duplicateProject()}},
    project.is_archived ? {name: 'Restore', onClick: e => {e.stopPropagation(); restoreProject(e, project.id)}} : {name: 'Archive', onClick: e => {e.stopPropagation(); handleArchive(e, project.id)}}
    
  ]

  //{name: 'Archive', onClick: e => {e.stopPropagation(); handleArchive(e, project.id)}} 
  useEffect(() => {
    function handleClickOutside(event) {
      
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, setMenuOpen]);

  return (
    <div className={`p-1 
    ${menuOpen ? 'bg-base-100' : 'opacity-0 group-hover:opacity-100 bg-base-0 hover:bg-base-100 '}
    rounded-md flex items-center justify-center relative select-none`} ref={menuRef}>
      <MoreHoriz height={20} width={20} onClick={() => setMenuOpen(!menuOpen)} />
      {menuOpen && 
        <div className="absolute bottom-1 left-1 -translate-x-full w-auto min-w-[100px]
        bg-base-0 ring-[0.5px] ring-base-300 shadow-md shadow-base-100 
        p-1 rounded-lg text-xs" >
          {actions.map((action, index) => (
            <div key={index} className="hover:bg-base-100 whitespace-nowrap px-2 py-1 rounded-md" onClick={action.onClick}>
              {action.name}
            </div>
          ))}
        </div>
      }
    </div>
  )
}

const OneImage = ({ imageUrl }) => {

  const noImage = !imageUrl || imageUrl === '';
  
  return (
    <div
      className="relative w-full h-full overflow-hidden
      w-full h-full bg-base-50 hover:bg-base-100 rounded-xl flex flex-col justify-between p-3
        transition-all duration-150 ring-[0.5px] ring-base-300 hover:ring-base-400
        group-hover:scale-[1.01]"
      style={
      !noImage ?  
      {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center'
      } : {}
    }
      >
        <div className="bg-base-100 w-8 h-8 absolute bottom-3 right-3 opacity-0 group-hover:opacity-100 transition-all
        rounded-full duration-150 flex items-center justify-center text-base-500 hover:scale-110 bg-base-0
        ring-[0.5px] ring-base-300 hover:ring-base-400
        drop-shadow-sm
        ">
          <ArrowRight height={16} width={16} className="stroke-[1.6px]"/>
        </div>
    </div>
  );
};


const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    // Preload images
    const preloadImages = () => {
      return Promise.all(
        images.map((src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
          });
        })
      );
    };

    preloadImages()
      .then(() => setLoaded(true))
      .catch((error) => console.error('Error loading images', error));
  }, [images]);

  useEffect(() => {
    if (!loaded || !hovered) return;

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 1000); // Change image every 1 second

    return () => {
      clearInterval(interval);
    };
  }, [loaded, hovered, images.length]);

  return (
    <div
      className="relative w-full h-full overflow-hidden bg-base-100 rounded-xl flex flex-col justify-between p-3
      transition-all duration-150 ring-[0.5px] ring-base-300 hover:ring-base-400
      group-hover:scale-[1.01]"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={
        loaded ?
        {
        backgroundImage: `url(${images[currentImageIndex]})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
      } : {}
    }
    >

      <div className="bg-base-100 w-8 h-8 absolute bottom-3 right-3 opacity-0 group-hover:opacity-100 transition-all
        rounded-full duration-150 flex items-center justify-center text-base-500 hover:scale-110 bg-base-0
        ring-[0.5px] ring-base-300 hover:ring-base-400
        drop-shadow-sm
        ">
          <ArrowRight height={16} width={16} className="stroke-[1.6px]"/>
        </div>
    </div>
  );
};
