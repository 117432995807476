import { useContext, useState, useEffect } from 'react';
import UserContext from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import logo from './juno-new.svg';
import AuthInput from './AuthInput';
import { Button, InputText } from '../../ui-kit/exports/react';

export default function LimitedSignUp() {
  
    return (
      <div className='flex flex-col items-center justify-center h-screen bg-white'>
        <div className='max-w-[480px] w-full bg-white rounded-lg p-6 pb-10 flex flex-col gap-5 text-center text-balance'>
          <img src={logo} alt="Logo" width={240} className='mx-auto mb-5'/>
          <p>
            We are making frequent updates to JUNO and currently cannot support new users. </p>
          <p>
            If you really want to try JUNO, email sam@junodesign.app</p>
          
        </div>
      </div>
    );
  }   








    /*/ CREATE PROJECT FOR USER
        const projectResponse = await fetch('/projects/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Assuming you're using token-based authentication for the /projects/add endpoint
          },
          body: JSON.stringify({
            name: 'Default Project',  // Default project name
            userId: userId,
            ds: '4cf707d6-317b-11ee-a595-ea12fce6e513'  // tailwind
          })
        });
  
        const projectData = await projectResponse.json();
        const projectId = projectData.id;
        
        const tasks = ['']
        navigate(`/project/${projectId}`, { state: { tasks: tasks } }); */