import { useContext, useState } from "react"
import { DnDProvider } from "./dragNdrop/DnDContext"
import DNDPreview from "./dragNdrop/DnDPreview"
import Sidebar from "./sidebar/Sidebar"
import Canvas from "./canvas/Canvas"
import { EditorContext } from "./EditorContext"
import MiniNav from "./minnav/MiniNavTop"
import LeftSide from "./LeftSide"

export default function CanvasView() {
    const { view, streaming } = useContext(EditorContext)
    const [insert, setInsert] = useState(null)
    

    // if i add relative to the wrapper drag insert is off 
    return (<div className={`flex flex-row flex-grow h-full w-full items-start justify-start w-screen gap-2 pr-3`}
    style={{ 
      maxHeight: '100%', 
      width: '100vw',
      maxWidth: '100vw', 
      overflow: 'hidden', 
  }}
  >
    <div className={`${!streaming ? 'hidden' : 'absolute absolute top-0 left-0 w-full h-full bg-current-10'} transition-all duration-150`}
    style={{zIndex: 100}}
    />
    <DnDProvider setInsert={setInsert} insert={insert}> 
    <div className="flex flex-col w-full h-full flex-grow flex-shrink-1 overflow-hidden" 
    style={{
      boxSizing: 'border-box',
      maxHeight: `100%`,

    }}>
      
      <MiniNav />
      <LeftSide />
    
      <Canvas /> 
    
    
    <DNDPreview insert={insert} />
    </div>
    {<Sidebar />}
    </DnDProvider>
    
  </div> )
    
    
  }

  