import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import UserContext from '../../UserContext';
import logo from './juno-new.svg'; // Adjust path as needed
import AuthInput from './AuthInput'; // Reuse your custom AuthInput component
import { Alert, Button, InputText } from '../../ui-kit/exports/react';

export default function LoginPage() {
  const { setUser, setIsAuthenticated } = useContext(UserContext);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setFormErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.email.trim()) errors.email = 'Please provide email';
    if (!formData.password.trim()) errors.password = 'Please provide password';
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    console.log(validationErrors);
    setFormErrors(validationErrors);

    if (Object.keys(validationErrors).length !== 0) return;

    const { email, password } = formData;
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const firebaseUser = userCredential.user;
      const token = await firebaseUser.getIdToken();
      localStorage.setItem('token', token);

      const response = await fetch('/auth/user', { 
        method: 'POST', 
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: firebaseUser.uid })
      });

      const data = await response.json();

      if (data && data.id && data.email) {
        setUser({
          id: data.id,
          firebaseId: firebaseUser.uid,
          email: data.email,
          firstname: data.firstname || null,
          lastname: data.lastname || null,
          token: token
        });
        setIsAuthenticated(true);
        navigate('/');
      } else {
        throw new Error("Response data does not contain expected properties");
      }
    } catch (err) {
      if (err.message == 'Firebase: Error (auth/wrong-password).') {
        setError(`Email and password don't match. Please try again.`);
      } else {
        setError(err.message);
      }
      
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const token = await firebaseUser.getIdToken();
        setUser({
          ...firebaseUser,
          token: token
        });
        setIsAuthenticated(true);
        navigate('/');
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, setUser, setIsAuthenticated, navigate]);

  const inputProps = {email: {
    size: 'large',
    type: 'email',
    name: 'email',
    bgColor: 'base-50',
    hasOutline: false, 
    value: formData.email,
    onChange: handleChange,
    placeholder: 'email@company.com',
    state: formErrors.email ? 'error' : 'default',
    helperText: formErrors.email
  },
  password: {
    size: 'large',
    type: 'password',
    name: 'password',
    bgColor: 'base-50',
    hasOutline: false, 
    value: formData.password,
    onChange: handleChange,
    placeholder: 'password',
    state: formErrors.password ? 'error' : 'default',
    helperText: formErrors.password
  }}

  
  return (
    <div className='flex flex-col items-center justify-between h-screen bg-white p-5'>
      <div></div>
      <div className='max-w-[420px] w-full bg-white rounded-lg p-6 pb-10 flex flex-col gap-5'>
        <img src={logo} alt="Logo" width={220} className='mx-auto mb-10'/>
        <form onSubmit={handleSubmit} className='flex flex-col gap-3'>
          <InputText  {...inputProps.email} />
          <InputText  {...inputProps.password} />
          {error && <p className='text-red-500'>{error}</p>}
          
          <Button
          size={'large'}
          color={'primary'}
          style={'filled'}
          onClick={(e) => handleSubmit(e)}
          text={'Log In'}
          marginTop={'md'}
          />
          {/*
          <Button 
          size={'large'}
          onClick={() => navigate('/register')}
          text={'Create Account'}
          />*/}
          <span className='text-sm opacity-50 text-center w-full mt-5'>
            Sign up is currently invite-only
          </span>
          
          
          
        </form>
      </div>
      <div className='text-base-500'>
      <Button
      size={'medium'}
      style={'link'}
      onClick={() => navigate('/forgot-password')}
      text={'Forgot Password'}
      />
      </div>
    </div>
  );
}
