import { SegmentedSwitch } from '../../../../../ui-kit/exports/react';

export default function SelectAlignItems({ propKey, options, value, onChange, label, direction='flex-col' }) {

    const allOptions = [
      { value: 'start', icon: direction === 'flex-row' ? 'align-top' : 'align-left' },
      { value: 'center', icon: direction === 'flex-row' ? 'align-vert-center' : 'align-hor-center' },
      { value: 'end', icon: direction === 'flex-row' ? 'align-bottom' : 'align-right' },
      { value: 'stretch', icon: direction === 'flex-row' ? 'align-vert-stretch' : 'align-hor-stretch' },
  ]

  return (
    <SegmentedSwitch
        size={'small'}
        width={'full'}
        label={label}
        options={allOptions}
        value={value}
        onChange={(value) => onChange(value)}
      />
  );
};
