import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { EditorContext } from '../EditorContext';
import PropEditor from './prop-editor/PropEditor';
import ThemesPicker from './theme-editor/ThemesPicker';
import Inspector from './inspect/Inspector';
import FrameEditor from './frame-editor/FrameEditor';
import Library from './elements/Library';
import Iconbar from './Iconbar';
import SuperChat from '../chat/Chat';
import { isEqual } from 'lodash';

export default function Sidebar() {
  const { view, setView, streaming,  } = useContext(EditorContext);

  // Calculating width based on visibility
  const widthMapMap = {
    editor: 280,
    assets: 280,
    elements: 280,
    theme: 280,
    chat: 280,
    inspect: 440,
    notes: 280
  }
  const sidebarWidth = view?.rightSide ? widthMapMap[view.rightSide] : 0
  const heightMap = {
    editor: `calc(100% - 12px)`,
    assets: 820,
    elements: 820,
    chat: 820,
    theme: 820,
    notes: 820,
    inspect: `calc(100% - 12px)`,
  }
  
  
  const sidebarVisible = sidebarWidth > 0
  const [portalData, setPortalData] = useState(null)
  function closePortal() {
    setPortalData(null)
  }
  useEffect(() => {
    if (!sidebarVisible) {
      closePortal(); // This will set portalData to null
    }
  }, [sidebarVisible]); 


  useEffect(() => {
    // Determine the desired width based on 'rightSide' condition
    if (view.rightSide === 'inspect') {
      setView({...view, sidebarWidth: 440})
    } else {
      setView({...view, sidebarWidth: 280, showOutlines: false})
    }
  }, [view.rightSide]);

  const closedWidth = 48
  return (
    
    <div className={`relative flex flex-row flex-shrink-0 transition-all text-base-content mt-1
    self-start duration-150 rounded-xl ring-[0.5px] ring-base-300 bg-base-0 shadow-md`}
    style={{
        height: sidebarVisible ? `calc(100% - 12px)` : 'auto',
        maxHeight: heightMap[view.rightSide],
        zIndex: 100,
        minWidth: closedWidth, 
        animation: 'fadeInGrow 200ms ease'
      }}
      >
        
      {/* SIDEBAR */}
      <div 
      className={`h-full flex flex-col flex-shrink-0 transition-all relative rounded-l-xl select-none transition-all duration-75
      overflow-y-scroll
      `}
      style={{ width: sidebarWidth}} >
        
          
        <EditingPage setPortalData={setPortalData}/>
        
        
      </div>
      {<Iconbar sidebarVisible={sidebarVisible} isDisabled={streaming} width={closedWidth} />}
      </div>  
  );
};

function EditingPage({setPortalData}) {
  const { view, selector, 
    handleAction, set, 
    streaming, setStreaming, 
    actionsHistory, 
    effectiveVariables, 
    notifications, setNotifications

   } = useContext(EditorContext);

return (
  <div className={`flex flex-col flex-grow w-full h-full`}>
                    
                
                {view.rightSide === 'editor' && <PropEditor />}
                {view.rightSide === 'notes' && <FrameEditor selector={selector} />}
                {/*view.rightSide === 'layouts' && (<LayoutPicker />)*/}
                {view.rightSide === 'elements' && (<Library setPortalData={setPortalData}/>)}
                {view.rightSide === 'chat' && (<ChatWrapper 
                  selector={selector}
                  handleAction={handleAction}
                  set={set}
                  view={view}
                  streaming={streaming}
                  setStreaming={setStreaming}
                  actionsHistory={actionsHistory}
                  effectiveVariables={effectiveVariables}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  
                
                />)}
                {view.rightSide === 'theme' &&<ThemesPicker />}
                {view.rightSide === 'inspect' && (<Inspector />)}

</div>


)

}

const ChatWrapper = React.memo((props) => {

  const {selector, handleAction, set, view, streaming, setStreaming, actionsHistory, effectiveVariables, notifications, setNotifications} = props
  return (
  <div
      className="w-full h-full overflow-hidden flex flex-col gap-2 p-3"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <SuperChat 
        selector={selector}
        handleAction={handleAction}
        set={set}
        view={view}
        streaming={streaming}
        setStreaming={setStreaming}
        actionsHistory={actionsHistory}
        effectiveVariables={effectiveVariables}
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </div>
)
}, compareState);

function compareState(prevProps, nextProps) {
  // This will return false (triggering a re-render) only if the props have actually changed
  return isEqual(prevProps, nextProps);
}