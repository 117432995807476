import React from 'react'

type LoaderProps = {
    size?: '12px' | '16px' | '20px' | '24px' | '28px' | string,
    type?: 'spinner' | 'pulse',
    color?: 'base-0' | 'base-100' | 'base-content' | 'primary' | 'accent' | 'error' | 'warning' | 'success' | 'info' | string,
    opacity?: '100' | '70' | '50',
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function Loader({
        size='16px', 
        type='spinner', 
        color=null,
        opacity='70', 
        attributes, 
        listeners, 
        junoProps /* remove line */
    }: LoaderProps) {
    
    const { junoAttributes, outlineStyle, preview} = junoProps || {} /* remove line */
    
    const borderSizeMap = {
        '12px': 'border',
        '16px': 'border-2',
        '20px': 'border-[3px]',
        '24px': 'border-4',
        '28px': 'border-[5px]'
    };
    // border-current 
    const useColor = color ? color : 'current'
    const typeClasses = type == 'spinner' ? `${borderSizeMap[size]} border-solid border-${useColor} border-t-transparent` : 
    `bg-${useColor} `
    
    const animation = 
        /* remove line */  preview ? 
        type == 'spinner' ? 'animate-spin' : 'pulsate-125' 
        /* remove line */  : ''
    let loaderClasses = `${typeClasses} rounded-full opacity-${opacity} ${animation}`

    return (
        <div {...junoAttributes} className={outlineStyle}  /* remove line */ >   
            <div 
                style={{width: size, height: size}}
                className={loaderClasses} {...attributes} {...listeners} />
        { /* remove line */}</div> 
    );
}



/* ignore rest */

Loader.definitions = {
    apiName: 'Loader',
    displayName: 'Loader',
    description: 'A loader component for indicating loading states, with options for type, size, color, and opacity. Supports different loader styles like spinner, pulse, and dots.',
    ai_instructions: 'animated loader',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    status: 'in review',
    package: 'Starter',
    propDefinitions: {
        type: {
            type: 'oneOf',
            options: ['spinner', 'pulse'],
            displayName: 'Type',
            default: 'spinner'
        },

        color: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-content', 'primary', 'accent', 'error', 'warning', 'success', 'info'],
            displayName: 'Color',
            default: null,
        },
        size: {
            type: 'oneOf',
            options: ['12px', '16px', '20', '24px', '28px'],
            displayName: 'Size',
            default: '16px',
            tile: '1/2'
        },
        opacity: {
            type: 'oneOf',
            options: ['100', '75', '50'],
            displayName: 'Opacity',
            default: '75', 
            tile: '1/2'
        }
    }
};


/*Loader.propTypes = {
    type: PropTypes.oneOf(['spinner', 'pulse']),
    size: PropTypes.oneOf(['12px', '16px', '20px', '24px', '28px']),
    color: PropTypes.oneOfType([
    PropTypes.oneOf(['base-0', 'base-100', 'base-content', 'primary', 'accent', 'error', 'warning', 'success', 'info']),
    PropTypes.string
    ]),
    opacity: PropTypes.oneOf(['100', '70', '50']),
    children: PropTypes.node
};*/