import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Button, Icon } from '../'; /* replace to 'import { Button, Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

type BannerProps = {
    title?: string,
    text?: string,
    type?: 'base' | 'error' | 'warning' | 'success' | 'info',
    width?: '480px' | '640px' | '780px' | '960px' | '1200px' | '100%',
    primaryAction?: string,
    secondaryAction?: string,
    icon?: 'none' | 'auto' | 'info' | 'warning' | 'error' | 'check-circle',
    backdrop?: 'dark' | 'blurred' | 'none',
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}


export default function Banner({
        title = 'Banner Title',
        text = 'Banner message',
        width = '640px',
        type = 'info',
        primaryAction = 'Learn more',
        secondaryAction = 'Discard',
        icon = 'auto',
        backdrop = 'none',
        attributes,
        listeners, 
        junoProps /* remove line */
      }: BannerProps) {

    const { junoAttributes, showTag, outlineStyle, eventListeners, preview,  onPropertyUpdate=()=>{} } = junoProps || {} /* remove line */

    
    // OVERLAY STYLES
    const darkBackground = `color-mix(in srgb, var(--base-content) 24%, transparent)`
    const lightBackground = `color-mix(in srgb, var(--base-content) 12%, transparent)`
    const overlayClasses = `absolute top-0 flex flex-col w-full h-full`

    // BANNER STYLES
    const alertStyles = 'flex flex-row items-start justify-between font-normal transition duration-100';
    const typeStyles = type != 'base' ? `text-${type}-content ring-1 ring-${type} bg-${type}-surface` : `text-base-content ring-1 ring-base-200`

    let wrapperClasses = `bg-base-0 text-base px-3 py-2 pb-3 rounded-lg gap-2 flex flex-row items-start justify-start shadow-md mx-auto ${alertStyles} ${typeStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    // ICON Styles
    const iconStyleMap = {
        info: 'info',
        error: 'warning',
        base: 'info',
        warning: 'warning',
        success: 'check-circle',
    };

    const useIcon = icon == 'auto' ? iconStyleMap[type] : icon;
    const IconComponent = icon !== 'none' ? <Icon icon={useIcon?.toLowerCase()} className='flex-shrink-0 mt-0.5' /> : null;
    
    return (
        /* Overlay */
        <div  className={overlayClasses} 
        {...attributes} {...listeners} 
        id={junoAttributes?.id} {...eventListeners} /* remove line */
        style={{
            backgroundColor: backdrop == 'none' ? 'transparent' : backdrop == 'dark' ? darkBackground : lightBackground,
            zIndex: 50, 
            backdropFilter: backdrop == 'blurred' && 'blur(2px)',
            WebkitBackdropFilter: backdrop == 'blurred' && 'blur(2px)', /* For Safari compatibility */
        }}>
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Overlay</div> /* remove line */}

        {/* Modal */}
            <div className={wrapperClasses} 
            // ref={setRefs} {...dndProps?.dndAttributes} {...dndProps?.dndListeners} /* remove line */
            style={{width: '100%', maxWidth: width, marginTop: 60 }}>
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Banner</div> /* remove line */}
            {IconComponent}
            <div className='flex flex-col gap-1.5 flex-grow-1 w-full items-start'>
                {title && <h2 className='font-semibold text-lg'>
                    {<EditableOnDoubleClick text={title} onBlur={onPropertyUpdate('title')} preview={preview}/> /* replace to '{title}' */}
                </h2>}
                {<EditableOnDoubleClick text={text} onBlur={onPropertyUpdate('text')} preview={preview}/> /* replace to '{text}' */}
                <div className={`flex flex-row flex-shrink-0 items-center gap-2`}>
            {primaryAction && 
                <Button 
                    text={primaryAction} 
                    size={'small'}
                    color={type == 'base' ? 'base-700' : type}
                    style={'filled'}
                    marginTop={'8px'}
                    junoProps={{onPropertyUpdate:  () => onPropertyUpdate('primaryAction') }} /* remove line */
                />}
            {secondaryAction && 
                <Button 
                    text={secondaryAction} 
                    size={'small'}
                    color={type == 'base' ? 'base-200' : type}
                    style={'light'}
                    marginTop={'8px'}
                    junoProps={{onPropertyUpdate:  () => onPropertyUpdate('secondaryAction') }} /* remove line */
                />}
            </div>
            </div>
            <Icon icon='close' className='flex-shrink-0 hover:scale-110 cursor-pointer transition-all' />
            </div>
        </div>
    );
}




/* ignore rest */

Banner.stories = [
    {
        name: 'Default',
        wrapperClasses: 'flex flex-col w-full max-w-[640px] h-full max-h-[480px] bg-base-0 relative',
        props: {
            width: '480px', 
            text: 'Banner typically appears on top of the page to inform users about important system state and calls to action.',
        }
    }

]

Banner.definitions = {
    apiName: 'Banner',
    displayName: 'Banner',
    description: `A dynamic banner component designed for displaying alerts, messages, or actions. Features customizable types, text, actions, icons, and backdrop settings.`,
    ai_instructions: `appears on top in the middle of the page above all content. typically has a backdrop. alerts user about some important system state and calls to action. limited to one per page`,
    type: 'dialogs',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    status: 'in review',
    package: 'Starter',
    propDefinitions: {

        title: {
            type: 'string',
            isRequired: true,
            displayName: 'Title',
            default: 'Banner Title'
        },
        text: {
            type: 'string',
            isRequired: true,
            displayName: 'Message',
            default: 'This is an alert message'
        },
        type: {
            type: 'oneOf',
            options: ['base', 'error', 'warning', 'success', 'info'],
            displayName: 'Type',
            default: 'base',
            ai_instructions: `warning is generally red while error is yellow`,
            tile: '1/2'
        },

        backdrop: {
            type: 'oneOf',
            options: ['dark', 'blurred', 'none'],
            displayName: 'Backdrop',
            default: 'none', 
            tile: '1/2'
        },
        primaryAction: {
            type: 'string',
            displayName: 'Primary Action',
            default: 'Learn more', 
            tile: '1/2'
        },
        secondaryAction: {
            type: 'string',
            displayName: 'Secondary Action',
            default: 'Discard', 
            tile: '1/2'
        },
        icon: {
            type: 'icon',
            options: ['none', 'auto', ...allIconNames],
            displayName: 'Left Icon',
            default: 'auto'
        },
    }
};



/*Banner.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['base', 'error', 'warning', 'success', 'info']),
    width: PropTypes.oneOf(['480px', '640px', '780px', '960px', '1200px', '100%']),
    primaryAction: PropTypes.string,
    secondaryAction: PropTypes.string,
    icon: PropTypes.oneOf(['none', 'auto', ...allIconNames]),
    backdrop: PropTypes.oneOf(['dark', 'blurred', 'none']),
};
*/