import { useContext } from 'react';
import { v1 as uuidv1 } from 'uuid';

import { createNewFrame, createNewIds } from '../../../utilities/helpers';
import { ArrowRight, Copy, FastArrowRight, NavArrowLeft, NavArrowRight, PagePlus, Plus } from 'iconoir-react';
import { EditorContext } from '../EditorContext';

export default function AddNext({isDragging}) {
    const {handleAction, selector, setNotifications, notifications } = useContext(EditorContext)
    
    function addFrame() {
      const page = selector.page
      const frames = selector.page.frames
        const insertIndex = frames?.length > 0 
        ? Math.max(...frames.map(frame => frame.index)) + 1 // add as last to the page
        : 1;
    
        let newFrame = createNewFrame({ ...page, type: 'page' }, [], 'Untitled frame', { width: 800, height: 800 });
        newFrame = { ...newFrame, index: insertIndex };;
        
        handleAction({type: 'INSERT_FRAME', frame: newFrame });
      
      
      const newNotification = {
        id: uuidv1(), 
        message: 'Page added', 
        timeout: 2000
      };
    
      // Update the notifications array
      setNotifications([...notifications, newNotification]);

    };


    const duplicateFrame = (frame) => {
      const newId = uuidv1(); // will use for frame.id and object.frame
      
      let newObjects = [...frame.objects]; // copy existing objects
      
      newObjects = createNewIds(newObjects, frame.id, newId); // create new Ids for these objects
      newObjects = newObjects.map(obj => ({...obj,frame: newId})); // update frame property
    
      const newFrame = {
          ...frame,
          id: newId,
          index: frame.index + 1,
          objects: newObjects
      };
      
      handleAction({type: 'INSERT_FRAME', frame: newFrame });
    }

    function handleClick() {
      addFrame()
      /*let frames = selector.page?.frames || []
      frames = frames.sort((a, b) => a.index - b.index)
      const lastFrame = frames[frames.length - 1]
      if (lastFrame) duplicateFrame(lastFrame)
      else addFrame()*/
    }

    if (!isDragging) return (
      
      <div className={`relative group flex flex-row items-center justify-center gap-2 font-medium
      rounded-lg whitespace-nowrap flex-shrink-0 rounded-lg flex-grow-0 select-none cursor-pointer
      text-base-700 transition-all duration-150 pl-2`}
      onClick={handleClick}
      style={{height: 52}}
      >
       
        <ArrowRight height={12} width={12} style={{strokeWidth: 1.5}} className='group-hover:scale-125 transition-all duration-75 opacity-0 group-hover:opacity-100'/>
        <span className={`opacity-0 group-hover:opacity-100 w-0 group-hover:w-10 transition-all duration-75   text-xs whitespace-nowrap`}>
        add
        </span>
      </div>
      
      )
  }

  

  /* old
  export default function ShareButton() {
    const {project} = useContext(EditorContext)
    const ZoomOut = IconoirIcons['OpenNewWindow'];
    const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap
    w-auto bg-gray-700 text-gray-50 font-medium rounded px-2 py-1 text-xs
    -bottom-5 mb-2 right-0 transform translate-y-full  transition-all`
    return (
      
      <div className='shadow p-2 relative flex flex-row gap-1 items-center hover:bg-base-50 font-medium group text-xs bg-white rounded-lg border border-base-200'
      onClick={() => window.open(`/share/${project.project.id}`, '_blank')}
      >
        <ZoomOut height={16} width={16} style={{strokeWidth: 1.5}}/>
        <span className={`${tooltipStyle}`} style={{ zIndex: 1001 }}>
        Open Preview
        </span>
      </div>
      )
  }

  */