import React from 'react'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */

type ProgressBarProps = {
    label?: string,
    color?: 'base-300' | 'primary' | 'accent' | 'info' | 'success' | 'warning' | 'error',
    progress?: '0%' | '10%' | '20%' | '30%' | '40%' | '50%' | '60%' | '70%' | '80%' | '90%' | '100%',
    showProgress?: boolean,
    barHeight?: '8px' | '12px' | '16px' | '20px',
    corners?: 'xs' | 'sm' | 'md' | 'lg' | 'full',
    fontSize?: 'xs' | 'sm' | 'base',
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function ProgressBar({
        label = null,
        color = 'info',
        progress = '50%', 
        showProgress = false,
        corners = 'sm',
        barHeight = '16px',
        fontSize = 'sm',
        attributes,
        listeners, 
        junoProps /* remove line */
      }: ProgressBarProps) {
    const { junoAttributes, showTag,  outlineStyle, onPropertyUpdate=()=>{}, preview } = junoProps || {} /* remove line */
    
    const noLabel = !label || label === ''

    let wrapperClasses = `flex flex-col w-full items-start justify-start whitespace-nowrap text-${fontSize}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    return (
        <div 
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={wrapperClasses} 
        >   
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Progress Bar</div> /* remove line */}
        {label && <div className='flex flex-row items-center justify-between w-full mb-1'>
            <span style={truncateStyle} className='font-semibold'>
            {<EditableOnDoubleClick text={label} preview={preview} whiteSpace='nowrap' onBlur={onPropertyUpdate('label')}/> /* replace to '{label}' */}
            </span>
            <div className='flex-shrink-0 leading-none'>{progress}</div>
        </div>
        }
        <div className='flex flex-row gap-2 w-full items-center'>
        <div className={`relative flex-grow w-full rounded-${corners}`}>
            <div className={`rounded-${corners} w-full h-full bg-current-10`} style={{ height: barHeight }}/>
            <div className={`absolute top-0 left-0 rounded-${corners} bg-${color}`} style={{width: progress, height: barHeight}}/>
        </div>
        {(showProgress && noLabel) && <div className='flex-shrink-0 leading-none'>{progress}</div>}
        </div></div>
    ); 
}



/* ignore rest */

ProgressBar.definitions = {
    apiName: 'ProgressBar',
    displayName: 'ProgressBar',
    description: '',
    ai_instructions: '',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    status: 'in review',
    package: 'Starter',
    propDefinitions: {
        label: {
            type: 'string',
            displayName: 'Label',
            default: null
        },
        barHeight: {
            type: 'oneOf',
            options: ['8px', '12px', '16px', '20px'],
            default: '16px', 
            displayName: 'Bar Height',
            tile: '1/2'
        },
        fontSize: {
            type: 'oneOf',
            options: ['xs', 'sm', 'base'],
            default: 'small', 
            displayName: 'Font Size', 
            tile: '1/2'
        },
        progress: {
            type: 'oneOf',
            displayName: 'Progress',
            options: ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
            default: '50%', 
            tile: '1/2'
        },
        corners: {
            type: 'corners',
            options: ['xs', 'sm', 'md', 'lg', 'full'],
            displayName: 'Corners',
            tile: '1/2',
            default: 'sm'
        },
        color: {
            type: 'colors',
            options: [ 'base-300', 'primary', 'accent', 'info', 'success', 'warning', 'error'],
            displayName: 'Bar color',
            default: 'info'
        },
        showProgress: {
            type: 'bool',
            displayName: 'Show Progress Value',
            ai_instructions: 'shows progress as text on the right side if true',
            default: false
        },
        
    }
};

/*ProgressBar.propTypes = {
    label: PropTypes.string,
    color: PropTypes.oneOf([ 'base-300', 'primary', 'accent', 'info', 'success', 'warning', 'error']),
    progress: PropTypes.oneOf(['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']),
    showProgress: PropTypes.bool,
    barHeight: PropTypes.oneOf(['8px', '12px', '16px', '20px']),
    corners: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'full']),
    fontSize: PropTypes.oneOf(['xs', 'sm', 'base'])
};  */