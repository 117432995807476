import React, { useState, useRef, useContext, useEffect } from 'react';
import * as IconoirIcons from 'iconoir-react';
import { EditorContext } from "../../EditorContext";
import { SegmentedSwitch } from '../../../../ui-kit/exports/react';

export default function ModeSwitch() {
    const {view, setView, selector, setSelector, streaming, activeSticky} = useContext(EditorContext);
    

    function handleSelectView(value) {
      let rightSide = view.rightSide
      
      if (value == 'eye' && (rightSide == 'editor' || rightSide == 'assets' || rightSide == 'elements')) {
        rightSide = null
      }
      console.log('rightSide', rightSide)
      setView({...view, mode: value, rightSide})
      setSelector({...selector, object: null, objectRef: null}) // clear selector

    }
    const currentValue = view.mode
    const options = [
      {value: 'editor', 
      icon: 'edit',
      label: 'Edit'},
      {value: 'eye', 
      icon: 'play',
      label: 'Preview'},
    ]    
    
    const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-normal
    w-full bg-gray-100 text-gray-800 ring-1 ring-gray-300 font-medium rounded-md px-2 py-1 text-xs z-50
    -bottom-3 transform translate-y-full transition-all left-1/2 -translate-x-1/2 duration-300`

    return (
      <div className='relative w-48'>
      <SegmentedSwitch 
        size={'small'}
        options={options}
        width={'full'}  
        value={currentValue}
        bgColor={'base-100'}
        onChange={(value)=>handleSelectView(value)}
      />

      </div>
      );
  }


/*
  <div 
        className={`relative items-center flex flex-row rounded-lg bg-slate-200 p-0.5 h-7 gap-1 text-xs w-40
        ring-1 ring-slate-300`}
        style={{userSelect: 'none'}}
      >
        {/* Sliding background 
        {options.map((option, index) => (
          
          <button
            key={option.value}
            className={`w-1/2 flex items-center justify-center h-full px-1.5 font-medium rounded-md flex-grow gap-1 group
            transition-all duration-150 ${currentValue === option.value ? 'ring-1 ring-slate-300 bg-base-0' : ''}  `}
            style={{flexGrow: 1, zIndex: 2}}
            onClick={option.onClick}
          >
            {option.icon}
            {option.label}
            
          </button>
        ))}
      </div>*/