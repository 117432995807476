import { useContext, useEffect, useState } from "react";
import { TextArea } from "ui-kit/exports/react";

export default function LongString({ propValue, onSave, propKey, label='No label' }) {
    const [value, setValue] = useState(propValue);

    function handleBlur(e) {
        onSave(propKey, e.target.value);
    }

    function handleInputChange(e) {
        setValue(e.target.value);
    }

    return (
        <><TextArea
            label={label}
            placeholder={''}
            value={value}
            width={'full'}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => handleBlur(e)}
            size={'small'}

    />

            </>
    );
}
