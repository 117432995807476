import React from 'react';
import { SegmentedSwitch } from '../../../../../ui-kit/exports/react';

export default function SelectSize({ propKey, options, value, onChange, label }) {  

  const allOptions = [
    { value: 'auto', label: 'auto' },
    { value: 'xsmall', label: 'XS' },
    { value: 'small', label: 'S' },
    { value: 'medium', label: 'M' },
    { value: 'large', label: 'L' },
]
  const modifiedOptions = allOptions.filter(option =>
    options.some(opt => opt === option.value)
  );
  
  return (
    <>
    <SegmentedSwitch
        size={'small'}
        width={'full'}
        label={label}
        options={modifiedOptions}
        value={value}
        onChange={(value) => onChange(propKey, value)}
      />
      </>
  );
};
