import { useContext, useState } from 'react';
import { convertThemeVariablesToStyles } from '../../../canvas/helpers';
import { SampleForm, SampleAlerts } from './previewComponents';
import { Arrows } from '../Arrows';

export default function ThemeDemo({ theme, assets }) {
    const [previewComponent, setPreviewComponent] = useState(0)
    
    const options = ['form', 'alerts']
    const variables = theme?.variables || [];
    const themeStyles = convertThemeVariablesToStyles(variables);
    

    function handleBefore(index) {
        if (index == 0) return
        setPreviewComponent(index - 1)
    }
    function handleAfter(index) {
        if (index == options.length - 1) return
        setPreviewComponent(index + 1)
    }


    return (

      <div className='w-full flex flex-col rounded-lg h-full overflow-hidden relative group'>
        {
        <Arrows 
            index={previewComponent}
            length={options.length}
            onPrev={() => handleBefore(previewComponent)}
            onNext={() => handleAfter(previewComponent)}
            />}
        <div className="w-full flex flex-col"
        style={{
            ...themeStyles,
                height: '100%',
                maxHeight: '100%',
                '--iconset': theme.iconSet,
                color: 'var(--base-content)',
                backgroundColor: 'var(--base-100)',
                fontFamily: `var(--mainFont), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
        }}
        > 

            {options.indexOf('form') === previewComponent && <SampleForm theme={theme} assets={assets}/>}
            {options.indexOf('alerts') === previewComponent && <SampleAlerts theme={theme} assets={assets}/>}
            
        </div>
        </div>
    )
}



/* nav

<div className="flex flex-row justify-center items-center rounded-t-md border-b">
        <div className="flex flex-row w-[240px] justify-between items-center rounded-lg border border-slate-200 my-1 px-1 gap-1">
        <NavArrowLeft 
            className={`cursor-pointer scale-75 transition-all ${previewComponent == 0 ? 'opacity-30' : 'opacity-100 hover:scale-100'}`}
        
            onClick={() => handleBefore(previewComponent)} />
        <select value={previewComponent} onChange={(e) => setPreviewComponent(parseInt(e.target.value))}
        className="flex-grow text-center w-full py-px px-2 text-xs rounded-md my-px bg-slate-100 hover:bg-white transition-all">
            {options.map((option, index) => (
                <option key={index} value={index}>{option}</option>
            ))}
        </select>
        <NavArrowRight
        className={`cursor-pointer scale-75 transition-all ${previewComponent == options.length - 1 ? 'opacity-30' : 'opacity-100 hover:scale-100'}`}
         onClick={() => handleAfter(previewComponent)} />
        </div></div> */