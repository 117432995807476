import { useEffect, useRef } from "react";

export default function ComponentList({ displayComponent, setDisplayComponent, filteredComponents, definitions }) {
    const listRef = useRef(null);
    
    useEffect(() => {
        listRef.current?.focus();
    }, []);
    
    // Filter and group definitions by type
    const groupedByType = definitions
        .filter(def => def.package) 
        .reduce((acc, def) => {
        if (filteredComponents.includes(def.apiName)) {
            if (!acc[def.type]) {
                acc[def.type] = [];
            }
            acc[def.type].push(def);
        }
        return acc;
    }, {});

    function formatTitle(title) {
        // Replace underscores with spaces and split camelCase words
        return title
            .replace(/_/g, ' ') // Replace underscores with spaces
            .split(/(?=[A-Z])/) // Split at the start of each uppercase letter in camelCase
            .join(' ') // Join words with space
            .split(' ') // Split the string into words to capitalize the first letter of each
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
            .join(' '); // Join words back into a single string
    }

    const handleKeyDown = (event) => {
        if (["INPUT", "TEXTAREA", "SELECT"].includes(event.target.tagName)) {
            // Allow default behavior for form elements
            return;
        }
        event.preventDefault();
        // Create a sorted list of types for defined navigation order
        const types = Object.keys(groupedByType).sort((a, b) => a.localeCompare(b));
        let currentTypeIndex = types.findIndex(type => groupedByType[type].some(component => component.apiName === displayComponent));
        let currentComponentIndex = groupedByType[types[currentTypeIndex]].findIndex(component => component.apiName === displayComponent);
    
        if (event.key === 'ArrowDown') {
            if (currentTypeIndex === types.length - 1 && currentComponentIndex === groupedByType[types[currentTypeIndex]].length - 1) {
                // If it's the last component of the last type, do nothing
                return;
            }
            currentComponentIndex++;
            if (currentComponentIndex >= groupedByType[types[currentTypeIndex]].length) {
                // Move to the next type
                currentTypeIndex++;
                currentComponentIndex = 0; // Start at the first component of the next type
            }
        } else if (event.key === 'ArrowUp') {
            if (currentTypeIndex === 0 && currentComponentIndex === 0) {
                // If it's the first component of the first type, do nothing
                return;
            }
            currentComponentIndex--;
            if (currentComponentIndex < 0) {
                // Move to the previous type
                currentTypeIndex--;
                currentComponentIndex = groupedByType[types[currentTypeIndex]].length - 1; // Start at the last component of the previous type
            }
        }
    
        // Set the new display component
        const newDisplayComponent = groupedByType[types[currentTypeIndex]][currentComponentIndex].apiName;
        setDisplayComponent(newDisplayComponent);
    };

    return (
        <div className="flex flex-col items-stretch overflow-y-scroll px-3 text-sm gap-0 scrollbar"
                tabIndex={0}
                ref={listRef}
                onKeyDown={handleKeyDown}
                >
            {Object.keys(groupedByType)
            .sort((a, b) => a.localeCompare(b))
            .map((type) => (
                <div key={type}
                
                >
                    <h3 className="my-2 font-semibold text-md">
                        {formatTitle(type)}
                    </h3>
                    <div className="pl-px">
                    {groupedByType[type].map((obj) => (
                        <div className={`flex flex-row w-full border-l transition-all duration-75
                        ${displayComponent === obj.apiName ? 'border-primary' : 'border-base-100 hover:border-primary'}`} key={obj.apiName}>
                        <div 
                            className={`flex items-center justify-between pl-3 px-2 py-1 rounded-md cursor-pointer transition-all duration-75
                            ${displayComponent === obj.apiName ? 'opacity-100 text-primary font-medium' : 'hover:text-primary hover:opacity-100 opacity-80'}`}
                            onClick={() => setDisplayComponent(obj.apiName)}
                        >
                            {obj.displayName}
                        </div>
                        </div>
                        
                    ))}</div>
                </div>
            ))}
        </div>
    );
}