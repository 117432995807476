import React, { useState, useContext } from "react";
import { EditorContext } from "../../EditorContext";
import ShowCode from "./code/ShowCode";
import { Button } from "../../../../ui-kit/exports/react/index";

export default function Inspector() {
  const { selector, view, setView } = useContext(EditorContext);
  const frame = (selector && selector.frame) || null;
  const selectedItem = selector.object || selector.frame;

  const [selectedOption, setSelectedOption] = useState("code");
    const switchOptions = 
      [
        { label: 'Code', value: 'code' },
        // { label: 'Layers', value: 'layers' },
        // { label: 'Install', value: 'install' },

      ] 
      function handleSelect(value) {
        setSelectedOption(value)
        setView({ ...view, code: value })
      }
  if (frame) return (
    <div
      className="w-full h-full overflow-hidden flex flex-col gap-2"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="w-full h-auto overflow-hidden flex-grow">
      {selectedOption === "code" && (<ShowCode 
      view={view} setView={setView}
      selector={selector} displayType={selectedOption} setDisplayType={setSelectedOption} />)}
      
      </div>
      
    </div>
  );
}

function FiddleGeneration() {
  return (
    <div className="w-full flex-grow flex flex-col p-2 items-start bg-yellow-200">
      <Button 
        text={'Generate Fiddle'}
        leftIcon={'sparkles'}
        type={'primary'}
        width={'auto'}
        size={'small'}
      />
    </div>
  )

}

{/*selectedOption === "exports" && <Exports />}
      {selectedOption === "install" && <Documentation />*/}