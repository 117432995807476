import { useEffect, useRef, useState } from "react"
import symbol from "./symbol.svg"
import { ArrowLeft, ArrowRight, NavArrowDown, NavArrowLeft, NavArrowRight, PauseSolid, Play, PlaySolid } from "iconoir-react"
import ReactPlayer from 'react-player';

const projects = [
    {  
        name: 'Juno', 
        
        rotate: 'hover:rotate-2', 
        video: 'https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/shorter.mov?alt=media&token=a1938518-7151-4db4-8617-1f647d5f1e89',
        symbol: symbol, 
        description: 'We use juno to build more juno (very meta).',
        stack: 'Node JS, React JS, Firebase.', 
        color: 'red'
    },
    { 
        name: 'Bao', 
        
        rotate: 'hover:-rotate-3',
        symbol: 'https://storage.googleapis.com/juno-v1.appspot.com/Screenshot%202024-07-24%20at%205.33.46%E2%80%AFPM%202.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=GU0Fu1%2B57K0tmhnccDlS8CyDg28L5aKAHQHF05dDfdOxQ4O%2FEmiE6rv5fxDQl%2BpFutz3YY4l%2B%2BjGlyirfK6gv8vpZs1bSvy7p3%2B%2FnHO9qavdM%2BNfbYALg9W4gEhuqg15kgHJF6TAX1c4x2hipO48tvnlBnsF5OWDAhNAhPoJ97D2irZ9Q3Yri7%2FTp7FwEicfwjUyaRevq23QgU6tj2pCdkZGKOVNL9FC1ad6B6ws6oNeRoVHN60P1MYcq4havrcObQ1qH0hM%2BAxL4QB9xub2BI3pud8tbvI%2FO8eH2d2P79KW9BFycp%2F0%2F%2Bu1vy%2B3ipEtu5I4w2T5QWQQh%2BqCjMg2iw%3D%3D', 
        video: 'https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/bao_2x1.mov?alt=media&token=065ede94-f538-4893-9ca6-51dc8b384ff6',
        description: 'App to practice Mandarin reading.',
        stack: 'Python, React JS, Supabase', 
        tag: '48 hrs', 
        color: 'green'
    }, 

    
    
    
    
    { 
        name: 'Cooklang', 
        
        rotate: 'hover:rotate-6', 
        thumbnail: "https://storage.googleapis.com/juno-v1.appspot.com/frame-439afe00-163a-11ef-bb7a-7b8cf5cdf797.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=NAk1y06e8sqK3KkfDkjx%2FM4J4IfHFP10%2BeHUxfaoBAZHODKFSYi7i6TlFdMsGpoYE%2BDjEnZiCKyKXmmnKbdNZNdyHHXE5XQcp0MET5UC5bv7mV0wS5nvhFXYITYG37rOB%2F6O6xS1ObJT4INOQvO9X2IhdM98XX6lZ136i4bbrL52J3FmDTzS%2BSvDfOig0dvKd4eDffhUeYPMPj14%2Fb382QjJa6sCdBKNUmN4cSq1yxX3pTWEgdMDXkJEoiUs%2BjG6b0WCIwdgWalCtSOKq2HlYgbukwby36uOj4Tr3j603X5dLbpq6VEtTo6Pi6z3z69J5O3F9T60BOjjZTdgKcVUHw%3D%3D&timestamp=1720635580610",
        images: [
            "https://storage.googleapis.com/juno-v1.appspot.com/frame-f63eac10-5aa9-11ef-983a-a7049dc18987.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=Sz9WcRJvJx%2B8II42Psv%2BMO%2FCW83%2FhXbYpZM7Nf8ldS4%2FzmNmbnP1dcEkBv7aoGNhhOmW3eKo5KqpnMikBBSB9yUOzmVWHNOyWrcCytKDAWA3uRFB8mDcpIEZFToX0zwHPc7ryDOPdV3efnCrdQgjWOi3NpR6N3%2FVG%2BwFELz2VFcdZg5ik6g9uZyAXb0DmEZ58O%2FG8g1y2EmNFmrE7xx3su2hS6FSv9IUgxmJHl%2BzUrt8APAiGbRpZJUUXmQJUzo2dSyGObO7fPvUbRJVf4qfZlwppF6cShVYARzb%2BX9I0s8esGpADzijPsJWCpkuQD92wTbu%2FWAGSTQ3MOU0e7mlmg%3D%3D&timestamp=1723687824682",
            "https://storage.googleapis.com/juno-v1.appspot.com/frame-25f6c6b0-fb71-11ee-b213-391fd44f8a69.png?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=fxcV7HOLeTzrvk%2FAJ4SVnXwyocG2Wm4IENtAlUCCUnm%2FONVJyb7RgnyBaK7DThZRxwixZJBfFSOWrRCpS54bB%2FfkQx0QIcB4Y3Amqs%2FLPYGuQltAeH5DymCQYzjcKfpMPtG9lVS%2FnVlNSeNNI7IUa1D4bxR2I%2BPOHC5VaDdx8O%2FwdkMVpbG5kOZX8AnDiOGPopQKKgD0h9stt8TDgX7%2FIPbWm%2FF%2B4ueddLattgIAEYMELH0M2nd6KWT5WkAMkt5pP8z%2BRXt7l39EFXuN3k%2B%2B3wnAcwFuFriBh057V11Sv053WnMQbwAKfMxyLJXGrF2P6gnSMxSUkt2rW0cB2oH%2FTg%3D%3D&timestamp=1723687014019"
        ],
        symbol: 'https://firebasestorage.googleapis.com/v0/b/juno-v1.appspot.com/o/cooklang_symbol.svg?alt=media&token=bffbff57-a1f8-47dd-a26a-95d0fc3dc3e0', 
        description: 'App for cooking enthusiasts.',
        stack: 'UX/Design, React JS code.', 
        color: 'blue'
    },
]

export default function Examples({mainWidth}) {

    const [selectedProject, setSelectedProject] = useState(projects[0])
   
    return (
            <div className="flex flex-col w-full h-full             
             gap-6 flex-grow items-center px-5 justify-start py-12 relative"
             style={{maxWidth: mainWidth}}
             >
                {/* Project Cards - one on desktop, list on mobile*/}
                <div className={`w-full gap-6 flex-grow justify-start md:min-h-auto flex flex-col`}>
                    <div className="w-full  relative flex flex-col justify-start items-center
                        ring-[0.5px] ring-base-300 shadow-sm md:shadow-lg rounded-md md:overflow-hidden
                        " >
                            <BrowserTop />
                            <div className={`w-full relative flex flex-col justify-start items-center`}
                    style={{ aspectRatio: '2/1' }}
                    >
                    {selectedProject.video ? <VideoPreview project={selectedProject} key={selectedProject.name} />
                    : <SlideShow project={selectedProject} />}
                    </div>
                    </div>
                    <ProjectHeader project={selectedProject} />
                </div>
                {/* 
                {projects.map(project => 
                <div className={`w-full gap-6 flex-grow justify-start md:min-h-auto 
                    ${selectedProject.name == project.name ? 'flex flex-col ' : 'hidden'}`}
                id={project.name}
                key={project.name}
                >
                    
                    <BrowserTop />
                    <div className={`w-full relative flex flex-col justify-start items-center`}
                    style={{ aspectRatio: '2/1' }}
                    >
                    {project.video ? <VideoPreview project={project} selectedProject={selectedProject} />
                    : <SlideShow project={project} />}
                    </div>
                    </div>

                    <ProjectHeader project={project} />
                    
                </div>
                )}*/}
                <ProjectSelector 
                    projects={projects}
                    selectedProject={selectedProject} 
                    setSelectedProject={setSelectedProject} />
            </div>
    )
  }




  function ProjectSelector({
    projects,
    selectedProject,
    setSelectedProject
}) {
    const miniThumbs = `flex flex-col w-16 h-16  hover:-translate-y-4 hover:scale-125
    transition-all duration-150 ease-in-out rounded-2xl relative group
    border-[0.5px] border-base-300 hover:border-base-300 shadow hover:shadow-lg`


    function selectNext() {
        const currentIndex = projects.findIndex((project) => project.name === selectedProject.name);
        let nextIndex = currentIndex + 1;
        if (nextIndex >= projects.length) nextIndex = 0;

        setSelectedProject(projects[nextIndex]);
    }

    function selectBefore() {
        const currentIndex = projects.findIndex((project) => project.name === selectedProject.name);
        let nextIndex = currentIndex - 1;
        if (nextIndex < 0) nextIndex = projects.length - 1;

        setSelectedProject(projects[nextIndex]);
    }

    //text-2xs px-1.5 py-0.5 rounded bg-gray-900 text-white ring-[0.5px] ring-base-300 leading-tight font-medium
    return (
        <>
        <div className="hidden md:flex md:flex-row gap-4 items-center justify-center z-10 -mt-4 select-none">
                {projects.map(project => (
                    <div key={project.name} className={`${miniThumbs} 
                    ${selectedProject.name == project.name && '-translate-y-4 scale-110 shadow-md'}
                    `}
                    style={{
                        backgroundImage: `url(${project.symbol})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: project.name == "Juno" && 'var(--accent)', 
                    }}
                    // onMouseEnter={() => setSelectedProject(project)}
                    onClick={() => setSelectedProject(project)}
                    >

                        <div className={`absolute -bottom-2 translate-y-full left-1/2 -translate-x-1/2 
                        ${project.name == selectedProject.name ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}
                        text-2xs px-1.5 py-0.5 rounded-sm ring-[0.5px] ring-base-300 leading-tight font-medium z-50 shadow-sm bg-base-0
                        `}>
                            {project.name}
                        </div>

                        {/*project.name == selectedProject.name &&
                        <div
                        className="absolute w-2 h-2 bg-primary -bottom-2 translate-y-full rounded-full
                        left-1/2 -translate-x-1/2"
                        />*/}
                    </div>
                ))}
                
            </div>


            <div className="flex flex-row md:hidden gap-4 items-center -mt-1 text-xl justify-end w-full">
                <div 
                onClick={selectBefore} 
                className="flex items-center justify-center bg-black/10 w-12 h-12 rounded-full" >
                    <NavArrowLeft />
                </div>
                <div 
                onClick={selectNext}
                className="flex items-center justify-center bg-black/10 w-12 h-12 rounded-full" >
                    <NavArrowRight />
                </div>
            </div>
            </>
    )
}

function ProjectHeader({ project }) {
    return (
        <div className="flex flex-col md:flex-row w-full items-start justify-between md:gap-6 flex-shrink-0 text-gray-500
        text-sm
        order-first md:order-last
        ">
            <span className="flex flex-row gap-1 items-center">
            <span className="md:hidden font-medium text-gray-700">{project.name} -</span>
            {project.description}
            </span>
                        <div className="flex flex-row items-center gap-2 md:items-end ">
    
        <span className="hidden md:block">
            {project.stack}
        </span>
    </div>
        </div>
    )
}

/*
<h2 className="text-lg md:text-xl font-semibold leading-tight relative 
              bg-clip-text bg-gradient-to-b from-gray-700 to-gray-800 text-transparent" 
                style={{  fontFamily: 'Unbounded' }}
                key={project.name}
            >
                
                {project.name}
                {project.tag &&
            <span
                className="hidden md:absolute top-0 -right-2 translate-x-full select-none transition-all duration-75
                inline-block px-2 py-0.5 text-xs font-medium bg-primary text-white rounded-md shadow-sm"
            >
                {project.tag}
    </span>}
            </h2>*/

function VideoPreview({ project }) {
    /*const [isPlaying, setIsPlaying] = useState(false);
    const videoSrc = project.video;
    */
    
    console.log('rendering video preview', project.name);
    const [isPlaying, setIsPlaying] = useState(true);
    const videoSrc = project.video;

    return (
        <div className="w-full flex flex-col h-full rounded-md md:rounded-b-md md:rounded-t-none overflow-hidden">
            {<ReactPlayer
                url={videoSrc}
                playing={isPlaying}
                controls={false}
                width="100%"
                height="auto"
                muted
                playsinline
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onEnded={() => setIsPlaying(false)}
                className=" transition-all duration-150 ease-in-out"
                style={{
                    objectFit: 'cover',
                    aspectRatio: '2/1',
                }}
            />}
            
            
            {/* Video Controls */}
            <div
                className={`absolute z-10 -bottom-5 translate-y-full md:translate-y-0 md:bottom-5 md:left-5 
                rounded-full bg-black/10 md:bg-black/25 w-12 h-12 flex items-center justify-center
                hover:scale-110 transition-all duration-150 ease-in-out md:opacity-50 hover:opacity-100`}
                onClick={() => setIsPlaying(!isPlaying)}
            >
                {isPlaying ? 
                    <PauseSolid width={28} height={28} className="" /> :
                    <PlaySolid width={28} height={28} className="ml-1" />
                }
            </div>
        </div>
    );
}

function SlideShow({ project }) {
    const images = project.images || [];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [isLooping, setIsLooping] = useState(true); // State to manage looping
    const intervalRef = useRef(null);

    useEffect(() => {
        // Preload images
        const preloadImages = () => {
            return Promise.all(
                images.map((src) => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = src;
                        img.onload = resolve;
                        img.onerror = reject;
                    });
                })
            );
        };

        preloadImages()
            .then(() => setLoaded(true))
            .catch((error) => console.error('Error loading images', error));
    }, [images]);


    useEffect(() => {
        if (!loaded || !isLooping) return;

        intervalRef.current = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 2500); // Change image every 2.5 seconds

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [loaded, isLooping, images.length]);

    return loaded ? (
            <div
                className="rounded-md md:rounded-b-md md:rounded-t-none transition-all duration-500 ease-in-out relative"
                style={{
                    width: '100%',
                    aspectRatio: '2/1',
                    backgroundImage: `url(${images[currentIndex]})`,
                    backgroundSize: '100% auto',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    
                    cursor: 'pointer',
                }}
            >
            {/* Play Controls */}
            <div className={`absolute z-10 -bottom-5 translate-y-full md:translate-y-0 md:bottom-5 md:left-5 
            rounded-full bg-black/10 md:bg-black/25 w-12 h-12 flex items-center justify-center
            hover:scale-110 transition-all duration-150 ease-in-out md:opacity-50 hover:opacity-100`}
            onClick={() => isLooping ? setIsLooping(false) : setIsLooping(true)}
            >
                {isLooping ? 
                <PauseSolid width={28} height={28} className="" /> :
                <PlaySolid width={28} height={28} className="ml-1" />}
            </div>
            </div>
    ) : null;
}




function BrowserTop() {
    const circle = `w-3 h-3 rounded-full bg-black/25`
    return (
        <div className="
        select-none hidden md:flex md:flex-row  h-8 w-full border-b-[0.5px] border-base-300 items-center justify-between bg-gradient-to-t from-gray-100 to-gray-50">
                <div className="flex flex-row gap-2 items-center justify-center h-full px-4">
                    <div className={circle} />
                    <div className={circle} />
                    <div className={circle} />
                </div>
                {/*
                <div className="mx-auto self-center h-5 bg-white w-full max-w-[400px] flex items-center justify-center 
                border-[0.5px] border-base-300 rounded-md text-gray-400 text-xs font-normal text-center
                ">
                    junodesign.app
                </div>*/}
                <div className="flex flex-row gap-2  items-center justify-center h-full px-4 opacity-0">
                    <div className={circle} />
                    <div className={circle} />
                    <div className={circle} />
                </div>
            </div>
    )
}