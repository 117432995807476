import { useContext } from 'react';
import { EditorContext } from '../../../EditorContext';
import DnDContext from '../../../dragNdrop/DnDContext';
import FramesPerGroup from './FramesPerGroup';

export default function Layouts({insertNewFrame, draggable=true, }) {
  const { assets, effectiveVariables, set, view } = useContext(EditorContext);
  const { handleDragEnd, handleDragStart } = useContext(DnDContext);

  console.log(set)
  const folder = set?.folders?.find(folder => folder.name == 'Layouts') || [];
  const flow = folder?.pages ? folder?.pages[0] : [];
  
  if (flow) return (
    <div className='w-full h-full flex flex-col gap-2 pb-3' style={{  overflow: 'scroll', userSelect: 'none' }}>
      <div className='flex flex-row gap-2 items-center justify-start text-xs p-2 font-medium'>
        <h1>Sample Layouts</h1>
        </div>
      <div className='w-full h-full flex flex-col gap-2'
        style={{   userSelect: 'none' }}>
            <FramesPerGroup
              flow={flow}
              
              assets={assets}
              view={view}
              insertNewFrame={insertNewFrame}
              variables={effectiveVariables}
              draggable={draggable}
              handleDragEnd={handleDragEnd}
              handleDragStart={handleDragStart}
            />
          </div>

      
      </div>
  );
}


