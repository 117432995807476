import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Button, Icon } from '../'; /* replace to 'import { Button, Icon } from './index'' */
import { useMemo } from 'react';

const allIconNames = Object.keys(iconMap) || []

type InfoCardProps = {
    title?: string,
    width?: '100%' | '200px' | '320px',
    imageSrc?: string,
    imageAspectRatio?: '2 / 1' | '1 / 1' | '3 / 2' | '4 / 3',
    textSize?: 'small' | 'medium',
    icon?: 'none' | (typeof allIconNames)[number],
    secondaryText?: string,
    description?: string,
    primaryAction?: string,
    secondaryAction?: string,
    corners?: 'none' | 'sm' | 'md' | 'lg' | 'xl',
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function InfoCard({
        title = "Card Title",
        width = '100%',
        imageSrc = null,
        imageAspectRatio = '2 / 1',
        textSize = 'small',
        icon = 'calendar',
        secondaryText = "Jun 2, 2023",
        description = "Short description that should be about 80-100 characters long.",
        primaryAction = null,
        secondaryAction = null,
        corners = 'md',
        attributes,
        listeners, 
        junoProps /* remove line */
    } : InfoCardProps) {
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{}, preview} = junoProps || {} /* remove line */

    const sizeStyles = `w-full h-auto ${textSize == 'small' ? 'text-sm' : 'text-base'}`
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    let wrapperClasses = `flex flex-col items-stretch justify-start gap-3 ${sizeStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const IconComponent = icon !== 'none' ? <Icon icon={icon?.toLowerCase()}  className='flex-shrink-0' /> : null;

    const noImage = !imageSrc;
    const imageStyles = useMemo(() => ({
        background: !noImage && `linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.12)), url(${imageSrc}) no-repeat center center / cover`, 
        backgroundSize: 'cover', 
        aspectRatio: imageAspectRatio
    }), [imageSrc, noImage, imageAspectRatio]);

    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const titleFont = textSize == 'small' ? 'text-base' : 'text-lg';
    const smallerFont = textSize == 'small' ? 'text-xs' : 'text-sm';

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={wrapperClasses} style={{maxWidth: width}}
        >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Info Card</div> /* remove line */ }

        {/* IMAGE / THUMBNAIL */}
        <div className={`relative w-full aspect-square flex items-center justify-center bg-current-10 ${cornerStyles}`} style={imageStyles}>
            {noImage && <Icon icon='image' />}
        </div>
        
        {/* Content Block */}
        <div style={truncateStyle} className={`flex flex-col flex-grow`}>
            
            {/* Title */}
            <div className={`mb-sm flex flex-col justify-between gap-2 items-start ${titleFont}`}>
                <h3 className={`font-semibold`} style={truncateStyle}>
                    {<EditableOnDoubleClick text={title} onBlur={onPropertyUpdate('title')} whiteSpace="nowrap"/> /* replace to '{title}' */}
                </h3>
                
                {(secondaryText || icon) && 
                <div className={`flex-shrink-0 flex flex-row items-center gap-1.5 ${smallerFont}`}>
                    {IconComponent}
                    {<EditableOnDoubleClick text={secondaryText} onBlur={onPropertyUpdate('secondaryText')} whiteSpace="normal"/> /* replace to '{secondaryText}' */}
                </div>}
            </div>

            {/* Card Description */}
            {description && 
            <div className={`${smallerFont} font-normal`} style={{minHeight: '2rem'}}>
                {preview ? description : <EditableOnDoubleClick text={description} onBlur={onPropertyUpdate('description')} /> /* replace to '{description}' */}
            </div>}
        </div>

        {/* Actions */}
        {(primaryAction || secondaryAction) && 
        <div className={`flex flex-row items-center flex-grow-0 flex-shrink-0 justify-start text-base gap-2 pointer-events-none`}>
            {primaryAction && <Button size='small' color='primary' text={primaryAction} onClick={()=>{}} marginTop={'6px'}/>} 
            {secondaryAction && <Button size='small' color='base-700' text={secondaryAction} onClick={()=>{}} marginTop={'6px'}/>} 
        </div>}
        </div>
    );
}

/* ignore rest */

InfoCard.definitions = {
    apiName: 'InfoCard',
    displayName: 'InfoCard',
    description: 'Info card with image, title, description, and two optional actions. Info is stacked vertically.',
    ai_instructions: 'Use this card to display information about a record, e.g. recipe, event. For things that sell preferrably use ProductCard.',
    type: 'cards',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    status: 'prototype',
    package: 'Pro',
    propDefinitions: {

        title: {
            type: 'string',
            default: "Card Title", 
            required: true, 
            displayName: 'Title'
        }, 

        secondaryText: {
            type: 'string',
            default: "Jun 2, 2023",
            ai_instructions: 'appears below title, can be used for rating, date, number reviews or similar', 
            displayName: 'Secondary Text'
        }, 
        description: {
            type: 'string',
            default: "Short description that should be about 80-100 characters long.",
            ai_instructions: 'max 100 chatacters, will be trimmed if longer', 
            displayName: 'Description'
        },
        primaryAction: {
            type: 'string',
            default: null,
            ai_instructions: 'try not to use this unless user is requiesting',
            displayName: 'Primary Action', 
            tile: '1/2'
        },
        secondaryAction: {
            type: 'string',
            default: null,
            ai_instructions: 'try not to use this unless user is requiesting', 
            displayName: 'Secondary Action',
            tile: '1/2'
        },
        width: {
            type: 'oneOf',
            options: ['100%', '200px', '320px'],
            default: 'full', 
            displayName: 'Width',
            tile: '1/2'
        },

        textSize: {
            type: 'size',
            options: ['small', 'medium'],
            ai_instructions: 'small sets title, price to text-sm and description to text-xs, medium sets title and price to text-base and description to text-sm',
            default: 'small', 
            displayName: 'Text Size',
            tile: '1/2'
        },
        corners: {
            type: 'corners',
            options: ["none", "sm", "md", "lg", 'xl'],
            default: 'md',
            ai_instructions: 'rounded corners for the thumbnail image', 
            displayName: 'Corners',
            tile: '1/2'
        },
        imageAspectRatio: {
            type: 'oneOf',
            options: ['2 / 1', '1 / 1', '3 / 2', '4 / 3'],
            default: '2 / 1', 
            displayName: 'Image Aspect',
            tile: '1/2'
        },
        imageSrc: {
            type: 'imageURL',
            default: null, 
            displayName: 'Image',
        },
        icon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Icon',
            ai_instructions: 'icon that appears in next to secondary text, not clickable',
            default: 'calendar', 
            
        },
        
    }
};

/*InfoCard.propTypes = {
    width: PropTypes.oneOf(['100%', '200px', '320px']),
    imageSrc: PropTypes.string,
    imageAspectRatio: PropTypes.oneOf(['2 / 1', '1 / 1', '3 / 2', '4 / 3']),
    textSize: PropTypes.oneOf(['small', 'medium']),
    title: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(['none', ...allIconNames]),
    secondaryText: PropTypes.string,
    description: PropTypes.string,
    primaryAction: PropTypes.string,
    secondaryAction: PropTypes.string,
    corners: PropTypes.oneOf(["none", "sm", "md", "lg", 'xl']),
    
};*/