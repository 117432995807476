import { EmojiSatisfied, Flare, Group, Running, SparksSolid, Xmark } from "iconoir-react";
import { useContext } from "react";
import UserSettings from "./UserSettings";
import UsageSettings from "./Usage";
import UserContext from "../../UserContext";
import { Modal, SidebarLink } from "../../ui-kit/exports/react";

export default function SettingsModal({view, setView}) {
    
    const { user, setUser, handleLogout } = useContext(UserContext);

    const contentMap = {
        'user': <UserSettings user={user} setUser={setUser} />,
        'usage': <UsageSettings />
    }
    return (
    <Modal modalBackground={'base-0'} closeButton={true} corners={'xl'}
    paddingX={'12px'} paddingY={'12px'}
    onClose={()=>setView({ ...view, settings: null })} >
         <div className={`flex flex-row relative items-stretch justify-start gap-5 min-h-[360px]`}>

        {/* Tabs */}
        <div className="max-w-[188px] w-1/3">
        
        <Tabs view={view} setView={setView} handleLogout={handleLogout} 
                firstName={user.firstname}
                />
                </div>

                {/* Content */}
                <div className="flex-grow">
                    {contentMap[view.settings]}
                </div>
        </div>
    </Modal>  
    );
}

function Tabs({view, setView, handleLogout, firstName=''}) {
    const tabStyles = `flex flex-row items-center gap-2 px-2 -ml-1 py-1 cursor-pointer transition-all duration-150 text-sm font-normal
    hover:bg-slate-100 transition-all duration-150 rounded-lg hover:text-slate-800
    `
    const iconStyles = {
        width: 18,
        height: 18,
        style: {strokeWidth: 1.5}
    }


    return (
        <div className="flex flex-col gap-2 h-full justify-between w-full">
            <div className="flex flex-col gap-1 w-full">
            <SidebarLink 
                text='Profile'
                leftIcon='smiley'
                size={'medium'}
                isActive={view.settings == 'user'}
                hoverEffect={true}
                onClick={() => setView({...view, settings: 'user'})}
                />
            <SidebarLink
                text={'AI Usage'}
                leftIcon='sparkles'
                size={'medium'}
                isActive={view.settings == 'usage'}
                hoverEffect={true}
                onClick={() => setView({...view, settings: 'usage'})}
                />
            </div>
            
            
            <SidebarLink
                text={'Log Out'}
                leftIcon='log-out'
                size={'medium'}
                hoverEffect={true}
                onClick={handleLogout}
                />
        </div>
    );
    
}
