import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { useRef, useState } from 'react';

import { Icon } from '../'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

type FileUploadProps = {
    size?: 'small' | 'medium' | 'large',
    corners?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl',
    fileName?: string,
    width?: 'auto' | '1/2' | 'full',
    label?: string,
    icon?: 'none' | 'cloud-upload' | 'page' | 'close' | string,
    dropAreaText?: string,
    secondaryText?: string,
    state?: 'placeholder' | 'focused',
    fileStatus?: 'uploading' | 'uploaded' | 'error',
    onChange?: (files: FileList) => void,
    accept?: string,
    hasOutline?: boolean,
    attributes?: any,
    listeners?: any,
    junoProps?: { /* remove line */
        junoAttributes?: any, /* remove line */
        showTag?: boolean, /* remove line */
        outlineStyle?: string, /* remove line */
        onPropertyUpdate?: (property: string) => (value: any) => void, /* remove line */
    } /* remove line */
}

export default function FileUpload({
        size = 'small',
        corners = "none",
        fileName = "file-name.txt",
        width = "auto",
        label,
        icon = 'cloud-upload',
        dropAreaText = 'Drag your file here or browse files',
        secondaryText,
        state = 'placeholder',
        fileStatus = 'uploading',
        onChange = () => console.log('File uploaded'),
        accept = '',
        hasOutline,
        junoProps, /* remove line */
      }: FileUploadProps ) {

    const { showTag, outlineStyle, onPropertyUpdate=()=>{}, junoAttributes} = junoProps || {} /* remove line */
    
    const [file, setFile] = useState({ name: '' });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('idle'); // 'uploading', 'success', 'error'
    const [isDragOver, setIsDragOver] = useState(false); // Track if drag is over the drop area

    const widthStyle = width != 'auto' ? `w-${width}` : 'w-auto'
    const borderStyles = hasOutline ? `border border-current-20` : '';
    const sizeStyles = size == 'small' ? 'gap-0.5 text-xs' : size == 'large' ? 'gap-1.5 text-base' : 'gap-1 text-sm'
    let wrapperClasses = `flex flex-col items-stretch justify-start ${sizeStyles} ${widthStyle} ${borderStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const labelTextSize = size == 'small' ? `text-xs` :  size == 'large' ? `text-lg`: `text-sm`;
    const labelClasses = `text-base-content ${labelTextSize} font-medium`

    const iconSize = size == 'small' ? '20px' : size == 'large' ? '32px' : '24px'
    const IconComponent = icon !== 'none' ? <Icon icon={icon}  className='flex-shrink-0' size={iconSize}  /> : null;

    const stateStyles = (state === 'focused' || isDragOver) ? 'bg-accent/20 border-accent' : 'bg-current-10 border-current-20';
    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const dropAreaSizeClasses = size === 'small' ? 'p-2 gap-0.5' : size === 'large' ? 'p-3 gap-1.5' : 'p-2 gap-1';
    const dropAreaClasses = `w-full h-full relative border-dashed flex flex-col items-center justify-center ${cornerStyles} ${stateStyles} ${dropAreaSizeClasses}`

    
    const barColor = (fileStatus === 'uploading') ? 'info-content' : fileStatus === 'uploaded' ? 'success-content' : 'error-content';
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}
    
    /* remove line */ /*
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            setFile(files[0]);
            handleFileUpload(files[0]);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
            handleFileUpload(files[0]);
            if (onChange) {
                onChange(files); // Only call onChange if files is not null
            }
        }
    };

    const handleFileUpload = (file: File) => {
        setUploadStatus('uploading');
        const formData = new FormData();
        formData.append('file', file);

        // Simulate an API call
        fetch('/upload-endpoint', {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (response.ok) {
                    setUploadStatus('success');
                } else {
                    throw new Error('Failed to upload');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setUploadStatus('error');
            });
    };

    */ /* remove line */

    return (
        <div 
        {...junoAttributes} /* remove line */
        className={wrapperClasses}
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>File Upload</div> /* remove line */}

            {label && <label className={labelClasses}>
                    <EditableOnDoubleClick text={label} onBlur={onPropertyUpdate('label')} whiteSpace='nowrap' /* replace to '{label}' */ />
            </label>}

            <div className={dropAreaClasses}
                style={{
                    borderWidth: size == 'small' ? '1px' : size == 'large' ? '2px' : '1.5px', 
                    minWidth: size == 'small' ? '120px' : size == 'large' ? '200px' : '160px',
                }}
                /* remove line */ /*
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                */ /* remove line */
            >
                {/* remove line */ /*
                <input
                    ref={fileInputRef}
                    type="file"
                    style={{ 
                        opacity: '0', 
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                    }}
                    onChange={handleFileChange}
                    accept={accept}
                />
                */ /* remove line */}
                {IconComponent}
                
                {dropAreaText ? <div className={'font-medium'}>
                <EditableOnDoubleClick text={dropAreaText} onBlur={onPropertyUpdate('dropAreaText')} whiteSpace="nowrap"  /* replace to '{dropAreaText}' */ />
                </div> : null}

                {secondaryText ? <div className={'font-light'}>
                <EditableOnDoubleClick text={secondaryText} onBlur={onPropertyUpdate('secondaryText')} whiteSpace="nowrap" /* replace to '{secondaryText}' */ />
                </div> : null
                }
            

            </div>
            {file && 
            <div className={`flex flex-col items-center gap-1.5 p-2 bg-base-100 ${cornerStyles}`}>
                <div className='flex flex-row gap-2 w-full justify-between'>
                <Icon icon='page'  size={'16px'}  className='flex-shrink-0 w-4 h-4' />
                <div className='text-base-content text-sm flex-grow' style={truncateStyle}>
                    {file?.name}
                </div>
                    <Icon icon='close' size={'16px'} className='flex-shrink-0 w-4 h-4' 
                    /* replace to 'onClick={() => setFile({ name: '' })}' */
                    />
                </div>
                {uploadStatus === 'uploading' && 
                <div className={`flex flex-row h-1 w-full rounded-full justify-start bg-base-0`}>
                  <div className={`rounded-full h-full`} style={{ 
                    minWidth: '5px',
                    width: `${uploadProgress}%`, 
                    backgroundColor: `var(--${barColor})` 
                    }} />
                </div>}
            </div>}
        
        </div>
    );
}


/* ignore rest */


FileUpload.definitions = {
    apiName: 'FileUpload',
    displayName: 'FileUpload',
    description: `File upload component with drag & drop area and small file card`,
    ai_instructions: 'File upload component with drag & drop area and small file card',
    type: 'inputs',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {

        label: {
            type: 'string',
            default: null, 
            ai_instructions: 'text to show above the dropzone, 70 characters max. very optional',
        },
        dropAreaText: {
            type: 'string',
            default: 'Drag your file here or browse files', 
            ai_instructions: 'text to show in the dropzone, 70 characters max', 

        },
        secondaryText: {
            type: 'string',
            default: null, 
            ai_instructions: 'supporting text, optional, up to 120 characters', 
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            tile: '1/2',
            defaultOnMobile: 'full'
        },

        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            ai_instructions: '',
            tile: '1/2',
            displayName: 'Size',
            default: 'small'
        },
        state: {
            type: 'oneOf',
            options: ['placeholder', 'focused',],
            default: 'placeholder', 
            tile: '1/2',
        },
        
        corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg", 'xl', '2xl'],
            displayName: "Corners",
            ai_instructions: 'corners of the drop area',
            tile: '1/2',
            default: "none"
        },
        fileName: {
            type: 'string',
            default: "file-name.txt",
            ai_instructions: 'short, will be truncated to 5 characters'
        },
        fileStatus: {
            type: 'oneOf',
            tile: '1/2',
            options: ['uploading', 'uploaded', 'error'],
            displayName: 'file status',
            ai_instructions: 'status of the file',
            default: 'uploading'
        },

        showFile: {
            type: 'bool',
            default: true,
            ai_instructions: 'show file icon, name, size, and status' 
        },
        icon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Icon',

            default: 'cloud-upload'
        },
        
    }
};



/*FileUpload.propTypes = {
    width: PropTypes.oneOf(["auto", "1/2", "full"]),
    label: PropTypes.string,
    state: PropTypes.oneOf(['placeholder', 'focused']),
    dropAreaText: PropTypes.string,
    secondaryText: PropTypes.string,
    corners: PropTypes.oneOf(["none", "sm", "md", "lg", 'xl', '2xl']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    showFile: PropTypes.bool,
    fileName: PropTypes.string,
    icon: PropTypes.oneOf(['none', ...allIconNames]),
    fileStatus: PropTypes.oneOf(['uploading', 'uploaded', 'error']), 
    onChange: PropTypes.func,
    accept: PropTypes.string,
};
*/