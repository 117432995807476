import { useContext } from "react";
import { EditorContext } from "../../EditorContext";
import AddFromLibrary from "../insideproject/AddFromLibrary";
import * as projectsDB from '../../../../api/projectsAPI'
import Editable from "../Editable";
import { Button, InputText } from "../../../../ui-kit/exports/react";

export default function OverviewHeader({tab}) {
    
    const { selector, view, project, handleAction, set, setProject } = useContext(EditorContext);

    const headings = {
        'designSystem': 'Themes', 
        'brand': 'Brand',
        'api_docs': 'API Docs',
        'ui_kit': 'UI-Kit',
      }
    
    const projectSelector = (view.inOverview == 'project' && !selector.folder) ? 'project' : 
    view.inOverview == 'folders' ? view.showAllFolders ? 'folder' : 'flow' : ''

    function renameProject(name) {
        const updatedProject = { ...project, project: { ...project.project, name } };
        setProject(updatedProject);
        projectsDB.update(updatedProject.project);
    }

    function renameFolder(newName) {
        const currentFolder = selector.folder;
        const action = {
            type: 'UPDATE_FOLDER',
            folder: { ...currentFolder, name: newName },
        };
        handleAction(action);
    }

    //project?.project?.name 
    const h1 = view.inOverview === 'project' ? 'Overview' : view.inOverview === 'folders' ? project?.project?.name  : 'Design System';
    const h2 = (view.inOverview !== 'project' && view.inOverview !== 'folders') ? headings[tab] : projectSelector == 'project' ? 
        <Editable 
            size="large"
            string={project?.project?.name}
            onBlur={renameProject}
            placeholder="Project Name"
        />
    
        : 
        <Editable 
            size="large"
            string={selector?.folder?.name}
            onBlur={renameFolder}
            placeholder="Project Name"
        />
    
        
    function addFlow() {
        const folder_id = selector.folder?.id;
        if (folder_id) {handleAction({ type: 'APPEND_PAGE', folder_id})}
    }
    return (
        <div className={`flex flex-row pl-5 px-2 w-full justify-between items-end transition-all`}>
            <div className="w-1/2 flex flex-col gap-1">
                <h1 className="text-xs opacity-80">{h1}</h1>
                <h2 className="flex flex-row gap-2 h-full items-end text-xl font-semibold">{h2}</h2>
            </div>

            <div className="flex flex-row gap-2 items-end pb-1"> 

                {(projectSelector === 'flow' || projectSelector === 'folder') &&
                <>
                <Button
                    size={'small'}
                    text={'New Flow'}
                    rightIcon={'plus'}
                    onClick={() => addFlow()}
                    
                
                />
                <AddFromLibrary
                selector={selector}
                handleAction={handleAction}
                set={set}
                />
                </>}
            </div>
        </div>)
}


/*<Editable
                    string={selector.folder?.name}
                    onBlur={updateFolderName}
                    size='large'
                    />*/
