import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import logo from './juno-new.svg';
import { Button, InputText } from '../../ui-kit/exports/react';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("If we have this user, you will receive an email to reset your password.");
      setTimeout(() => navigate('/login'), 5000); // Redirect to login page after 5 seconds
    } catch (error) {
      console.error(error);
      setMessage("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <div className='flex flex-col items-center justify-between h-screen bg-white p-5'>
      
      <div></div>
      <div className='max-w-[420px] w-full bg-white rounded-lg p-6 pb-10 flex flex-col gap-5'>
        <img src={logo} alt="Logo" width={160} className='mx-auto mb-10'/>
        <form onSubmit={handleSubmit} className='flex flex-col gap-5'>
          {message ?
            <><p className='text-center text-2xl font-semibold text-slate-700'>Check your email</p>
            <p className='mt-2 px-4 py-3 bg-lime-200 border border-lime-300 rounded-xl text-lime-700 text-center'>{message}</p>
            </>
         : <>
          <p className='text-center text-2xl font-semibold text-slate-700'>Forgot password?</p>
        <p className='text-center text-md w-3/4 mx-auto text-slate-700 mb-5'>Enter your email, we'll send you a link to reset your password.</p>
        <InputText
          size='large'
          type="email"
          name={'email'}
          bgColor={'base-50'}
          hasOutline={false}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <Button
          size={'large'}
          color={'primary'}
          style={'filled'}
          onClick={(e) => handleSubmit(e)}
          text={'Send Recovery Email'}
          />
        
        </>}
      </form>
      </div>
      <div className='text-base-500'>
      <Button
      size={'medium'}
      style={'link'}
      onClick={() => navigate('/login')}
      text={'Back to Sign In'}
      />
      </div>
    </div>
  );
}
