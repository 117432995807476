import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import logo from "./logo_text.svg";
import { Button } from '../../ui-kit/exports/react';

export default function Header({mainWidth,}) {

  const navigate = useNavigate();
  const headerRef = useRef(null);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 120) {
        headerRef.current.style.borderBottom = '0.5px solid #eee'; 
        if (window.innerWidth < 768) { headerRef.current.style.height = '40px'; }
        headerRef.current.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.02)';
        headerRef.current.style.backgroundColor = 'rgba(255, 255, 255, 0.50)';
      } else {
        headerRef.current.style.borderBottom = 'none'; 
        headerRef.current.style.height = '56px'; 
        headerRef.current.style.boxShadow = 'none'; 
        headerRef.current.style.backgroundColor = 'transparent';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToForm = () => {
    const element = document.getElementById('contact-form');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div 
    ref={headerRef}
    className="transition-all duration-150 
    sticky md:flex md:flex-col top-0 backdrop-blur 
    w-full h-[56px] relative justify-between items-center gap-2" 
    style={{zIndex: 50}}>
      
        <div className='flex flex-row h-full items-stretch gap-2 w-full  mx-auto justify-between items-center px-5'
        style={{maxWidth: mainWidth}}
        >
        <div className='flex flex-row h-full items-stretch gap-2 '>
          
          {/* Logo */}
          <div className='flex flex-row py-1 gap-2  rounded items-center text-sm select-none transition-all relative flex-shrink-0'onClick={() => navigate('/')}>
            <img src={logo} alt="Logo" width={80} onClick={() => navigate(`/`)} className='flex-shrink-0'/>
          </div>
        </div>

      
      <div className="flex flex-row h-full w-1/2 items-center justify-end gap-3" >
      
          <Button
          style={'ghost'}
          color={'base-700'}
          size={'small'}
          text='login'
          onClick={() => navigate('/login')}
          />
            
          <Button
          style={'light'}
          size={'small'}
          color={'base-200'}
          text='contact'
          onClick={handleScrollToForm}

           />
          
      </div>
      </div>
    </div>
  );
}    
