import { useEffect, useState } from "react"
import { InputText } from "../../../ui-kit/exports/react"

export default function Editable({string, onBlur, size='medium', placeholder=''}) {
    const [localString, setLocalString] = useState(string)
    
    useEffect(() => {
      setLocalString(string)
    }
    , [string])
  
    return (
      <input 
        value={localString}
        onChange={(e) => setLocalString(e.target.value)}
        onBlur={() => onBlur(localString)}
        className="bg-transparent focus:bg-current-10 placeholder-text-current-50 focus:cursor-text cursor-default"
        placeholder={placeholder}

      />
      
    )
  }