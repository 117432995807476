import {  useState } from "react";
import Pages from "./templates/Pages";
import Layouts from "./templates/Layouts";
import { TabGroup } from "../../../../ui-kit/exports/react";


export default function Library({onSelect, set, draggable=false}) {
    
    const tabs = 
    [ { label: 'Templates', icon: 'book', value: 'Templates'},
      { label: 'Layouts', icon: 'layout', value: 'Layouts'}, 
      { label: 'Generate', icon: 'flare', value: 'Components'},
    
    ]
      
    const [activeTab, setActiveTab] = useState('Templates')

    const [showSearch, setShowSearch] = useState(false)
 
    return (
      <>
          <div className="w-full px-4 -mt-1 relative">
          <TabGroup
            tabs={tabs} 
            selectColor={'primary'}
            size={'small'}
            underlineAll={false}
            onChange={setActiveTab}
            value={activeTab}
            />    
          </div>
          
            <div className="w-full flex flex-col pt-3 gap-2 px-2.5 overflow-scroll scrollbar-thin bg-base-0 h-full" >
              {!showSearch && activeTab == 'Templates' && set && <Pages 
                insertNewFrame={onSelect}
                draggable={draggable}
                />}
                {!showSearch && activeTab == 'Layouts' && set && <Layouts 
                insertNewFrame={onSelect}
                draggable={draggable}
                />}
            </div>
      </>)

}

