import React, { useContext } from 'react';
import { getOptimalTextColor } from '../../../../../utilities/colorHelpers';
import { EditorContext } from '../../../EditorContext';
import { Xmark } from 'iconoir-react';

export default function SelectColor({ propKey, label, colorKeys, currentColor, onChange }) {

  const handleColorSelect = (color) => {
    onChange(propKey, color);
  };

  const { ds, currentThemeId } = useContext(EditorContext)
  
  const themes = ds?.themes;
  const currentTheme = themes.find(theme => theme.id === currentThemeId); // fall back to default theme if no current theme
  
  const colorVariables = currentTheme.variables.filter(variable => variable.type === 'color');
  const filteredVariables = colorVariables
  .filter(variable => colorKeys.includes(variable.api_name))
  .sort((a, b) => colorKeys.indexOf(a.api_name) - colorKeys.indexOf(b.api_name));
  
  const nullOption = colorKeys.find(key => key == 'none')
  
  return (
    <div className='flex flex-col w-full gap-0 items-end text-xs'>
    <div className='flex flex-row gap-1 justify-between items-start w-full'>
      {label}
      <button className='hover:underline opacity-70 hover:opacity-100 transition-all duration-75'
      onClick={() => onChange(propKey, null)}
      ><Xmark /></button>
    </div>
    <div className='flex flex-col gap-2 rounded w-full bg-base-50 hover:bg-base-0 hover:ring-inset hover:ring-[0.5px] hover:ring-base-100
    transition-all duration-150
    '>
      
        <div className='w-full p-1 flex flex-row flex-wrap gap-[3px] items-start'>
        {filteredVariables.map((color) => (
          <div
            key={color.api_name}
            className={`h-5 rounded-sm flex items-center justify-center p-2 text-sm font-medium hover:scale-105 transition-all duration-150 relative group
            ring-1 ${currentColor == color.api_name ? 'border border-base-0 ring-primary z-10' : 'hover:ring-primary border border-base-0 ring-base-100 hover:z-10'}
            `}
            onClick={() => handleColorSelect(color.api_name)}
            title={color.api_name}
            style={{
              backgroundColor: color.value,
              width: 20
              
            }}
          >
            <div className='absolute -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full opacity-0 group-hover:opacity-100 transition-all duration-150
            text-xs whitespace-nowrap bg-base-100 text-base-700 p-1 rounded-md pointer-events-none
            ' 
            >{color.api_name}</div>
          </div>
        ))}
        {/*nullOption && 
        <div
        key={'none'}
        className={`h-6 rounded-md flex items-center justify-center text-sm font-medium relative
        ${currentColor == 'none' ? 'text-primary' : 'text-base-700 hover:text-primary'}`}
        onClick={() => handleColorSelect('none')}
        title={'none'}
      >
        <Xmark />
      </div>
      */}
      </div>

    </div>
    </div>
  );
};
