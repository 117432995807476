import React, { useState, useRef, useContext, useEffect } from 'react';
import { EditorContext } from '../EditorContext';

import ZoomCanvas from './canvas/ZoomCanvas';
import ShareButton from './ShareButton';
import ModeSwitch from './canvas/ModeSwitch';
import Notifications from './Notifications';
import { Avatar, Button, ButtonIcon, InputText } from '../../../ui-kit/exports/react';
import ThemeSwitcher from './ThemeSwitcher';
import UserContext from '../../../UserContext';


export default function Header() {
  const { view, selector, setView, handleAction } = useContext(EditorContext);
  const {user} = useContext(UserContext);
  const firstName = user?.firstname;
  const lastName = user?.lastname;

  const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap
  bg-gray-800 text-gray-200 font-normal rounded-sm px-2 py-0.5 text-2xs z-50 
  transition-all duration-75`
  
  const currentFrame = selector?.frame || {}

  function updateName(newName) {
    handleAction({
      type: 'UPDATE_FRAME',
      currentFrame: currentFrame,
      newFrame: { ...currentFrame, name: newName }
    })
  } 
  
  return (
    <div className={`h-full transition-all flex-shrink-0 flex flex-row items-center justify-between w-full text-base-content `}
    style={{
      width: `100%`, 
      zIndex: 1000,   
    }}
    >
          <div className={`flex flex-row w-1/2 flex-shrink-0 gap-2 items-center pl-[2px]`} >
            {view.overview ? 
             <>
             <div className='w-[280px] flex pl-2'>
             <InputText 
              leftIcon={'search'}
              placeholder={'Search'}
              bgColor={'none'}
              width={'full'}
              size={'small'}
              hasOutline={false}
              />
              </div>
             </>
            :
            <>
            
              <ButtonIcon size={'small'} icon={'chevron-left'}
              onClick={() => setView({...view, overview: !view.overview, inOverview: 'folders'})}
              />
              {/*
              <ButtonIcon size={'small'} state={view.showFeatures ? 'active' : 'default'} icon={'layers'}
              onClick={() => setView({...view, showFeatures: !view.showFeatures})}
              />*/}
              <Button size={'small'}
              rightIcon={view.miniNav ? 'chevron-up' : 'chevron-down'}
              text={selector?.page?.name}
              onClick={()=> setView({...view, miniNav: !view.miniNav})}
              />
              
              {/*
              <RevealFlow 
                tooltipStyle={tooltipStyle}
                featureName={selector?.page?.name}
              />
              <FrameName 
              currentFrame={selector.frame}
              onBlur={updateName}
              />*/}
              {
              <InputText
              key={selector.frame?.name}
              size={'small'}
              defaultValue={selector.frame?.name}
              hasOutline={false}
              placeholder={''}
              bgColor={'none'}
              onBlur={(e) => updateName(e.target.value)}
              />}
              
            </>
            }
    
    </div>
    
    <div className='flex flex-row w-1/2 flex-shrink-0 items-center justify-end gap-3 pointer-events-auto'>
    <Notifications />
    {view.overview ?
     renderFolderViewControls({tooltipStyle, view, setView, firstName}) :
      renderCanvasControls({tooltipStyle, view, setView})
    }
    <ShareButton tooltipStyle={tooltipStyle}/>
    <ButtonIcon color={'base-200'} style={'light'} size={'small'}
      icon={view.nightMode ? 'sun' : 'moon'}
      onClick={() => setView({...view, nightMode: !view.nightMode})}
    />
    <Avatar
      initials={firstName}
      bgColor={'primary'}
      size={'32px'}
      type={'initials'}
      onClick={() => setView({...view, settings: 'user'})}   
      outlineColor={'accent'} 
    />
    </div>
    
    </div>
  );
}


function renderFolderViewControls({tooltipStyle, view, setView}) {
  
  return (
    <>
    </>
  )
}

function renderCanvasControls({tooltipStyle, view, setView}) {
  return (
    <>
    <ZoomCanvas tooltipStyle={tooltipStyle}/>
    <ThemeSwitcher tooltipStyle={tooltipStyle}/>
    <ModeSwitch tooltipStyle={tooltipStyle}/>
    
    </>
  )
}
