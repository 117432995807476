import { useState } from "react";
import { Button, InputText, Loader } from "../../ui-kit/exports/react";
import symbol from "./symbol.svg";
import { Linkedin, Mail, Twitter } from "iconoir-react";

export default function ContactForm() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const buttonClass =  `hover:scale-125 transition-all duration-150 ease-in-out`;
    return (

  
        <div className="flex flex-col flex-grow w-full h-full items-center justify-between relative max-w-[1440px] p-5 mx-auto">
        <div/>
        {!isSubmitted ? 
        <Form 
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
        /> : 
        <SuccessState 
        
        />}


        <div className="flex flex-row gap-6 mt-32 md:mt-20 items-center justify-center text-sm pb-3">
            
                  <Mail className={buttonClass} onClick={() => window.open('mailto:sam@junodesign.app')}/>
                  <Twitter className={buttonClass} onClick={() => window.open('https://x.com/madewithjuno')}/>
                <Linkedin className={buttonClass} onClick={() => window.open('https://www.linkedin.com/company/juno-design')}/>

        
            </div>
        {/*<div
                className="absolute aspect-square -right-20 h-2/3 -bottom-20 transition-all duration-300 
                saturate-150 
                group-hover:scale-105 group-hover:rotate-3"
                style={{
                    backgroundImage: `url(${cat})`, 
                    backgroundSize: 'auto 100%',
                    backgroundRepeat: 'no-repeat',
                }}
            />*/}
                
    </div>
    )
    
  }

  
  function Form({
    setIsSubmitted
  }) {
      const [formData, setFormData] = useState({
          name: { value: "", error: "", type: "text", placeholder: "Name" },
          email: { value: "", error: "", type: "email", placeholder: "Email" },
          phone: { value: "", error: "", type: "tel", placeholder: "Phone" },
      });

      const [error, setError] = useState(null);
      
      const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData((prevData) => ({
              ...prevData,
              [name]: { ...prevData[name], value: value, error: "" }
          }));
      };
  
    const [isLoading, setIsLoading] = useState(false);
      async function handleSubmit(event) {
        event.preventDefault();
        setError(null);
        setIsLoading(true);
    
        const { isValid, errors } = validateForm(formData);
    
        if (!isValid) {
            setFormData(errors);
            setIsLoading(false);
            return;
        }
    
        try {
            const response = await fetch('/api/leads/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: formData.name.value,
                    email: formData.email.value,
                    phone: formData.phone.value,
                }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const result = await response.json();
    
            if (result.message === "Lead added successfully") {
                setIsSubmitted(true);
                
            } else {
                setError("Failed to submit form.");
            }

            setIsLoading(false);
        } catch (err) {
            console.error("Error:", err);
            setError("Something went wrong. Please try again.");
            setIsLoading(false);
        }

     
    }
      return (

          <form className="flex flex-col flex-nowrap w-full md:max-w-[420px]  
          text-base-content gap-6 items-center justify-start h-auto"
              onSubmit={handleSubmit}
          >
             <div className="w-full flex flex-col gap-6 mb-6 items-center">
              <h1 className="text-ellipsis text-xl md:text-3xl text-primary self-center font-semibold select-none whitespace-nowrap text-center"
                  style={{
                      animationDuration: '150ms',
                      fontFamily: 'Unbounded'
                  }}>
                  from idea to MVP <br/>
                  and beyond
              </h1>
              <p className="text-base-700 text-center w-3/4 md:w-2/3 text-balance">
                Let's talk and see how our team can support you.
              </p>
              </div>
  
              <div className="flex flex-col flex-nowrap w-full max-w-full self-auto text-inherit bg-none gap-5 items-center justify-start h-auto">
                  <InputText
                      name="name"
                      type={formData.name.type}
                      value={formData.name.value}
                      onChange={(val) => handleChange(val)}
                      placeholder="Your Name"
                      rightIcon="none"
                      leftIcon={'smiley'}
                      width="full"
                      bgColor="base-0"
                      size='large'
                      hasOutline={false}
                      state={formData.name.error ? 'error' : 'default'}
                      helperText={formData.name.error}
                  />
                  <InputText
                      name="email"
                        type={formData.email.type}
                      value={formData.email.value}
                      onChange={handleChange}
                      placeholder="email@company.com"
                      width="full"
                      bgColor="base-0"
                      leftIcon={'mail'}
                      state={formData.email.error ? 'error' : 'default'}
                      size='large'
                      hasOutline={false}
                      helperText={formData.email.error}
                  />
                  <InputText
                      name="phone"
                      
                        type={formData.phone.type}
                      value={formData.phone.value}
                      onChange={handleChange}
                      placeholder="(123) - 456 - 7890"
                      width="full"
                      bgColor="base-0"
                      leftIcon={'phone'}
                      size='large'
                      hasOutline={false}
                      state={formData.phone.error ? 'error' : 'default'}
                      helperText={formData.phone.error}
                  />
              </div>
              <Button
                text={'Contact Me'}
                size={'large'}
                color={'primary'}
                width={'full'}
                style={'filled'}
                onClick={handleSubmit} 
                state={isLoading ? 'loading' : 'default'}
              />
              <span className="text-base-500 text-sm"
              >or</span>
              <Button
                text={'Book a Call'}
                size={'large'}
                onClick={() => window.open('https://calendar.app.google/NGbAziCDtYUXmrRCA')}
                width={'full'}
                style={'light'}
                rightIcon={'calendar'}
                state={isLoading ? 'disabled' : 'default'}
              />
              
              {error && <p className="text-red-500 mt-3">{error}</p>}
          </form>
      )
  }
  

  function SuccessState() {
    return (
      <div className="flex flex-col flex-nowrap px-lg py-lg w-full max-w-[480px] 
      text-base-content gap-lg items-center justify-start h-auto text-center"
      style={{animation: 'fadeInGrow 150ms ease'}}
      > 
              <img src={symbol} className="w-24 h-24 rounded-full" />
              <h1 className="text-center text-3xl text-primary mx-auto font-bold select-none whitespace-nowrap"
                  style={{
                      animationDuration: '150ms',
                      fontFamily: 'Unbounded'
                  }}>
                  We'll be in touch!
              </h1>
              Thank you for your interest. 
              <br/>Check spam if you don't hear from us.
              

        </div>
    )
  }

  export function validateForm(data) {
    let isValid = true;
    let errors = { ...data };

    // Validate Name
    if (!data.name.value.trim()) {
        errors.name.error = 'Name is required';
        isValid = false;
    }

    // Validate Email or Phone
    if (!data.email.value.trim() && !data.phone.value.trim()) {
        errors.email.error = 'Either email or phone is required';
        errors.phone.error = 'Either phone or email is required';
        isValid = false;
    } else {
        // Validate Email
        if (data.email.value.trim() && (!data.email.value.includes('@') || !data.email.value.includes('.'))) {
            errors.email.error = 'Invalid email';
            isValid = false;
        }

        // Validate Phone
        if (data.phone.value.trim() && !/^\d+$/.test(data.phone.value)) {
            errors.phone.error = 'Phone number must be numeric';
            isValid = false;
        }
    }

    return { isValid, errors };
}



