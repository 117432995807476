import React, { useContext, useRef, useEffect, useState } from "react";
import { EditorContext } from "../../EditorContext";
import UserFlow from "./flow/UserFlow";
import Editable from "../Editable";

export default function FolderDisplay({draggedFrame, refreshKey}) {
    const { project, view, set, selector, handleAction } = useContext(EditorContext);
  
    const columnWidth = view.columnWidth
    const folder = selector.folder
    const page = selector.page
    function renameFlow(newName, page) {
      const currentPage = page
      if (!currentPage) return;
      const action = {
          type: 'UPDATE_PAGE',
          currentPage: currentPage,
          newPage: { ...currentPage, name: newName }
        };
    
        handleAction(action);
  }

    const pages = project.pages?.filter((p) => !p.is_archived && p.folder_id === folder.id)
    const updateFolderName = (newName) => {
      const currentFolder = selector.folder;
      const action = {
        type: 'UPDATE_FOLDER',
        folder: { ...currentFolder, name: newName }
      };
      handleAction(action);
    };


    if (folder && page) return (
        <div className="overflow-scroll">
            {pages
                  .filter((p) => !p.is_archived && p.folder_id === folder.id)
                  .sort((a, b) => a.index - b.index)
                  .map((page) => (
                    <div key={page.id} className="flex flex-col gap-0 mb-4">
                    <div className="text-md pl-5 font-medium -mb-2 ">
                    
                    <Editable
                    string={page.name}
                    onBlur={(newName) => renameFlow(newName, page)}
                    size='small'
                    />
                    
                    </div>
                <UserFlow
                      
                      frames={page.frames}
                      page={page}
                      folder={folder}
                      isMobile={false}
                      columnWidth={columnWidth}
                      draggedFrame={draggedFrame}
                      refreshKey={refreshKey}
                    />
                    </div>
                ))}
          </div>
    );
    }  
