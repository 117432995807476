import FramePreview from "components/FramePreview";

export default function FlowGroups({ 
    flows, 
    variables,
    onSelect
  }) {
    
    const tileWidth = 218;
  
  const width = 1440
  const height = 820
  // Calculate the scaling factor based on the largest dimension
  const scaleWidth = tileWidth / width;
  const scaleFactor = Math.min(1, scaleWidth); // Ensure the scaling does not exceed 1

  // Use the scaling factor to determine the dimensions
  const scaledWidth = width * scaleFactor;
  const scaledHeight = height * scaleFactor;
  
  
    return (
      <div className='flex flex-col relative px-1.5' style={{ height: '100%', position: 'relative' }}>
      <div className='flex flex-row gap-2 items-center justify-start text-xs py-2 font-medium'>
        <h1>Categories</h1>
        </div>
      {/* Fixed bar relative to the parent */}
      <div className='absolute bg-gradient-to-b from-base-0 to-transparent top-0 w-full h-3 z-10'/>
      <div className='overflow-y-auto pt-2' style={{ height: '100%' }}>
          <div className='grid grid-cols-2 gap-2'>
          {flows.map(flow => { 
            const firstFrame = flow.frames && flow.frames[0];
            return (
            <div key={flow.name} className='flex flex-col w-full gap-1 last:mb-3'>
              <div className={`flex flex-col w-full bg-base-200 text-center rounded-md text-xs items-center justify-center
              border border-base-300 hover:border-base-400 transition-all duration-75 text-base-500
              hover:shadow-inner group cursor-default relative 
              `}
              
              
              >
                {<div className="flex flex-col w-full" style={{height: scaledHeight}} onClick={() => onSelect(flow)}>
                {firstFrame && 
                <PreviewFrame
                frame={firstFrame}
                scaledWidth={scaledWidth}
                scaledHeight={scaledHeight}
                scaleFactor={scaleFactor}
                width={width}
                height={height}
                variables={variables}
                />}
                </div>
                }
              <div className="absolute opacity-0 group-hover:opacity-100 transition-all duration-75
              rounded-md bg-base-0 py-1 px-3 ring-1 ring-base-400
               shadow-md z-50 text-base-800 font-medium"
               onClick={() => onSelect(flow)}
               >
                View {flow.frames?.length} templates
              </div>
              </div>
  
              <div className='flex flex-row gap-1 justify-between w-full font-normal text-xs  opacity-70'>
              <h4 className='capitalize flex-grow truncate'>
                {flow.name}
              </h4>
              
              </div>
            </div>
            )}
          )}
        </div>
        </div>
      </div>
    );
  }
  


  function PreviewFrame({
    frame, scaledWidth, scaleFactor, width, height, variables, scaledHeight
  }) {
    return (
      <div
    className="flex flex-col justify-center transition-all duration-75 items-center relative font-normal select-none rounded-md overflow-hidden"
    style={{ width: scaledWidth, height: scaledHeight }}
    
  >
    
    <div
      className="bg-base-0"
      style={{
        transform: `scale(${scaleFactor})`,
        width: width,
        height: height
      }}
      >
        {<FramePreview
          frame={frame} width={width} height={height}
          // assets={assets}
          effectiveVariables={variables} 
        />}
        
      </div>
      </div>
    )
  }