import Color from 'color'; // Import the color library
import { useRef } from 'react';

export default function MainColorPicker({ theme, onColorChange, colorName, isDarkTheme = false }) {
    
    const colorSet = theme.colors

    function updateColors(e, colorName, isDark=false) {
        const luminosityLimit = isDark ? 10 : 90
        
        const newColorHex = e.target.value;
        const newColorHSLA = Color(newColorHex).hsl();

        const hue = Math.round(newColorHSLA.hue());
        const saturation = Math.round(newColorHSLA.saturationl());
        let luminosity = Math.round(newColorHSLA.lightness());
        luminosity = isDark ? Math.max(luminosity, luminosityLimit) : Math.min(luminosity, luminosityLimit);
        const alpha = newColorHSLA.alpha().toFixed(2);

        
        // Adust luminosity based on whether theme is dark or light
        const focusLuminosity = isDark ? Math.min(luminosity + 5, luminosityLimit) : Math.max(luminosity - 5, 0);
        const contentLuminosity = luminosity > 50 ? 85 : 15
        const surfaceLuminosity = luminosity > 50 ? 15 : 85

        // Construct new HSLA values for focus and content colors
        const color = `hsla(${hue}, ${saturation}%, ${luminosity}%, ${alpha})`
        const focusColor = `hsla(${hue}, ${saturation}%, ${focusLuminosity}%, ${alpha})`
        
        // For Surface Color desaturate and increase/lower luminosity
        const contentColor = `hsla(${hue}, ${Math.max(saturation - 10, 0)}%, ${contentLuminosity}%, ${alpha})`
        
        // For Surface Color desaturate and lower/increase luminosity
        const surfaceColor = `hsla(${hue}, ${Math.max(saturation - 20, 0)}%, ${surfaceLuminosity}%, ${alpha})`

        const colors = {
            [colorName]: color,
            [`${colorName}-focus`]: focusColor,
            [`${colorName}-content`]: contentColor,
            [`${colorName}-surface`]: surfaceColor,
        };
        
        onColorChange(colors);
    }

    const inputRef = useRef(null);
    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const circleSize = 20
    const swatchStyle = `w-5 h-16 z-10 hover:z-20 hover:shadow-sm transition-all last:rounded-r-md first:rounded-l-md relative group`
    
    return (
        
        <div className={`flex flex-col mr-4 gap-2 relative`}>
        {colorName}
        <div className='flex flex-row items-end'>
        <div className={`${swatchStyle} !w-12 hover:scale-110 focus-within:rounded focus-within:scale-110 focus-within:z-20 hover:rounded `} style={{backgroundColor: colorSet[colorName]}} 
        onClick={handleClick}
        >
<input
                 ref={inputRef}
                    type="color"
                    name={colorName}
                    value={Color(colorSet[colorName]).hex()}
                    onChange={(e) => updateColors(e, colorName, isDarkTheme)}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                    }}
                />

        </div>
        <div className={swatchStyle} style={{backgroundColor: colorSet[colorName+'-focus']}}>
            <Tooltip name={`focus`} />
        </div>
        <div className={swatchStyle} style={{backgroundColor: colorSet[colorName+'-content']}}>
            <Tooltip name={`content`} />
        </div>
        <div className={swatchStyle} style={{backgroundColor: colorSet[colorName+'-surface']}}>
            <Tooltip name={`surface`} />
        </div>
            
        </div>
        
        </div>
    );
}

function Tooltip({name}) {
    return (
        <div className='absolute -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-full opacity-0 group-hover:opacity-100 transition-all duration-150
        text-2xs whitespace-nowrap bg-base-100 text-base-700 px-1 py-px rounded-md pointer-events-none
        ' 
        >{name}</div>
    )
}