import React, { useContext, useState, useEffect, useRef } from 'react';
import { EditorContext } from '../../EditorContext';
import { useNavigate } from 'react-router-dom';
import Folders from './Folders';
import AddFolderPlus from './AddFolderPlus';
import { Button, Divider, OrgMenu, SidebarLink, UserMenu } from '../../../../ui-kit/exports/react';

import UserContext from '../../../../UserContext';

export default function Navigation() {
    const { view, setView, project, assets, selector, setSelector } = useContext(EditorContext)
    const {handleLogout} = useContext(UserContext)
    const navigate = useNavigate();
    const isOpen = view.overviewSidebar
    const setIsOpen = (value) => setView({...view, overviewSidebar: value})

    const navigationRef = useRef(null);
    
    function useOutsideClick(ref, callback) {
      useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            callback();
          }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref, callback]);
    }
    const folders = project.folders?.filter(f => !f.is_archived) || []
    useOutsideClick(navigationRef, () => setView({...view, leftSide: false}));

    const tooltipStyle = `opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap text-base-100
    w-auto bg-base-800 border border-base-800  font-normal rounded-md px-2 py-0.5 text-2xs ${isOpen ? 'absolute md:hidden' : 'absolute'}
    top-1/2 mb-2 -right-3 transform -translate-y-1/2 translate-x-3/4 group-hover:translate-x-full transition-all`
    
    //bg-gradient-to-b from-base-50 to-white 
    //       ${view.inOverview == 'designSystem' ? '' : 'border-r-[0.5px] border-base-200'}
    function openHome() {
      setSelector({ ...selector, folder: null, page: null, frame: null, object: null });
      setView({...view, inOverview: 'project',});
    }

    const projectName = project?.project?.name
    const projectSymbol = assets?.find(a => a.api_name == 'symbol')?.file_url
    
    return(
      <div className={`flex flex-col h-full flex-shrink-0 gap-1 relative transition-all duration-75 relative select-none
      py-3 pt-2 px-3 ${isOpen ? 'w-[52px] md:w-60 ' : 'w-[52px]'} duration-75`}
      onClick={() => !isOpen && setIsOpen(true)}
      style={{
        height: `100%`,
        maxHeight: `100vh`,
        cursor: 'default', 
        zIndex: 1000,
      }}
      ref={navigationRef} 
      >
        <div className='w-full flex flex-col gap-1 items-start justify-start'>
          <div className='flex flex-col gap-1 items-start justify-start z-10 w-full mb-1.5'>
          
            <OrgMenu  isCollapsed={!isOpen} icon={'arrows-up-down'} width={'full'} size={'small'} imageSrc={projectSymbol} name={projectName}>
              <SidebarLink size={'small'} onClick={() => navigate('/projects')} leftIcon={'arrow-left'} text={'All Projects'}hoverEffect={true} /> 
              <Divider margins={'6px'}color='base-100'/>
              <SidebarLink  size={'small'} onClick={handleLogout} leftIcon={'log-out'} text={'Logout'}hoverEffect={true}/>  
            </OrgMenu>

          </div>
          <div className='flex flex-col gap-1 items-start justify-start z-0 w-full'>
          <SidebarLink  
          size={'small'}
          onClick={openHome}
          leftIcon={'home'}
          isActive={view.inOverview == 'project'}
          text={'Overview'}
          isCollapsed={!isOpen}
          hoverEffect={true}
          
        /> 

          
        <SidebarLink  
          size={'small'}
          onClick={()=> {setView({...view, inOverview: 'designSystem'})}}
          leftIcon={'colors'}
          isActive={view.inOverview == 'designSystem'}
          text={'Themes'}
          isCollapsed={!isOpen}
          hoverEffect={true}
        /> 
        <SidebarLink  
          size={'small'}
          onClick={()=> {setView({...view, inOverview: 'brand'})}}
          leftIcon={'flower'}
          isActive={view.inOverview == 'brand'}
          text={'Branding'}
          isCollapsed={!isOpen}
          hoverEffect={true}
        /> 
        <SidebarLink  
          size={'small'}
          onClick={()=> {setView({...view, inOverview: 'ui_kit'})}}
          leftIcon={'box'}
          isActive={view.inOverview == 'ui_kit'}
          text={'Components'}
          isCollapsed={!isOpen}
          hoverEffect={true}
        /> 
        <SidebarLink  
          size={'small'}
          onClick={()=> {setView({...view, inOverview: 'api_docs'})}}
          leftIcon={'post'}
          isActive={view.inOverview == 'api_docs'}
          text={'API Docs'}
          isCollapsed={!isOpen}
          hoverEffect={true}
        /> 
        </div>
        </div>

        {/* overflow-hidden  take out */}
        <div className='flex flex-col w-full flex-grow h-full w-full text-xs gap-1'>
          <div className={` gap-1 items-center justify-between mt-3 px-0 pr-1 py-1 group relative font-medium
          overflow-hidden
          ${isOpen ? 'hidden md:flex md:flex-row' : '!hidden'} 
          `}>
              <h2 className={`opacity-60 w-full text-xs font- items-center justify-between px-1`}>
                  Folders
            </h2>
            <div></div>
            <AddFolderPlus folders={folders}/>
          </div>
          <Folders sidebarOpen={isOpen} 
          tooltipStyle={tooltipStyle}
          />
        </div>
        
        
        {/*
        <div className='w-full flex flex-col gap-1 items-start justify-start'>
          <UserMenu sidebarOpen={isOpen} tooltipStyle={tooltipStyle}/>
        </div>
        {/**/}
        </div>
    )
  }
