import React, { useRef } from 'react';
import * as FeatherIcons from 'react-icons/fi';
import * as Ionicons from 'react-icons/io5'; 
import * as MaterialIcons from 'react-icons/md';
import * as HeroIcons from 'react-icons/hi';
import * as IconoirIcons from 'iconoir-react';

import { getIconName, iconMap } from './iconMap'

const allIconNames = Object.keys(iconMap)

type IconProps = {
    icon?: string;
    library?: 'feather' | 'ionic' | 'material' | 'heroicons' | 'iconoir';
    color?: string;
    size?: 'auto' | string;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    attributes?: React.HTMLAttributes<HTMLDivElement>;
    listeners?: React.DOMAttributes<HTMLDivElement>;
  };

export default function Icon({
        icon = 'add',
        library,
        color,
        size = 'auto',
        className = '',
        onClick,

        attributes,
        listeners, 
      }: IconProps) {


    const colorStyles = (color == 'auto' || color == 'none' || !color) ? '' : `text-${color}`
    let wrapperClasses = `${colorStyles}`
    
    const iconRef = useRef(null)
    const globalIconSet = iconRef?.current ? getComputedStyle(iconRef.current).getPropertyValue('--iconset').trim() : 'iconoir'
    
    const fallbackIconSet = 'iconoir'
    const libraryMap = {
        feather: FeatherIcons,
        ionic: Ionicons,
        material: MaterialIcons,
        heroicons: HeroIcons,
        iconoir: IconoirIcons,
    };
    
    const defaultIconSet = libraryMap[globalIconSet] ? globalIconSet : fallbackIconSet;
    
    const useLibrary =  (!library || libraryMap[library] === undefined) ? defaultIconSet : library;
    
    const mappedIconName = getIconName(icon, useLibrary);
    
    
    const IconLibrary = libraryMap[useLibrary];
    
    const IconComponent = IconLibrary[mappedIconName] || null

    const sizeInt = parseInt(size, 10);
    
    if (!IconComponent) return null;
    const iconSize = size == 'auto' ? `1.5em` : sizeInt
    return (
        <div
        className={wrapperClasses}
        {...attributes} {...listeners} 
onClick={onClick}
        >
        <div className='absolute w-0 h-0 invisible' ref={iconRef} />
        <IconComponent size={iconSize} color={color} className={className} />
        </div>
    );
}



