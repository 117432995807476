import React, { useState, useEffect, useRef } from 'react';
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Icon } from '../'; /* replace to 'import { Icon } from './index'' */


const sampleOptions = [
    { label: 'Option A', value: 'option-a'},
    { label: 'Option B', value: 'option-b'},
    { label: 'Option C', value: 'option-c'}
]

type SelectProps = {
    width?: 'auto' | '1/2' | 'full';
    size?: 'small' | 'medium' | 'large';
    label?: string;
    state?: 'default' | 'disabled' | 'error' | 'success';
    bgColor?: 'base-0' | 'base-50' | 'base-100' | 'none' | 'current-5' | string;
    value?: string;
    placeholder?: string;
    showOptions?: boolean;
    options?: { label: string, value: string }[];
    rightIcon?: 'chevron-down' | 'none';
    hasOutline?: boolean;
    onChange?: (value: string) => void;
    helperText?: string;
    attributes?: Record<string, unknown>;
    listeners?: Record<string, unknown>;
    junoProps?: { /* remove line */
        showTag?: boolean;  /* remove line */
        junoAttributes?: Record<string, unknown>;  /* remove line */
        outlineStyle?: string;  /* remove line */
        onPropertyUpdate?: (...args: any[]) => unknown;  /* remove line */
    }; /* remove line */ 
};

export default function Select({
        size = 'medium',
        placeholder = 'Select',
        showOptions = false,
        bgColor = 'current-5',

        options: externalOptions,
        value: externalValue,

        label = '',
        helperText = '',
        state = 'default',
        rightIcon = 'chevron-down',
        width = 'auto',
        hasOutline = false,
        onChange = () => {},
        attributes,
        listeners, 
        junoProps /* remove line */
      }: SelectProps) {

    const { junoAttributes,  showTag, outlineStyle, onPropertyUpdate=() => {} } = junoProps || {} /* remove line */

    const [ open, setOpen ] = useState(showOptions)
    const [internalOptions, setInternalOptions] = useState(externalOptions || sampleOptions);
    const [selectedValue, setSelectedValue] = useState(externalValue || internalOptions[0].value);
    const isControlled = externalOptions !== undefined && onChange !== undefined;
    const options = isControlled ? externalOptions : internalOptions;
    const value = isControlled ? externalValue : selectedValue
    const selectedOption = options.find(o => o.value == value)
    

    useEffect(() => {if (externalOptions) {setInternalOptions(externalOptions);}}, [externalOptions]);
    useEffect(() => {if (externalValue) {setSelectedValue(externalValue);}}, [externalValue]);     
    
    function handleSelect(e: React.MouseEvent<HTMLDivElement>, option: any) {
        const value = option?.value || option
        if (isControlled) {
            onChange(value);
        } else {
            setSelectedValue(value);
        }
    }

    useEffect(() => {
        setOpen(showOptions);
    }, [showOptions]);
    
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);
    
    const sizeStyles = size == 'small' ? `py-1 px-2 gap-1.5 text-xs` : size == 'large' ? `py-2 px-3 gap-3 text-base` : `py-1.5 px-2 gap-3 text-sm`;
        
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    
    const ringSize = size == 'small' ? '1' : size == 'large' ? '2' : '1.5'
    // default
    let stateStyles = hasOutline ? `ring-1 ring-inset ring-current-10 focus-within:ring-[${ringSize}px] focus-within:ring-primary` : `ring-1 ring-inset ring-transparent focus-within:ring-[${ringSize}px] focus-within:ring-primary`;
    switch (state) {
        case 'disabled':
            stateStyles = `bg-base-100 opacity-70 cursor-not-allowed ${hasOutline ? 'ring-1 ring-inset ring-current-10' : ''}`
            break;
        case 'error':
            stateStyles = `text-warning ${hasOutline ? 'ring-1 ring-inset ring-warning' : ''}`
            break;
        case 'success':
            stateStyles = `text-success ${hasOutline ? 'ring-1 ring-inset ring-success' : ''}`
            break;
    }

    const heightStyle = size == 'small' ? 'h-7' : size == 'large' ? 'h-12' : 'h-9';
    const bgStyles = (bgColor && bgColor !== 'none') ? `bg-${bgColor}` : 'bg-base-100 text-base-content'
    
    let classes = `w-full flex items-center justify-between truncate ellipsis box-border font-medium select-none ${sizeStyles} ${heightStyle} ${cornerStyles} ${bgStyles} ${stateStyles}`
    const labelTextSize = size == 'small' ? `text-xs` :  size == 'large' ? `text-lg`: `text-sm`;
    const labelClasses = `${labelTextSize} font-medium`

    const messageTextColor = state == 'error' ? 'text-warning' : state == 'success' ? 'text-success' : 'text-base-content'
    const messageClasses = `text-sm ${messageTextColor}`
    const widthStyle = width != 'auto' ? `w-${width}` : size == 'small' ? 'min-w-[120px]' : size == 'large' ? 'min-w-[200px]' : 'min-w-[160px]'
    const gapStyles = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    let wrapperClasses = `flex flex-col ${widthStyle} ${gapStyles} relative ${open ? 'z-50' : ''}`
    wrapperClasses += ` ${outlineStyle} ` /* remove line */

    const RightIconComponent = rightIcon !== 'none' ?  <Icon icon={rightIcon?.toLowerCase()} className={`flex-shrink-0 flex-grow-0 opacity-80 scale-75`}/> : null;

    /* OPTIONS STYLING */
    const shadowStyles = size == 'small' ? 'shadow-sm' : size == 'large' ? 'shadow-md' : 'shadow'
    const optionsBorderRadius = (size === 'small' ? 'rounded' : size === 'large' ? 'rounded-lg' : 'rounded-md');
    const smallerRadius = size === 'small' ? 'rounded-sm' : size === 'large' ? 'rounded-md' : 'rounded';

    const optionsClasses = `w-full absolute mt-2 p-0.5 gap-0.5 bg-base-0 overflow-hidden ${optionsBorderRadius} ${shadowStyles} ring-[0.5px] ring-inset ring-current-10`
    const optionSizeStyles = size == 'small' ? `py-1 px-2 gap-1.5 text-xs min-w-[120px]` :  size == 'large' ? `py-2 px-3 gap-3 text-base min-w-[200px]`: `py-1.5 px-2 gap-3 text-sm min-w-[160px]`;
    
    const optionClasses = `${optionSizeStyles} hover:bg-current-10 transition-all duration-75 ease-in-out cursor-default ${smallerRadius}`

    return (
        <div
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={wrapperClasses}
        
    >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Select</div> /* remove line */}
        {label && <label className={labelClasses}>
                    <EditableOnDoubleClick text={label} onBlur={onPropertyUpdate('label')} whiteSpace='nowrap'  /* replace to '{label}' */ /> 
                </label>}
        <div className={classes} ref={dropdownRef}
        /* replace to 'onClick={(e) => {e.stopPropagation(); setOpen(!open)}}' */
        >
        {selectedOption ? 
            (selectedOption?.label || selectedOption?.value) :
            <span className={'text-current-70'}> 
            <EditableOnDoubleClick text={placeholder} onBlur={onPropertyUpdate('placeholder')} whiteSpace='nowrap' /* replace to '{placeholder}' */ />
            </span>} 
        
        {RightIconComponent}
        {open && (
            <div className={optionsClasses}
            
             style={{ position: 'absolute', top: '100%', left: 0, zIndex: 100 }}>
                {options.map((option, index) => (
                    <div 
                        key={index}
                        className={optionClasses}
                        /* replace to 'onClick={(e) => handleSelect(e, option)}' */
                        >
                    {option?.label || option?.value || option.toString()}
                    </div>
                ))}
            </div>
        )}
        </div>
        {helperText && <span className={messageClasses}>
            <EditableOnDoubleClick text={helperText} onBlur={onPropertyUpdate('helperText')} whiteSpace='pre-wrap' /* replace to '{helperText}' */ />
        </span>}
    </div>
);  
}

/* ignore rest */

Select.definitions = {
    apiName: 'Select',
    displayName: 'Select Dropdown',
    description: 'A dropdown select component allowing users to choose from a list of options. Features include customizable size, background color, and the ability to show or hide options.',
    ai_instructions: 'dropdown list. show/hides options on click',
    type: 'inputs',
    relativeSize: 'small',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        
            
        state: {
            type: 'oneOf',
            options: ['default', 'disabled', 'error', 'success'],
            default: 'default', 
            displayName: 'State',
            tile: '1/2'
        },
        bgColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-50', 'none'],
            displayName: 'Background',
            default: 'base-100', 
            tile: '1/2',
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            tile: '1/2',
            defaultOnMobile: 'full'
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            displayName: 'Size',
            default: 'medium', 
            tile: '1/2'
        },
        
        label: {
            type: 'string',
            default: '', 
            displayName: 'Label',
        },

        value: {
            type: 'string',
            displayName: 'Value',
            default: '', 
        },
        options: {
            type: 'arrayOfStrings',
            displayName: 'Options',
            default: sampleOptions,
        },
        placeholder: {
            type: 'string',
            default: 'placeholder text', 
            required: true,
            ai_instructions: 'value example, e.g. "email@example.com" or "Acme Corp Inc". do not write placeholder like action "Enter text". limit to 3 words.'
        },
        helperText: {
            type: 'string',
            default: '', 
            displayName: 'Help Text (hint/error)',
            ai_instructions: 'the text under the field'
        },
        
        rightIcon: {
            type: "oneOf",
            options: ['chevron-down','none'],
            displayName: "Right Icon",
            default: 'chevron-down', 
            ai_instructions: 'icon',
            tile: '1/2'
        },

        
        showOptions: {
            type: 'bool',
            displayName: 'Show Options',
            default: false, 
            
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
        }, 
    }
}



/*
Select.propTypes = {
    width: PropTypes.oneOf(['auto', '1/2', 'full']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    label: PropTypes.string,
    state: PropTypes.oneOf(['default', 'disabled', 'error', 'success']),
    bgColor: PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'none']),
    value: PropTypes.string,
    placeholder: PropTypes.string,
    showOptions: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      ),
    rightIcon: PropTypes.oneOf(['chevron-down','none']),
    hasOutline: PropTypes.bool,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
};
 */