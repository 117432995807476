import React, { useState } from 'react';
import { MiniSnippet } from 'ui-kit/exports/react';
import { Button } from "ui-kit/exports/react";

export default function BelvoConnect() {
  const [accessToken, setAccessToken] = useState('');
  const [linkId, setLinkId] = useState('');
  const [accountData, setAccountData] = useState(null);

  const createWidget = async () => {
    try {
      const response = await fetch('/belvo-get-token');
      const data = await response.json();
      const access = data.access_token;
      setAccessToken(access);
      console.log('Access token:', access);

      const successCallbackFunction = (link, institution) => {
        console.log('Success callback:', link, institution);
        setLinkId(link);
      };

      const onExitCallbackFunction = (data) => {
        console.log('Exit callback:', data);
      };

      const onEventCallbackFunction = (data) => {
        console.log('Event callback:', data);
      };

      const config = {
        locale: 'en',
        institution_types: ['fiscal'], // Restrict to fiscal institutions only
        callback: successCallbackFunction,
        onExit: onExitCallbackFunction,
        onEvent: onEventCallbackFunction,
        // Add any additional configuration if needed
      };

      window.belvoSDK.createWidget(access, config).build();
    } catch (error) {
      console.error('Error fetching access token or initializing widget:', error);
    }
  };

  const fetchAccountData = async () => {
    try {
      const response = await fetch(`/belvo-get-invoices?link_id=${linkId}`);
      const data = await response.json();
      setAccountData(data);
      console.log('Account data:', data);
    } catch (error) {
      console.error('Error fetching account data:', error);
    }
  };

  
  return (
    <div className='w-screen min-h-screen flex flex-col items-center justify-center overflow-y-scroll p-10'>
      <div id="belvo"></div>
      <div className='w-[480px] mx-auto flex flex-col items-center justify-start gap-4'>
      <Button
        text='Connect Account'
        onClick={createWidget}
      />
      {linkId && (
        <>
          <p>Link ID: {linkId}</p>
          <Button
            text='Fetch Data'
            onClick={fetchAccountData}
          />
          
        </>
      )}
      {accountData && (
        <>
          <h2>Invoice Data:</h2>
          <MiniSnippet
          width='full'
          text={JSON.stringify(accountData, null, 2)}
          maxHeight={320}
          />
        </>
      )}
      
      </div>
    </div>
  );
}