import { ArrowLeft, ArrowRight, MediaImage, NavArrowLeft, Upload, Xmark } from 'iconoir-react';
import React, { useState, useRef } from 'react';
import { Loader } from '../../../../ui-kit/local/index';

export default function ImgSearchUnsplash({ onSubmit, initialPrompt, handleImageUpload, onClose }) {
  const [prompt, setPrompt] = useState(initialPrompt || '');
  const [loading, setLoading] = useState(false);
  const [imageURLs, setImageURLs] = useState([]);
  const [selectedImageURL, setSelectedImageURL] = useState('');
  const [page, setPage] = useState(0);  // NEW: current page of the images

  const inputRef = useRef();

  const handleInputChange = (e) => {
    setPrompt(e.target.value);
  };

  function handleSubmit() {
    setLoading(true);
    fetchImagesFromUnsplash(prompt)
      .then(response => {
        setLoading(false);
        if (response && response.length > 0) {
          setImageURLs(response);
          setSelectedImageURL(response[0].urls.large);
          onSubmit(response[0].urls.large);
        } else {
          console.error('Failed to get images from Unsplash or invalid response');
        }
      });
  };

  const fetchImagesFromUnsplash = async (query) => {
    try {
      const response = await fetch(`/get-unsplash-images?q=${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };

  const nextImages = () => {
    setPage(prevPage => prevPage + 1);
  };

  const prevImages = () => {
    setPage(prevPage => prevPage - 1);
  };

  return (
    <div ref={inputRef} className="w-full flex-grow flex flex-col gap-2">
        <div className='flex flex-row gap-2 w-full justify-between mb-2'>
        <input
          type="text"
          value={prompt}
          onChange={handleInputChange}
          onKeyDown={event => {if (event.key === 'Enter') {handleSubmit();            }}}
          placeholder="Search Stock Images"
          className="w-full bg-base-100 px-2 py-1 rounded-lg  focus:bg-base-0 focus:ring-1 focus:ring-primary transition-all"
        />        
        
        <div  className='flex items-center justify-center bg-base-100 w-6 h-full transition-all py-1 font-medium rounded-md flex-shrink-0
        hover:bg-base-0 hover:ring-1 hover:ring-base-200 cursor-pointer'
            onClick={() => {document.getElementById("uploadInput").click();}}>
            <Upload width={20} height={20} />
            <input
                type="file"
                className=''
                id="uploadInput"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
        </div>
        <div 
        className='flex items-center justify-center bg-base-100 w-6 h-full transition-all py-1 font-medium rounded-md flex-shrink-0
        hover:bg-base-0 hover:ring-1 hover:ring-base-200 cursor-pointer'onClick={() => onClose('')}
        ><Xmark width={20} height={20}  />
        </div><div>
        </div>
        </div>
      <div className="grid grid-cols-3 gap-2">
        {
          Array(3).fill(null).map((_, index) => {
            const imgData = imageURLs[page * 4 + index];
            
            return (
              <div key={`cell-${index}`} 
              className='flex justify-center items-center rounded-md bg-base-200 text-white relative aspect-square'
              style={{
                backgroundImage: imgData ? `url(${imgData.urls.small})` : 'none',
                backgroundSize: 'cover',
              }}
              onClick={() => {
                setSelectedImageURL(imgData.urls.large);  
                onSubmit(imgData.urls.large);
              }}
              >
                {!imgData && <Loader />}
               
                
              </div>
            );
          })
        }
      </div>
      <div className="flex flex-row gap-2 w-full justify-between mb-2">
        <button onClick={prevImages} disabled={page === 0} className='py-1 px-2 bg-base-100 rounded-md hover:scale-110 transition-all cursor-pointer'><ArrowLeft width={16} height={16}/></button>
        <button onClick={nextImages} disabled={(page + 1) * 4 >= imageURLs.length} className='py-1 px-2 bg-base-100 rounded-md hover:scale-110 transition-all cursor-pointer'><ArrowRight width={16}/></button>
      </div>
    </div>
  );
}


//${selectedImageURL === imgData.urls.small ? 'generated-image-selected' : ''} rounded-md `}