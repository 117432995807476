import React from 'react';
import { allFlags } from '../assets/flags/flags'; /* replace to 'import { allFlags } from './assets/flags/flags'' */
import * as Flags from '../assets/flags/index'; /* replace to 'import * as Flags from './assets/flags/index'' */

const countryNames = allFlags.map(f => f.name); 

type CountryFlagProps = {
    country: string;
    corners: 'none' | 'sm' | 'base' | 'md' | 'lg' | 'full',
    aspectRatio: 'square' | '4x3',
    height: '12px' | '16px' | '20px' | '24px' | '28px' | '32px',
    attributes: React.HTMLAttributes<HTMLDivElement>;
    listeners: React.DOMAttributes<HTMLDivElement>;
    junoProps: any; /* remove line */
}

export default function CountryFlag({
        country = 'United States of America',
        corners = 'none',
        aspectRatio = '4x3',
        height = '16px',
        attributes,
        listeners, 
        junoProps, /* remove line */
      }: CountryFlagProps) {
    
    const { junoAttributes, outlineStyle } = junoProps || {} /* remove line */
    
    let classes = `flex-shrink-0 flex-grow-0 rounded-${corners}`
    classes += ` ${outlineStyle}` /* remove line */

    // Find correct flag SVG
    const flag = allFlags.find(f => f.name === country);
    const aspectSuffix = aspectRatio === 'square' ? '1By1' : '4By3';
    const flagCodeCamel = flag.code
    const flagSvg = Flags[`${flagCodeCamel}${aspectSuffix}`];
    const imageStyles = {
        backgroundImage: flagSvg ? `url(${flagSvg})` : placeholderImg(),
        backgroundSize: 'cover',
        height: height, 
        aspectRatio: aspectRatio === 'square' ? '1 / 1' : '4 / 3'
    };
    
    return (
        <div
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={classes}
            style={imageStyles}
        />
    );
}

function placeholderImg() {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <rect x="1" y="1" width="100" height="32" fill="#EEEEEE" /> <!-- Top rectangle with lightest grey -->
                    <rect x="1" y="33" width="100" height="34" fill="#CCCCCC" /> <!-- Middle rectangle with medium grey -->
                    <rect x="1" y="67" width="100" height="33" fill="#AAAAAA" /> <!-- Bottom rectangle with darkest grey -->
                 </svg>`;
    return `url('data:image/svg+xml;utf8,${encodeURIComponent(svg)}')`;
}



/* ignore rest */

CountryFlag.definitions = {
    apiName: 'CountryFlag',
    displayName: 'CountryFlag',
    description: `This component displays a flag based on the country name provided.`,
    ai_instructions: `Small country flag image. Will render a placeholder image if no country is none.`,
    type: 'media',
    relativeSize: 'varies',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        country: {
            type: 'oneOf',
            options: ['none', ...countryNames], 
            default: 'United States of America', 
            displayName: 'Country', 
            
        },
        height: {
            type: 'oneOf',
            options: ['12px', '16px', '20px', '24px', '28px', '32px'],
            default: '16px', 
            displayName: 'Height', 
            tile: '1/2'
        },
        corners: {
            type: 'corners',
            options: ['none', 'sm', 'base', 'md', 'lg', 'full'],
            default: 'none', 
            displayName: 'Corners',
            tile: '1/2'
        },
        aspectRatio: {
            type: 'oneOf',
            options: ['square', '4x3'],
            default: '4x3', 
            displayName: 'Aspect Ratio'
        }, 
    }
}


/*CountryFlag.propTypes = {
    country: PropTypes.oneOf(['none', ...countryNames]),
    corners: PropTypes.oneOf(['none', 'sm', 'base', 'md', 'lg', 'full']),
    aspectRatio: PropTypes.oneOf(['square', '4x3']),
    height: PropTypes.oneOf(['12px', '16px', '20px', '24px', '28px', '32px']),
};*/