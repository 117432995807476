import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { DatePicker } from '../'; /* replace to 'import { DatePicker } from './index'' */

type DateRangeProps = {
    label?: string;
    helperText?: string;
    state?: 'default' | 'error' | 'disabled' | 'success';
    bgColor?: string;
    hasOutline?: boolean;
    size?: 'small' | 'medium' | 'large';
    width?: 'auto' | '1/2' | 'full';
    
    dateFrom?: string;
    dateTo?: string;

    attributes?: Record<string, unknown>;
    listeners?: Record<string, unknown>;
    junoProps?: { /* remove line */
        junoAttributes?: Record<string, unknown>; /* remove line */
        showTag?: boolean; /* remove line */
        outlineStyle?: string; /* remove line */
        onPropertyUpdate?: (...args: any[]) => unknown; /* remove line */
    }; /* remove line */
};

export default function DateRange({
    label = '',
    helperText = '',
    
    state = 'default',
    bgColor = 'current-5',
    hasOutline= false,
    
    size = 'medium',
    width = 'auto',
    
    dateFrom = '',
    dateTo = '',

    attributes,
    listeners, 
    junoProps /* remove line */
}: DateRangeProps) {
    
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{} } = junoProps || {} /* remove line */
    
    const labelTextSize = size == 'small' ? `text-xs` :  size == 'large' ? `text-lg`: `text-sm`;
    const labelClasses = `${labelTextSize} font-normal`

    let stateStyles = ''
    switch (state) {
        case 'disabled':
            stateStyles = `opacity-70 cursor-not-allowed`
            break;
        case 'error':
            stateStyles = `text-warning`
            break;
        case 'success':
            stateStyles = `text-success`
            break;
    }

    const messageTextColor = state == 'error' ? stateStyles = 'text-warning' : state == 'success' ? stateStyles = 'text-success' : ''
    const messageClasses = size == 'large' ? `text-base  ${messageTextColor}` : `text-sm ${messageTextColor}`

    const gapStyles = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    let wrapperClasses = `flex flex-col ${width} ${gapStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    return (
        <div
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={wrapperClasses}
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Date Range</div> /* remove line */}
            {label && ( 
                <label className={labelClasses}>
                    <EditableOnDoubleClick text={label} onBlur={onPropertyUpdate('label')} whiteSpace='nowrap'  /* replace to '{label}' */ />
                </label>
            )}
            <div className={`flex flex-row gap-2 items-center`} style={{boxSizing: 'border-box', position: 'relative'}}>
                <DatePicker 
                    size={size}
                    width={'full'}
                    state={state}
                    bgColor={bgColor}
                    hasOutline={hasOutline}
                />
                <DatePicker
                    size={size}
                    width={'full'}
                    state={state}
                    bgColor={bgColor}
                    hasOutline={hasOutline}
                />
            </div>
            {helperText && <span className={messageClasses}>
                <EditableOnDoubleClick text={helperText} onBlur={onPropertyUpdate('helperText')} whiteSpace='pre-wrap' /* replace to '{helperText}' */ />
            </span>}    
        </div>
    );
}

/* ignore rest */

DateRange.definitions = {
    apiName: 'DateRange',
    displayName: 'Date Range Picker',
    description: 'A component for selecting a range of dates. It includes options for labeling, hinting, and displaying a calendar. The component can be customized in size and background color, and supports various states.',
    ai_instructions: 'data range with an optional label, hint. on click opens a calendar view',
    type: 'inputs',
    relativeSize: 'small',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        state: {
            type: 'oneOf',
            options: ['default', 'disabled', 'error', 'success'],
            default: 'default', 
            displayName: 'State',
            tile: '1/2',
        },
        bgColor: {
            type: 'colors',
            options: ['none', 'base-0', 'base-50', 'base-100', 'current-5', 'current-10'],
            default: 'base-50',
            displayName: 'Background',
            tile: '1/2',
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2',
        },
        
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            default: 'medium', 
            displayName: 'Size',
            tile: '1/2',
        },        
        label: {
            type: 'string',
            default: '', 
            displayName: 'Label',
            tile: '1/2',
        },
        helperText: {
            type: 'string',
            default: '', 
            displayName: 'Hint',
            tile: '1/2',
        },
        dateFrom: {
            type: 'string',
            displayName: 'from',
            default: ''
        },
        dateTo: {
            type: 'string',
            displayName: 'to',
            default: ''
        }
    }
}
/*
    DateRange.propTypes = {
    width: PropTypes.oneOf(['auto', '1/2', 'full']),
    label: PropTypes.string,
    hint: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    bgColor: PropTypes.oneOf(['none', 'base-0', 'base-100']),
    showCalendar: PropTypes.bool,
    state: PropTypes.oneOf(['placeholder', 'filled', 'disabled', 'warning', 'success']),
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
};*/