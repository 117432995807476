import React from 'react';
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers';' */

type SidePanelProps = {
    background?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'base-700' | 'base-content' | 'primary' | 'accent' | string,
    width?: '240px' | '280px' | '320px' | '360px' | '480px',
    paddingX?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px' | '64px',
    paddingY?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px' | '64px',
    gap?: '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    hasOutline?: boolean,
    alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch',
    justifyContent?: 'start' | 'end' | 'center' | 'between',
    display?: boolean,
    textSize?: 'sm' | 'base' | 'md',
    position?: 'left' | 'right',
    children?: React.ReactNode,
    attributes?: any,
    listeners?: any,
    junoProps?: any, /* remove line */
}

export default function SidePanel({
        paddingX = null,
        paddingY = null,
        gap = null,

        background = 'base-50',

        position = 'left',
        hasOutline = true,
        
        alignItems = 'stretch',
        justifyContent = 'start',
        display = true,
        textSize = 'base',
        width = '280px',
        children,
        attributes,
        listeners, 
        junoProps /* remove line */
      }: SidePanelProps) {
    const { junoAttributes, showTag, outlineStyle } = junoProps || {} /* remove line */

    const bgStyles = background ?`bg-${background}` : '';
    const fontColor = (!background || background == 'none') ? '' : (background?.startsWith('base') && background != 'base-content' && background != 'base-700') ? 'text-base-content' : `text-base-0`
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';

    const fontSize = `text-`+textSize
    const borderStyles = !hasOutline ? '' : position == 'left' ? 'border-r-[0.5px] border-base-300' : 'border-l-[0.5px] border-base-300';
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    
    let classes = `flex flex-col flex-grow-0 flex-shrink-0 z-30 h-full relative ${paddingStyles} ${fontColor} ${fontSize} ${bgStyles} ${borderStyles} ${gapStyles} ${alignItemsStyles} ${justifyContentStyles}`
    classes += ` ${outlineStyle}` /* remove line */

    if (display) return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={classes}
        style={{
            order: position == 'left' ? '-1' : 1, 
            width: width,
            maxWidth: width,
            minWidth: width
        }}>
        {showTag && <div className='selected-element-inside' style={{zIndex: 2000}}>Side Panel</div> /* remove line */ }
        {children}
        </div>
    );
}


/* ignore rest */


SidePanel.definitions = {
    apiName: 'SidePanel',
    displayName: 'SidePanel',
    description: '...',
    ai_instructions: 'side panel used to display secondary content or secondary navigation',
    type: 'section',
    relativeSize: 'large',
    package: null,
    acceptedChildren: 'everything except other sections or dialogs',
    propDefinitions: {
        width: {
            type: 'oneOf',
            options: ['240px', '280px', '320px', '360px', '480px'],
            displayName: 'Width',
            default: '280px', 
            tile: '1/2'
        }, 
        position: {
            type: 'oneOf',
            options: ['left', 'right'],
            displayName: 'Position',
            default: 'left', 
            tile: '1/2'
        },
        background: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'base-700', 'base-content'],
            displayName: 'Background',
            default: 'base-0'
        },

        
        paddingX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: null
        },
        paddingY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null
        },
        gap: {
            type: 'gap',
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        textSize: {
            type: 'oneOf',
            options: ['sm', 'base', 'md'],
            default: 'base',
            tile: '1/2'
        },
        alignItems: {
            type: 'alignItems',
            options: ['start', 'end', 'center',  'stretch'],
            displayName: 'Align Items',
            default: 'stretch', 
            tile: '1/2'
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['start', 'end', 'center', 'between', ],
            displayName: 'Justify Content',
            default: 'start', 
            tile: '1/2'
        },
        hasOutline: {
            type: 'bool',
            ai_instructions: 'adds subtle outline on one side of the sidebar, e.g. if Sidebar position is left the outline will be on the right',
            displayName: 'Border',
            default: true,
        },
        display: {
            type: 'bool',
            default: true,
            displayName: 'Display', 
            defaultOnMobile: false, 
        },
    }
};

/*SidePanel.propTypes = {
    background: PropTypes.oneOfType(
        [PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200', 'base-700', 'base-content']),
        PropTypes.string]),
    width: PropTypes.oneOf(['240px', '280px', '320px', '360px', '480px']),
    position: PropTypes.oneOf(['left', 'right']),
    paddingX: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    paddingY: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    gap: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    hasOutline: PropTypes.bool,
    alignItems: PropTypes.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
    justifyContent: PropTypes.oneOf(['start', 'end', 'center', 'between']),
    display: PropTypes.bool,
    textSize: PropTypes.oneOf(['sm', 'base', 'md']),
};*/