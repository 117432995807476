import Fuse from 'fuse.js';
import * as IconoirIcons from 'iconoir-react';
import { useEffect, useMemo, useState } from 'react';
import { Alert } from 'ui-kit/exports/react';
import SearchInput from 'ui-kit/exports/react/react/Search'

export default function Search({
    searchResults,
    setSearchResults,
    showSearch,
    setShowSearch,
    items,
}) {
    const [searchQuery, setSearchQuery] = useState('');
    useEffect(() => {
        if (searchQuery === '') {
            setShowSearch(false);
        } else {
            setShowSearch(true);
        }   
    }, [searchQuery]);

    const fuse = useMemo(() => new Fuse(items, {
        keys: ['name'],
        includeScore: true,
        threshold: 0.2,
        tokenize: true
    }), [items]);

    useEffect(() => {
        if (searchQuery?.length > 0) {
            // Perform search and map results to the items
            const results = fuse.search(searchQuery).map(result => result.item);
            setSearchResults(results);
            setShowSearch(true);
        } else {
            setSearchResults([]);
            setShowSearch(false);
        }
    }, [searchQuery]);

    function handleClearSearch() {
        setSearchQuery('');
        setSearchResults([]);
        setShowSearch(false);
    }

    return (
        
            <SearchInput 
                value={searchQuery}
                size='small'
                onChange={(e) => setSearchQuery(e.target.value)}
                onBlur={() => (!searchQuery || searchQuery.length === 0) && setShowSearch(false)}
            />
        
    );
}