import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../../EditorContext";
import { Alert, Checkbox, InputText, MiniSnippet, TextArea, ToggleSwitch } from "../../../../ui-kit/exports/react";

export default function FrameEditor({selector}) {
    const { handleAction, isAdmin, project } = useContext(EditorContext);
    
    const currentFrame = selector?.frame;

    // State for input and textarea
    const [frameName, setFrameName] = useState(currentFrame?.name);
    const [frameNotes, setFrameNotes] = useState(currentFrame?.notes || '');
    const [isTemplate, setIsTemplate] = useState(currentFrame?.use_template || false);

    useEffect(() => {
        setFrameName(currentFrame?.name);
        setFrameNotes(currentFrame?.notes || '');
        setIsTemplate(currentFrame?.use_template || false);
    }, [currentFrame]); // Only currentFrame in the dependency array
      

    function updateFrameName() {
        const action = { type: 'UPDATE_FRAME', currentFrame: currentFrame, newFrame: { ...currentFrame, name: frameName }};
        handleAction(action);
    }

    function updateFrameNotes() {
        const action = { type: 'UPDATE_FRAME', currentFrame: currentFrame, newFrame: { ...currentFrame, notes: frameNotes }};
        handleAction(action);
    }

    function handleUseTemplate() {
        // Toggle the checked state
        const newCheckedState = !isTemplate;
        setIsTemplate(newCheckedState);
    
        // Dispatch the action with the new state
        const action = { 
            type: 'UPDATE_FRAME', 
            currentFrame: currentFrame, 
            newFrame: { ...currentFrame, use_template: newCheckedState }
        };
        handleAction(action);
    }
    

    function toggleFavorite() {
        
        // Dispatch the action with the new state
        const action = { 
            type: 'UPDATE_FRAME', 
            currentFrame: currentFrame, 
            newFrame: { ...currentFrame, is_favorite: !currentFrame.is_favorite }
        };
        handleAction(action);
    }
    
    return (
        <div className="flex flex-col items-start gap-3 text-sm p-3">
            <InputText 
                value={frameName}
                label={null}
                placeholder="Frame Name"
                bgColor={'base-50'}
                hasOutline={false}
                onChange={(e) => setFrameName(e.target.value)}
                width={'full'}
                onBlur={(e) => updateFrameName()}

            />
            
            { isAdmin && <APIName frameName={frameName} project={project} currentFrame={currentFrame} />}
            
            <TextArea 
                value={frameNotes}
                label={null}
                hasCharacterCount={false}
                onChange={e => setFrameNotes(e.target.value)}
                onBlur={()=>updateFrameNotes()}
                bgColor={'base-50'}
                hasOutline={false}
                placeholder="Add page notes, e.g. description or notes for the developer"
                rows={4}
                width={'full'}
            />
            <ToggleSwitch
                    label={'Use Template'}
                    type={'primary'}
                    checked={isTemplate}
                    onChange={handleUseTemplate}
                />

                <ToggleSwitch
                    checked={currentFrame.is_favorite}  
                    type={'primary'}
                    onChange={toggleFavorite}
                    label={'Add to Favorites'}
                />
            
            
                {isAdmin &&  <MiniSnippet text={selector.frame?.id} />}
                
                
        </div>
    );
}


function APIName({ frameName, currentFrame,  project}) {

    const allFolders = project?.folders.sort((a, b) => a.index - b.index);
    const allFlows = project?.pages.filter(p => allFolders.map(f=>f.id).includes(p.folder_id)).sort((a, b) => a.index - b.index);
    const allFrames = allFlows?.flatMap(f => f.frames).filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index);
    
    
    const formatName = (name) => {
        return name
            .replace(/[•–]/g, ' ') // Replace special characters with space
            .replace(/-/g, '_') // Replace hyphens with underscores
            .split(' ')
            .map((word, index) => index === 0 ? word.charAt(0).toLowerCase() + word.slice(1) : word.charAt(0).toUpperCase() + word.slice(1))
            .join('')
            .replace(/[^a-zA-Z0-9_]/g, ''); // Allow underscores in the final step
    };
    
    const apiName = formatName(frameName)
    const allFramesAPI = allFrames.filter(frame => frame.id !== currentFrame.id).map(frame => formatName(frame.name));
    const isUnique = !allFramesAPI.includes(apiName);

    return (
    <Alert type={isUnique ? 'success' : 'warning'} text={`api: ${apiName}`} 
    icon={isUnique ? 'check' : 'warning'}
    size={'small'}
    width={'full'}
    />)
    
    

}

