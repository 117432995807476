import { Plus } from 'iconoir-react';
import { prepareProject } from './helpers';
import { Button } from '../../ui-kit/exports/react';

const emptySet = {
  name: "empty",
  folders: [
  {
      "name": "Sample Folder",
      "flows": [
          {
              "name": "User Flow",
              "frames": [
                  {
                      "name": "Sample Page",
                      "notes": "",
                      "jsx": `<AppShell pageBackground="base-50">
                      <Main gap="24px" width="1200" corners="md" marginX="12px" marginY="12px" paddingX="24px" paddingY="24px" textSize="base" direction="flex-col" selfAlign="center" alignItems="start" background="base-0" justifyContent="start" />
                      </AppShell>`
                  }
              ]
          }]
          }
        ]
      }

export default function AddProject({sets, addProject, userId, }) {
    function addSet() {
      const setName = 'empty'
      const projectName = "New Project"
      const payload = prepareProject(userId, emptySet, setName, projectName)
      
      payload && addProject(payload);
    }
  
    return (

      <Button
        size='small'
        color='primary'
        style='filled'
        text='New App'
        onClick={addSet}
        rightIcon='plus'
      />
    );
  }
  