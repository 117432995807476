import React, { useEffect, useState } from 'react';
import { 
    baseColorPresets, 
    functionColorsPresets, 
    primaryColorDefault, 
    accentColorDefault,
    cornersPresets, 
} from './presets';
import { v1 as uuidv1 } from 'uuid';
import ColorPicker from './ColorPicker';
import CornersPicker from './CornersPicker';
import { ArrowLeft, NavArrowLeft, Refresh, Trash } from 'iconoir-react';
import IconsPicker from './IconsPicker';
import FontPicker from './FontPicker';
import { convertTheme, 
    makeDefaultTheme,
    buildThemeObject
 } from './helpers';
import { Button, Select } from '../../../../../../ui-kit/exports/react';


// Presets for easy setup
const fontPresets = ['Inter', 'Lato', 'Montserrat', 'Geologica', 'Merriweather', 'Poppins', 'Noto Sans' ]
const iconSets = ['feather', 'ionic', 'material', 'heroicons', 'iconoir']
const fontPreference = ['default', 'thin', 'bold']
const logos = ['magik_corp', 'hellodoc', 'sample'];

export default function ThemeEditor({themeToEdit, setTempTheme, setState, onSave, onCreate, onDelete}) {
    
    const mode = themeToEdit ? 'edit' : 'build'

    const [theme, setTheme] = useState(
        mode === 'edit' ? 
        convertTheme(themeToEdit)
        : makeDefaultTheme()
    );
    
    useEffect(() => {
        setTempTheme(buildThemeObject(theme));
    }, [theme]);
    

    const handleCancel = () => {
        setTempTheme(null); 
        setState('list')
    }

    const handleSave = () => {
        if (mode === 'edit') {onSave(); } else {onCreate()}
        setState('list')
    }

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this theme?')) {
            onDelete(themeToEdit.id);
            setState('list');
        }
    }
    // console.log(theme?.colors)
    return (
        <div className='w-full gap-3 flex flex-col h-full px-3 py-2'>
            <div className='flex flex-col gap-3 items-stretch flex-grow'>
            <ThemeName theme={theme} setTheme={setTheme} handleSave={handleSave}/>
            {<ColorPicker theme={theme} setTheme={setTheme} baseColorPresets={baseColorPresets} functionColorsPresets={functionColorsPresets}/>}
            <FontPicker theme={theme} setTheme={setTheme} fontPresets={fontPresets} />
            <CornersPicker theme={theme} setTheme={setTheme} cornersPresets={cornersPresets}/>
            <IconsPicker theme={theme} setTheme={setTheme} iconSets={iconSets}/>

            </div>

            
            {/* Save, Cancel, Delete buttons */}
            <div className='flex flex-row gap-2 items-start'>
                <Button size='small' type='ghost' text='cancel'  onClick={handleCancel} leftIcon='refresh' />
                {mode === 'edit' &&  <Button size='small' type='ghost' text='delete' onClick={handleDelete} leftIcon='trash'/>}
            </div>
                
                
        </div>
    );
}



function ThemeName({theme, setTheme, handleSave}) {
    const handleNameChange = (event) => {
        setTheme({
            ...theme,
            display_name: event.target.value
        });
    };

    return (
        
                <div className='flex flex-col gap-2 items-start mb-4'>
                    <span 
                        className='text-xs opacity-60 hover:opacity-80 flex flex-row gap-1 items-center hover:scale-105 transition-all cursor-pointer' 
                        onClick={handleSave}>
                        <ArrowLeft className='scale-75'/> 
                        back to themes</span>                
                    <input
                        type="text"
                        className='bg-transparent focus:bg-base-100 w-full text-3xl font-medium appearance-none border border-red-300 transition-all'
                        value={theme.display_name}
                        onChange={handleNameChange}
                        maxLength={32}
                        style={{fontFamily: theme.font}}
                        placeholder="Enter theme name"
                    />
                </div>
    );
}