import { invoices } from "./invoices";
export default function BelvoInvoices() {
  
  const list = invoices?.results;
  const revenueInvoices = list?.filter(invoice => invoice.type === 'INFLOW');
  const costInvoices = list?.filter(invoice => invoice.type === 'OUTFLOW');
  

  return (
    <div className='w-screen min-h-screen flex flex-col items-center justify-center overflow-y-scroll p-10'>
      
      <h1 className="text-2xl font-bold text-center">Analyzing Invoices</h1>
      <span>Count: {list?.length}</span>
      
      
      {/* Revenue Invoices */}
      <div className="flex flex-col mt-4">
        <h2 className="text-xl font-semibold mb-4">Revenue Invoices:</h2>
        <table className="table-auto border-collapse border border-gray-200">
          <thead>
            <tr>
              <th className="border px-4 py-2">Invoice ID</th>
              <th className="border px-4 py-2">Amount</th>
              <th className="border px-4 py-2">Date</th>
              <th className="border px-4 py-2">Description</th>
              <th className="border px-4 py-2">Place of Issue</th>
            </tr>
          </thead>
          <tbody>
            {revenueInvoices?.map((invoice, index) => (
              <tr key={index} className="border">
                <td className="border px-4 py-2">{invoice.id}</td>
                <td className="border px-4 py-2">{invoice.total_amount}</td>
                <td className="border px-4 py-2">{invoice.invoice_date}</td>
                <td className="border px-4 py-2">{invoice.invoice_details && invoice.invoice_details[0] && invoice.invoice_details[0].description}</td>
                <td className="border px-4 py-2">{invoice.place_of_issue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Cost Invoices */}
      <div className="flex flex-col mt-4">
        <h2 className="text-xl font-semibold mb-4">Cost Invoices:</h2>
        <table className="table-auto border-collapse border border-gray-200">
          <thead>
            <tr>
              <th className="border px-4 py-2">Invoice ID</th>
              <th className="border px-4 py-2">Amount</th>
              <th className="border px-4 py-2">Date</th>
              <th className="border px-4 py-2">Description</th>
              <th className="border px-4 py-2">Place of Issue</th>
            </tr>
          </thead>
          <tbody>
            {costInvoices?.map((invoice, index) => (
              <tr key={index} className="border">
                <td className="border px-4 py-2">{invoice.id}</td>
                <td className="border px-4 py-2">{invoice.total_amount}</td>
                <td className="border px-4 py-2">{invoice.invoice_date}</td>
                <td className="border px-4 py-2">{invoice.invoice_details && invoice.invoice_details[0] && invoice.invoice_details[0].description}</td>
                <td className="border px-4 py-2">{invoice.place_of_issue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>  

    </div>
  );
}