import React, { useContext, useEffect, useState } from 'react';
import Icon from '../../../../components/icons/Icon';
import { EditorContext } from '../../EditorContext';
import * as UIKit from '../../../../ui-kit/local/index'
import ImageUpload from './ImageUpload';
import SmartIcon from './sub-editors/SmartIcon';
import SelectColor from './sub-editors/SelectColor';
import { convertObjectsToJSX } from '../../../../utilities/AIhelpers';
import { v1 as uuidv1 } from "uuid";

import SelectWidth from './sub-editors/SelectWidth';
import SelectHeight from './sub-editors/SelectHeight';
import SelectPadding from './sub-editors/SelectPadding';
import SelectGap from './sub-editors/SelectGap';
import SelectDirection from './sub-editors/SelectDirection';
import SelectAlignItems from './sub-editors/SelectAlignItems';
import SelectJustify from './sub-editors/SelectJustify';

import SelectSize from './sub-editors/SelectSize';
import { CodeBrackets, CursorPointer, Code, LayoutLeft, ArrowRight, Check } from 'iconoir-react';
import LongString from './sub-editors/LongString';
import ShortString from './sub-editors/ShortString';
import CustomSize from './sub-editors/CustomSize';
import ExtendChanges from './ExtendChanges';
import SelectSpacing from './sub-editors/SelectSpacing';
import { generateNode } from '../inspect/code/helpers';
import { Badge, Button, Checkbox, InputText, MiniSnippet, Select, Status, ToggleSwitch } from '../../../../ui-kit/exports/react';
import PropsCode from './PropsCode';

function PropEditor() {

    
    const { selector, handleAction, view, setView, notifications, setNotifications, isAdmin, setSelector } = useContext(EditorContext);
    
    let isMobile = view.isMobile
    const emptyFrame = selector.frame?.objects.length == 0
    if (emptyFrame) {
        return <NoSections />
    } else if (!selector.object) {
        return (
            <div className="flex flex-col items-start justify-between h-full gap-2 p-2">
            <div className='bg-base-100 rounded-lg flex flex-col gap-1 border border-base-200 p-3 items-center justify-center w-full text-black font-medium text-center balance text-sm  '>
                <CursorPointer width={24} height={24} style={{strokeWidth: 1.5}}/>
                <h2>Make a selection</h2>
                <p className='text-xs font-normal w-2/3 leading-1'
                style={{lineHeight: '1rem'}}
                >Select an element on the canvas to make changes</p>
            </div>
            </div>
        )
    }
    
    const getDefaultProps = (definitions) => {
        
        return Object.keys(definitions?.propDefinitions).reduce((acc, key) => {
            acc[key] = definitions?.propDefinitions[key]?.default;
            
            return acc;
        }, {});
    };
    
    const getMobileProps = (definitions) => {
        return Object.keys(definitions?.propDefinitions).reduce((acc, key) => {
            const propDefinition = definitions?.propDefinitions[key];
            if (propDefinition && 'defaultOnMobile' in propDefinition) {
                acc[key] = propDefinition?.defaultOnMobile;
            }
            return acc;
        }, {});
    };
    

    const componentName = selector?.object?.componentAPIName
    
    const findComponentIgnoreCase = (componentName, moduleObj) => {
        const normalizedComponentName = componentName.toLowerCase();
        const componentNameKey = Object.keys(moduleObj)
          .find(key => key.toLowerCase() === normalizedComponentName);
      
        return moduleObj[componentNameKey];
      };
      const Component = findComponentIgnoreCase(componentName, UIKit) || null;  
      
      

    const definitions = Component?.definitions
    const propDefinitions = definitions?.propDefinitions
    const componentDescription = definitions?.description
    
    const defaultProps = getDefaultProps(definitions)
    const currentProps = selector?.object?.object_props;
    const defaultMobileProps = getMobileProps(definitions)
    const mobileProps = selector?.object?.mobile_props;
    
    const effectiveProps = isMobile
      ? { ...defaultProps, ...currentProps, ...defaultMobileProps, ...mobileProps }
      : { ...defaultProps, ...currentProps };

      const handleResetProps = () => {
        let currentObject = { ...selector.object };
    
        // Initialize newObject with empty object_props and mobile_props
        let newObject = { ...currentObject, object_props: {}, mobile_props: {} };
    
        // Iterate through propDefinitions to find fields where type is 'string'
        for (const key in propDefinitions) {
            
            if (propDefinitions[key].type === 'string' || propDefinitions[key].type === 'longString') {
                // Check if the key is present in currentObject.object_props
                // console.log(currentObject)
                if (key in currentObject.object_props) {
                    newObject.object_props[key] = currentObject.object_props[key];
                }
                // Check if the key is present in currentObject.mobile_props
                
                if (currentObject?.mobile_props && key in currentObject?.mobile_props) {
                    newObject.mobile_props[key] = currentObject.mobile_props[key];
                }
            }
        }
    
        handleAction({ type: 'UPDATE_OBJECT', currentObject, newObject });
    };

    function saveComponent() {
        const template = {
            apiname: selector.object.componentAPIName,
            jsx: convertObjectsToJSX(selector)
        }
        console.log('saving template for')
        handleAction({type: 'SAVE_TEMPLATE', template})
    }

    const handleInputChange = (propName, value) => {
        
        let currentObject = { ...selector.object };
        
        if (isMobile) {
            let mobile_props = { ...mobileProps };
    
            if (value === null) {
                // Remove the key if the value is null
                delete mobile_props[propName];
            } else {
                mobile_props[propName] = value;
            }
    
            const newObject = { ...currentObject, mobile_props };
    
            handleAction({
                type: 'UPDATE_OBJECT',
                currentObject,
                newObject
            });
    
        } else {
            let object_props = { ...currentObject.object_props };
            // console.log(propName, value)
            console.log('updating', propName, value)
            if (value === null || value === '' || value === undefined || value === NaN) {
                // Remove the key if the value is null
                delete object_props[propName];
            } else {
                object_props[propName] = value;
            }
    
            object_props = Object.fromEntries(
                Object.entries(object_props).filter(([key]) => defaultProps?.hasOwnProperty(key))
            );
    
            const newObject = { ...currentObject, object_props };
    
            handleAction({
                type: 'UPDATE_OBJECT',
                currentObject,
                newObject
            });
        }     
    };

    let elementsToRender = [];

    if (propDefinitions) {
        elementsToRender = Object.entries(propDefinitions)
            .map(([key, definition]) => {
                
                const propValue = effectiveProps[key]; // Use effectiveProps here
                
                let inputElement;
                switch (definition.type) {
                    
                    case 'bool':
                        inputElement = (
                            <ToggleSwitch
                                checked={propValue}
                                onChange={(e) => handleInputChange(key, e.target.checked)}
                                label={definition.displayName}
                                style={'rectangle'}
                            />
                        );
                        break;
                    case 'arrayOfObjects': 
                    case 'arrayOfStrings':
                        inputElement = (
                            
                            <div className='text-xs flex flex-row flex-wrap gap-1 items-center'>
                            JSON data. 
                            <span className='font-medium hover:underline' onClick={()=> setView({...view, rightSide: 'chat',})}>
                                Edit via chat
                            </span>
                            <ArrowRight width={12} height={12} style={{strokeWidth: 2}}/>
                            </div>
                        );
                        break;
                    case 'string':

                        inputElement = (
                            <>

                            <ShortString
                                label={definition?.displayName}
                                
                                propKey={key}
                                propValue={propValue}
                                onSave={handleInputChange}
                            />
                            </>

                        );
                        break;
                    case 'longString':
                            
                            inputElement = (
                                <LongString
                                propKey={key}
                                propValue={propValue}
                                label={definition.displayName || key}
                                onSave={handleInputChange}
                                />
    
                            );
                        break;
                    case 'imageURL':
                        inputElement = (
                            <ImageUpload onImageUpload={(uploadedImageUrl) => {console.log(uploadedImageUrl);
                                handleInputChange(key, uploadedImageUrl);}} 
                            altText={selector?.object?.object_props?.altText}
                            />
                        );
                        break;
                    case 'number':
                        inputElement = (
                            
                            <InputText 
                                key={selector.object.id}
                                size='small'
                                width='full'
                                label={definition.displayName || key}
                                value={propValue || null}
                                onChange={e => handleInputChange(key, parseInt(e.target.value))}
                                type='number'
                            />


                        );
                        break;
                    case 'oneOf':
                        inputElement = (
                            
                            <>
                            <Select
                                width="full"
                                size="small"
                                value={propValue}
                                label={definition.displayName || key}
                                onChange={val => handleInputChange(key, val)}
                                options={definition?.options?.map(value => ({
                                    value: value,
                                    label: value
                                }))}
                            />
                            </>
                        );
                        break;
                    case 'icon':
                        inputElement = (
                              <SmartIcon
                                propKey={key} 
                                icons={definition.options.sort()}
                                currentIcon={propValue}
                                label={definition.displayName || key}
                                onChange={(key, value) => handleInputChange(key, value)}
                              />
                          );
                          
                        break;
                    case 'colors':
                            inputElement = (
                                  <SelectColor 
                                    propKey={key} 
                                    label={definition.displayName}
                                    colorKeys={definition.options}
                                    currentColor={propValue}
                                    onChange={(key, value) => handleInputChange(key, value)}    
                                  />
                              );
                              
                        break;
                    case 'width':
                            inputElement = (
                                
                                  <SelectWidth
                                    propKey={key} 
                                    label={definition?.displayName}
                                    options={definition.options}
                                    value={propValue}
                                    onChange={(key, value) => handleInputChange(key, value)}    
                                  />
                              );
                              
                                  
                        break;
                        case 'customSize':
                            inputElement = (
                                
                                  <CustomSize
                                    propKey={key} 
                                    options={definition.options}
                                    currentOption={propValue}
                                    onSelect={(key, value) => handleInputChange(key, value)}    
                                  />
                              );
                              
                                  
                        break;
                        case 'padding':
                                inputElement = (
                                      <SelectPadding
                                        propKey={key} 
                                        paddingOptions={definition.options}
                                        currentPadding={propValue}
                                        onPaddingSelect={(key, value) => handleInputChange(key, value)}    
                                      />
                                  );
                                  break;
                        /*case 'corners':
                        inputElement = (
                            <SelectRadius
                            propKey={key} 
                            currentOption={propValue}
                            label={definition.displayName || key}
                            options={definition.options}
                            onChange={(key, value) => handleInputChange(key, value)}
                            />
                        );*/
                        break;
                        case 'size':
                    inputElement = (
                            <SelectSize
                            propKey={key} 
                            label={definition?.displayName}
                            options={definition.options}
                            value={propValue}
                            onChange={(key, value) => handleInputChange(key, value)}    
                            />
                        );
                        break;
                        case 'height':
                            inputElement = (
                                  <SelectHeight
                                    propKey={key} 
                                    widthOptions={definition.options}
                                    currentWidth={propValue}
                                    onWidthSelect={(key, value) => handleInputChange(key, value)}    
                                  />
                              );
                              
                        break;
                        case 'direction':
                            inputElement = (
                                  <SelectDirection
                                    propKey={key} 
                                    label={definition.displayName}
                                    directionOptions={definition.options}
                                    currentDirection={propValue}
                                    onDirectionSelect={(key, value) => handleInputChange(key, value)}    
                                  />
                              );
                              
                        break;
                        case 'alignItems':
                            inputElement = (
                                  <SelectAlignItems
                                    propKey={key} 
                                    direction={effectiveProps.flexDirection || effectiveProps.direction}
                                    options={definition.options}
                                    label={definition.displayName || key}
                                    value={propValue}
                                    onChange={(value) => handleInputChange(key, value)}    
                                  />
                              );
                              
                        break;
                        case 'justifyContent':
                            inputElement = (
                                <>
                                  <SelectJustify
                                    propKey={key} 
                                    direction={effectiveProps.flexDirection || effectiveProps.direction}
                                    options={definition.options}
                                    label={definition.displayName || key}
                                    value={propValue}
                                    onChange={(value) => handleInputChange(key, value)}    
                                  />
                                  </>
                              );
                              
                        break;
                        case 'corners':
                        case 'gap':
                        case 'spacing':
                            inputElement = (
                                  <SelectSpacing
                                    propKey={key} 
                                    currentOption={propValue}
                                    options={definition.options}
                                    label={definition.displayName || key}
                                    onChange={(key, value) => handleInputChange(key, value)}
                                  />
                              );
                              
                        break;
                        /*
                        case 'gap':
                            inputElement = (
                                  <SelectGap
                                  propKey={key} 
                                  currentOption={propValue}
                                  direction={effectiveProps.flexDirection || effectiveProps.direction}
                                  options={definition.options}
                                  label={definition.displayName || key}
                                  onChange={(key, value) => handleInputChange(key, value)}
                                  />
                              );
                              
                        break;*/
                    default:
                        inputElement = <span>Unsupported prop type</span>;
                        break;
                }

                return (
                    <li key={key} className={`flex flex-col items-start justify-start
                    flex-grow ${definition.tile == '1/2' ? 'w-1/3' : definition.tile == '1/3' ? '1/4' : 'w-full'}
                    `}>
                        {inputElement}
                    </li>
                );
            });
    }


    const canExtendChanges = ['IconBar', 'TableWidget', 'Sidebar', 'Header', 'Main', 'SidePanel', 'CollapsibleSidebar', 'AppShell']

    function copyCodeToClipboard() {
        const code = generateNode({object: selector.object});
        navigator.clipboard.writeText(code);
        const notification = {
            id: uuidv1(), 
            message: 'Code copied to clipboard',
            timeout: 2000
          };
        setNotifications([...notifications, notification]);
    }
    const status = definitions?.status
    return (
        <div className="flex flex-col items-start justify-start h-full overflow-y-scroll overflow-x-hidden">
            {/* Header */}
            <div className='flex flex-row justify-between items-end w-full gap-2 pt-3 pb-2 px-2.5 
                    bg-base-50 border-b border-base-200'>
                    <h1 className='text-md font-medium flex flex-row gap-2'>{componentName}

                    {status &&
                    <Badge
                        size='small'
                        color={status == 'stable' ? 'success' : 'warning'}
                        style={'filled'}
                        text={status}
                        alignSelf='start'
                    />}
                    </h1>
                    

                    {selector.object && 
                    <PropsCode
                        selector={selector}
                    
                    />}
            </div>

            
            {/* Prop Controls */}
            <div className='flex flex-col w-full py-2 px-2.5 flex-grow'>
            
                {!selector.object && <NoSelection />}
                
                {<ul className="w-full flex flex-wrap gap-2">
                    {elementsToRender}
                </ul>}
            </div>

            {/* Extras */}
            {isAdmin &&
            <div className='flex flex-col gap-1 w-full px-2.5 h-auto mt-10 pb-3'>
                <div className='flex flex-col gap-2 text-xs'>
                <button className='bg-base-100 py-0.5 px-2 self-start rounded active:bg-base-200'
                    onClick={()=> navigator.clipboard.writeText(selector?.object?.id)}
                    >{selector?.object?.id}</button>
                
                <div className='flex flex-row gap-2 items-center'>
                {elementsToRender.length > 0 && (
                        <Button 
                        size={'small'}
                        onClick={handleResetProps}
                        text={'Reset Props'}
                        color={'warning'}
                        style={'light'}
                        />
                        
                    )}
                    <Button 
                        size={'small'}
                        onClick={() => setSelector({...selector, object: null})}
                        text={'Unselect'}
                        color={'base-200'}
                        style={'light'}
                        />
                    
                    </div>
                {canExtendChanges.includes(selector.object?.componentAPIName) &&
                <ExtendChanges selector={selector} handleAction={handleAction} />}
            </div>
            
            </div>}
            
            
            

        </div>
    );
}

export default PropEditor;



function NoSelection() {
    return (
        <div className='rounded-xl bg-orange-100 text-orange-600 px-3 w-full py-3 text-sm flex flex-row items-start gap-2 font-medium  border border-orange-200 leading-5'>
                    <Icon name={'side-eye'} width={20} fill='#ea580c'/>
                    <div className='flex flex-col gap-2 items-start'>
                        'This page is empty
                    </div>
        </div>
    )
}


/*function handleToggleHideFromAI() {
        // Toggle the checked state
        const newCheckedState = !checked;
        setChecked(newCheckedState);
    
        // Dispatch the action with the new state
        const action = { 
            type: 'UPDATE_OBJECT', 
            currentObject: object,
            newObject: { ...object, hide_from_ai: newCheckedState }
        };
        handleAction(action);
    }*/



function NoSections() {
    const { setView, view } = useContext(EditorContext);
    return (
        <div className="flex flex-col items-start justify-between h-full gap-2 py-3 px-2.5">
    <div className='bg-base-100 rounded-lg flex flex-col gap-1 p-3 items-center justify-center w-full text-black font-medium text-center balance text-sm'>
      <LayoutLeft width={24} height={24} style={{strokeWidth: 1.5}}/>
      <h2>This page is empty</h2>
        <p className='px-2 py-1 rounded-md text-xs font-medium'>
        Pick <a className='underline cursor-pointer' onClick={() => setView({ ...view, rightSide: 'assets', assets: 'sections' })}>Layout</a> or <a className='underline cursor-pointer' onClick={() => setView({ ...view, rightSide: 'assets', assets: 'pages' })}>Page Template</a>
        </p>
              
    </div></div>
    )
    
  }
  
  
  