import { useContext, useState } from "react"
import { EditorContext } from "../EditorContext"
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy, sortableKeyboardCoordinates,  } from '@dnd-kit/sortable';

import MiniFrame from "./MiniFrame";
import AddNext from "./AddNext";
import Library from "./library/OpenLibrary";

export default function MiniNav() {
    const {selector, setSelector, handleAction, assets, effectiveVariables, 
      setView, view

    } = useContext(EditorContext)

    const isOpen = view.miniNav
    const setIsOpen = (bool) => setView({...view, miniNav: bool})
    
    const page = selector?.page || {}
    const frames = page?.frames || []
    const selectedFrame = selector?.frame
    const selectedFrameId = selector?.frame?.id
    
    
    function selectFrame (frame) {
        setSelector({...selector, frame, object: null})
    }
    const [draggingItem, setDraggingItem] = useState(null);

    const handleDragStart = (event) => {
        
        setDraggingItem(frames.find(frame => frame.id === event.active.id));
    };

    function handleDragEnd(event) {
        const { active, over } = event;
      
        if (active?.id !== over?.id) {
          // Logic to reorder frames
          handleReorderFrames(active.id, over.id);
           
        }
        
        setDraggingItem(null);
      }
      
      function handleReorderFrames(activeId, overId) {
        const movingFrame = frames.find(f => f.id == activeId)
        const overFrame = frames.find(f => f.id == overId)
    
        if (movingFrame && overFrame && movingFrame != overFrame) {
          const action = {
            type: 'RELOCATE_FRAME', 
            currentFrame: {...movingFrame}, 
            newFrame: {...movingFrame, index: overFrame.index}
          }
          handleAction(action)
        } 
      }
    
      const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 8,
          },
        })
      )
    const height = isOpen ? 60 : 0

    return (
      <div className={`w-full items-start flex flex-row relative justify-start flex-shrink-0 transition-all duration-75 relative ${isOpen ? 'mb-2' : ''}`}
      style={{height: height, maxHeight: height}}>
        
        {isOpen && <Library isDragging={draggingItem} />}
        {/* we need an extra wrapper so this min nav stays stable during drag */}
        {isOpen &&
        <DndContext
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCenter}
                sensors={sensors}
                dropAnimation={null}
                >
                <SortableContext
                    items={frames}
                    strategy={horizontalListSortingStrategy}
                >
              <div className={`flex flex-row max-w-full py-1 flex-shrink-0 flex-grow-0 px-3 gap-3 
              transition-all relative items-center justify-start box-border`}
              style={{ 
                height: height, maxHeight: height, overflowY: 'visible', overflowX: 'scroll',
                width: '100%', flexWrap: 'nowrap', maxWidth: '100%'}}
              >
                {frames
                .sort((a, b) => a.index - b.index)
                .map((frame) => 
                    <MiniFrame 
                    frame={frame} 
                    key={frame.id}
                    selector={selector}
                    isSelected={selectedFrameId == frame.id}
                    selectFrame={selectFrame}
                    isDragPreview={false}
                    draggingItem={draggingItem}
                    assets={assets}
                    variables={effectiveVariables}

                    />
                    
                )}
                
                <AddNext />
              
              </div>
              </SortableContext>
              {draggingItem && <DragOverlay>
                <MiniFrame 
                  frame={draggingItem} 

                  isDragPreview={true}
                   /> </DragOverlay>}
                </DndContext>
                }
                
                </div>
    )
  }

