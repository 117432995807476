import { useEffect, useState } from "react";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Button, InputText } from "../../ui-kit/exports/react";

export default function UserSettings({user, setUser}) {
    
    
    // Local state for the form fields
    const [email, setEmail] = useState(user?.email);
    const [firstname, setFirstName] = useState(user?.firstname);
    const [lastname, setLastName] = useState(user?.lastname);
    const [passwordMessage, setPasswordMessage] = useState('');

    const fetchUserData = async () => {
        try {
            const response = await fetch('/api/user/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}` // Assuming token is still valid
                },
                body: JSON.stringify({ id: user.id })
            });
            const data = await response.json();
            if (response.ok) {
                setUser({...data, token: user.token})
            } else {
                console.log('failed to refresh user')
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    console.log(user?.id)
    // useEffect to run once on component mount
    useEffect(() => {
        fetchUserData();
    }, []);

    async function resetPassword() {
        const auth = getAuth();
        try {
            await sendPasswordResetEmail(auth, email);
            setPasswordMessage("Email sent. Check your inbox.");
            setTimeout(() => setPasswordMessage(''), 5000);  // Clear message after 5 seconds
        } catch (error) {
            console.error(error);
            setPasswordMessage("Failed to send password reset email. Please try again.");
            setTimeout(() => setPasswordMessage(''), 5000);  // Clear message after 5 seconds
        }
    }  

    function updateUser() {
        // Construct the request body
        const body = {
            id: user.id, // assuming the uid is stored in the user context
            firstName: firstname,
            lastName: lastname
        };

        fetch('/api/user/update/names', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` // assuming you have a token in the user context
            },
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === 'User names updated successfully.') {
                setUser(prev => ({ ...prev, firstname, lastname }));
            } else {
                alert('Failed to update user: ' + data.message);
            }
        })
        .catch(error => {
            console.error('Error updating user:', error);
            alert('An error occurred while updating the user');
        });
    }

    const buttonStyles = `text-xs px-3 py-1 bg-red-100 hover:bg-red-500 text-red-600 hover:text-white rounded-md font-medium transition-colors ease-in-out duration-200 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-slate-100`

    return (
            <div className="flex flex-col h-full w-full items-start gap-3 text-sm">
            <h2 className="text-lg font-semibold"
            >Profile</h2>
            <div className="flex flex-col items-start gap-4 w-full">
            
            <div className="w-full flex flex-row gap-2 justify-between items-end font-normal">
                <InputText
                type={'text'}
                name={'firstname'}
                label="First Name"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                width={'full'}
                size={'medium'}
                />
                <InputText
                type={'text'}
                name={'lastname'}
                label="Last Name"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                width={'full'}
                size={'medium'}
                />
            </div>
            <InputText 
            type={'email'}
            name={'email'}
            label="Email"
            value={email}
            placeholder="Email"
            width={'full'}
            size={'medium'}
            state={'disabled'}
            />
            
            
            </div>

            
            {/* Spacer div */}
            <div className="flex-grow"></div>
            <div className="w-full flex flex-row justify-end">
            <Button
                size={'small'}
                color={'warning'}
                style={'light'}
                text='Reset Password'
                onClick={() => resetPassword()}
            />
            {passwordMessage}
            </div>
        </div>
    )
}




function InputElement({label, value, onChange=()=>{}, placeholder, isDisabled=false}) {
    
    return (
        <div className="flex flex-col gap-2 w-full">
            {label && <label className="text-xs capitalize opacity-70">{label}</label>}
            <input
                type="text"
                className={`w-full py-2 mb-1 px-3 font-medium focus:cursor-text rounded-md 
                bg-slate-100 focus:bg-white focus:ring-1 focus:ring-primary transition-colors ease-in-out duration-200 flex-shrink-0
                ${isDisabled ? 'text-gray-400 cursor-not-allowed' : 'cursor-default '}
                `}
                disabled={isDisabled}
                placeholder={placeholder}
                value={value || ''}
                onChange={onChange}
            />
        </div>
    )
}