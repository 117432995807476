import { useContext, useEffect, useState, useRef } from "react";
import { createNewFrame, createNewIds } from '../../../../utilities/helpers';
import { EditorContext } from "../../EditorContext";
import { Book, LayoutLeft, OpenBook, PagePlus, Plus, Xmark } from "iconoir-react";
import Library from "./Library";


export default function OpenLibrary({isDragging}) {
    const { selector, set, handleAction, view } = useContext(EditorContext)
    const [showLibrary, setShowLibrary] = useState(false)
    

    function insertNewFrame({objects=[]}) {
      
      const page = selector.page
      if (!page) return
      const frames = page?.frames
      const insertIndex = frames?.length > 0 
        ? Math.max(...frames.map(frame => frame.index)) + 1 // add as last to the page
        : 1;
    
      let newFrame = createNewFrame({ ...page, type: 'page' }, [], 'Untitled frame', { width: 800, height: 800 });
      newFrame = { ...newFrame, index: insertIndex };

      let newObjects = objects.map(obj => ({...obj, frame: newFrame.id}));
      newObjects = newObjects.map(obj => obj.parent == 'rootObject' ? {...obj, parent: newFrame.id} : obj); 
      
      const action = {type: 'INSERT_FRAME', frame: {...newFrame, objects: newObjects}}
      handleAction(action);
      setShowLibrary(false)
    }

    const myRef = useRef(null);
    
    // Close the library when clicking outside of it
    useEffect(() => {
      function handleClickOutside(event) {
        if (myRef.current && !myRef.current.contains(event.target)) {
          setShowLibrary(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [myRef]);
    
    return (
      <div className="pl-3 py-1 text-base-content"
      ref={myRef}
      >
      <div className={`flex flex-col items-center justify-center rounded-lg gap-0
      whitespace-nowrap flex-shrink-0 text-base-content group relative backdrop-blur-sm ring-1 ring-base-200 ring-inset
      cursor-default flex-shrink-0 flex-grow-0 font-normal select-none hover:ring-base-300
      ${showLibrary && 'bg-base-100'}
      ${!isDragging && 'transition-all duration-75 hover:bg-base-100'}`}
      onClick={() => setShowLibrary(!showLibrary)}
      style={{width: 88, height: 52}}
      >
        <Plus height={20} width={20} className={`-mt-1 stroke-[1.2px] transition-all duration-75`}/>
        <span className={`h-3 transition-all duration-75 text-xs whitespace-nowrap font-normal`}>
        Add page
        </span>
      </div>
      {showLibrary &&
      <div className={`absolute left-2 -bottom-2  bg-base-50 translate-y-full z-50 rounded-lg border-[0.5px] border-base-300 shadow-xl
        z-50
      flex flex-col pt-2 overflow-hidden`} style={{ width: 480,  height: 580, }}>
          
          
          <Xmark 
            width={20} 
            height={20} 
            className="absolute top-2 right-2 hover:scale-110 transition-all duration-75 opacity-40 hover:opacity-80 cursor-pointer
            z-50"
            
            onClick={() => setShowLibrary(false)}
            
            />
            <Library 
              onSelect={insertNewFrame} 
              set={set} 
              draggable={!view?.overview}
            
            />
            </div>}
            </div>
            )

}

