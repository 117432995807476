// BASIC IMPORTS
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

// User
import { useContext } from 'react';
import UserContext from '../../UserContext';

// COMPONENTS
import Projects from './Projects';
import Header from './Header';
import { TabGroup } from '../../ui-kit/exports/react';
import SettingsModal from '../settings/SettingsModal';
import AddProject from './AddProject';

export default function ProjectsPage({ handleLogout, data, setData }) {
  
  
  const [projects, setProjects] = useState([]);
  const [designSystems, setDesignSystems] = useState([]);
  
  const navigate = useNavigate();
  
  const { user } = useContext(UserContext); // Access user from UserContext
    
  const getUserToken = async () => {
    if (user) {
      return user.token;
    }
    return null; 
  };  

  const sets = [{
    name: 'empty', 
    folders: [
    {
        "name": "Folder",
        "flows": [
            {
                "name": "User Flow",
                "frames": [
                    {
                        "name": "Sample Page",
                        "notes": "",
                        "jsx": ""
                    }
                ]
            }]
            }]}]
  
    const [ dataLoaded, setDataLoaded ] = useState(false);
    // LOAD PROJECTS
    useEffect(() => {
      const fetchProjects = async () => {
        if (user && user.id) { // Make sure user and user.id is set before making API calls
          try {
            
            const response = await axios.get(`/users/${user.id}/projects`, {
              headers: {
                Authorization: `Bearer ${await getUserToken()}`,
              },
            });
            setProjects(response.data);
          } catch (error) {
            console.error('Error fetching projects:', error);
            
          }
        }
        setDataLoaded(true);
      };
      fetchProjects();
    }, [user]);
  
    

  function openProject(e,projectId) {
    e.stopPropagation();
    navigate(`/project/${projectId}`);
  }
  
  async function addProject(payload, task = null, open = true) {
    // Get token from user context
    const token = await getUserToken(); 
    
    try {
      // Call the new endpoint with the payload and the token
      const response = await axios.post(
        '/api/projects/add',
        payload,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }
      );
  
      if (response.status === 201) {
        
        const newProject = response.data
        navigate(`/project/${newProject.id}`);
        setData(task)
      } else {
        console.error('Error creating project');
      }
    } catch (error) {
      console.error('Error creating project:', error.response ? error.response.data : error.message);
    }
  }


  async function renameProject(newName, project) {
  
    try {
      const updatedProject = {
        ...project, 
        id: project.id,
        name: newName,
        is_archived: project.is_archived,
      };

      const response = await axios.put('/projects/update', updatedProject, {
        headers: {
          Authorization: `Bearer ${await getUserToken()}`,
        },
      });
  
      if (response.status === 200) {
        const updatedProjects = projects.map((p) =>
          p.id === project.id ? { ...p, name: newName} : p
        );
        setProjects(updatedProjects);

      } else {
        console.error('Error renaming project');
      }
    } catch (error) {
      console.error('Error renaming project:', error);
    }
  }
  
  async function archiveProject(e, projectId) {
    e.stopPropagation();
    
    const project = projects.find(p => p.id == projectId)
    
    try {  
      const updatedProject = {
        ...project,
        is_archived: true,
      };
  
      const response = await axios.put('/projects/update', updatedProject, {
        headers: {
          Authorization: `Bearer ${await getUserToken()}`,
        },
      });
  
      if (response.status === 200) {
        const updatedProjects = projects.map((p) =>
          p.id === project.id ? updatedProject : p
        );
        setProjects(updatedProjects);
      } else {
        console.error('Error archiving project');
      }
    } catch (error) {
      console.error('Error archiving project:', error);
    }
  }

  async function restoreProject(e, projectId) {
    e.stopPropagation();
    
    const project = projects.find(p => p.id == projectId)
    
    try {  
      const updatedProject = {
        ...project,
        is_archived: false,
      };
  
      const response = await axios.put('/projects/update', updatedProject, {
        headers: {
          Authorization: `Bearer ${await getUserToken()}`,
        },
      });
  
      if (response.status === 200) {
        const updatedProjects = projects.map((p) =>
          p.id === project.id ? updatedProject : p
        );
        setProjects(updatedProjects);
      } else {
        console.error('Error restoring project');
      }
    } catch (error) {
      console.error('Error restoring project:', error);
    }
  }
  
  

  async function updateFavoriteStatus(projectId, isFavorite) {
    try {
      const token = await getUserToken();
      const response = await fetch(`/users/${user.id}/projects/${projectId}/favorite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({ is_favorite: isFavorite }) // Send the desired favorite status
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Favorite status updated successfully');

      // Update the project list in the state
      const updatedProjects = projects.map((project) => {
        if (project.id === projectId) {
          return { ...project, is_favorite: isFavorite };
        }
        return project;
      });

      setProjects(updatedProjects);

    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  }


  const [view, setView] = useState({
      settings: null
    });

  const [selectedTab, setSelectedTab] = useState('All');
  const tabs = [
    {label: 'All', icon: 'folder', value: 'All'},
    {label: 'Favorites', icon: 'star', value: 'Favorites'},
    {label: 'Archive', icon: 'archive', value: 'Archive'}
  ]

  // console.log(projects.map(p => ({id: p.id, frames: p.frames})))

  let showProjects = selectedTab == 'Archive' ? projects.filter(p => p.is_archived).sort((a, b) => a.updated_at < b.updated_at ? 1 : -1)  
    : selectedTab == 'Favorites' ? projects.filter(p => p.is_favorite && !p.is_archived) :
    projects.filter(p => !p.is_archived);

  showProjects = showProjects.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))

  return (
    
    <div className='w-screen h-screen max-h-auto flex flex-col text-base-content'>
      {view.settings && 
                    <SettingsModal 
                      view={view} 
                      setView={setView} 
                    />}
      <div className='flex flex-col w-full flex-grow mx-auto'> 
      
      <Header 
        user={user}
        sets={sets} 
        firstProject={showProjects.length == 0}
        addProject={addProject} 
        view={view} 
        setView={setView} 
      />  
      
      
      <div className='flex flex-col w-full h-full flex-grow mx-auto
      max-w-[1240px] p-5 mx-auto gap-5 items-start justify-start'> 
        <div className='flex px-4 flex-col gap-2 w-full mx-auto  mt-5'>
          <div className='flex flex-row w-full gap-1 justify-between items-center'>
            <h1 className='font-semibold text-2xl'>Apps</h1>
            
            
            <AddProject sets={sets} addProject={addProject} userId={user.id} />
          </div>
          <TabGroup 
            tabs={tabs}
            size='medium'
            selectColor={'primary'}
            value={selectedTab}
            onChange={setSelectedTab}

          />
        </div>
        {<Projects
        dataLoaded={dataLoaded}
        projects={showProjects}
        openProject={openProject}
        renameProject={renameProject}
        archiveProject={archiveProject}
        designSystems={designSystems}
        addProject={addProject}
        restoreProject={restoreProject}
        selectedTab={selectedTab}
        sets={sets}
        updateFavoriteStatus={updateFavoriteStatus} 
        />}
        
      </div>
      </div>
  </div>
);
}

