import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Icon } from '../'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */

const allIconNames = Object.keys(iconMap) || []

type TagProps = {
    text?: string,
    type?: 'filled' | 'outline' | 'light',
    color?: 'base-200' | 'base-700' | 'primary' | 'accent' | 'info' | 'success' | 'warning' | 'error',
    size?: 'small' | 'medium',
    leftIcon?: 'none' | 'auto' | string,
    rightIcon?: 'none' | 'auto' | string,
    isPill?: boolean,
    onRightIconClick?: () => void,
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function Tag({
        text = 'Tag',
        type = 'filled',
        color = 'base-200',
        size = 'medium',
        leftIcon = 'none',
        rightIcon = 'close',
        isPill = true,
        onRightIconClick,
        attributes,
        listeners, 
        junoProps /* remove line */
      }: TagProps) {
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{} } = junoProps || {} /* remove line */
    
    // CONTAINER STYLES
    const styleMap = {
        'filled': `bg-${color} text-${color == 'base-200' ? 'base-content' : 'base-0'}` ,
        'outline': `text-${color} ring-1 ring-${color}` ,
        'light': `bg-${color}/20 text-${color?.startsWith('base') ? 'base-content' : color+'-content'}` ,
    }
    
    const sizeStyleMap = {
        small: `text-xs py-0.5 gap-0.5 ${isPill ? 'rounded-full px-2' : 'rounded px-1.5'}`,
        medium: `text-sm py-1 gap-1 ${isPill ? 'rounded-full px-3' : 'rounded-md px-2'}`,
    };

    const sizeStyles = sizeStyleMap[size]

    let wrapperClasses = `flex font-semibold items-center flex-shrink-0 justify-center ${sizeStyles} ${styleMap[type]}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */    
    
    const LeftIconComponent = leftIcon !== 'none' ? <Icon icon={leftIcon?.toLowerCase()} className={`flex-shrink-0 scale-90 -ml-${size == 'small' ? '1' : '1.5'}`}/> : null;
    const RightIconComponent = rightIcon !== 'none' ? <Icon 
        icon={rightIcon?.toLowerCase()} 
        className={`flex-shrink-0 scale-[0.8] active:scale-100 transition-all duration-150 -mr-${size == 'small' ? '1' : '1.5'}`}
        /* replace to 'onClick={onRightIconClick}' */
        /> : null;
        
    // -mr-1 -mr-1.5
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    return (
        <div 
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={wrapperClasses} 
        >   
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Tag</div> /* remove line */}
        {LeftIconComponent}
        <span style={truncateStyle} className='flex-grow'>
        {<EditableOnDoubleClick text={text} whiteSpace='nowrap' onBlur={onPropertyUpdate('text')}/> /* replace to '            {text}' */}
        </span>
        {RightIconComponent}
        </div>
    ); 
}


/* ignore rest */

Tag.definitions = {
    apiName: 'Tag',
    displayName: 'Tag',
    description: '',
    ai_instructions: '',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    status: 'in review',
    package: 'Starter',
    propDefinitions: {
        text: {
            type: 'string',
            displayName: 'Text',
            default: 'Tag'
        },
        type: {
            type: 'oneOf',
            options: ['filled', 'outline', 'light'],
            displayName: 'Type',
            default: 'filled', 
            tile: '1/2'
        },
        size: {
            type: 'size',
            options: ['small', 'medium'],
            default: 'medium', 
            displayName: 'Size',
            tile: '1/2'
        },
        color: {
            type: 'colors',
            options: ['base-200', 'base-700',  'primary', 'accent', 'info', 'success', 'warning', 'error'],
            displayName: 'Color',
            default: 'base-200'
        },
        
        leftIcon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Left Icon',
            default: 'none' 
        },
        rightIcon: {
            type: 'icon',
            options: ['none', 'close', 'check', 'check-circle'],
            displayName: 'Right Icon',
            default: 'Xmark', 
        },
        isPill: {
            type: 'bool',
            displayName: 'Rounded',
            default: false
        }   
    }
};

/*Tag.propTypes = {
    text: PropTypes.string,
    type: PropTypes.oneOf(['filled', 'outline', 'light']),
    color: PropTypes.oneOf(['base-200', 'base-700',  'primary', 'accent', 'info', 'success', 'warning', 'error']),
    size: PropTypes.oneOf(['small', 'medium']),
    leftIcon: PropTypes.oneOf(['none', ...allIconNames]),
    rightIcon: PropTypes.oneOf(['none', 'close', 'check', 'check-circle']), 
    onRightIconClick: PropTypes.func,
    isPill: PropTypes.bool,
};
*/