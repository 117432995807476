import { Icon } from '../'; /* replace to 'import { Icon } from './index'' */
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */
import { useMemo } from 'react';

const allIconNames = Object.keys(iconMap) || []

type FileCardProps = {
    description?: string,
    thumbnailAspectRatio?: '2 / 1' | '1 / 1' | '3 / 2' | '4 / 3',
    textSize?: 'small' | 'medium',
    thumbnailImageSrc?: string,
    title?: string,
    icon?: 'none' | 'page' | 'star',
    corners?: 'none' | 'sm' | 'md' | 'lg' | 'xl',
    width?: '100%' | '200px' | '320px',
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function FileCard({
        description = "File • 2Mb",
        thumbnailAspectRatio = '2 / 1',
        textSize = 'small',
        thumbnailImageSrc = null,
        title = "File Name",
        icon = 'page',
        corners = "none",
        width = '100%',
        attributes,
        listeners, 
        junoProps /* remove line */
    } : FileCardProps) {
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{}} = junoProps || {} /* remove line */

    const sizeStyles = `w-full h-auto ${textSize == 'small' ? 'text-sm' : 'text-base'}`
    let wrapperClasses = `flex flex-col items-stretch justify-start gap-3 ${sizeStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const contentClasses = `flex flex-col flex-grow`
    
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    const noImage = !thumbnailImageSrc;
    const imageStyles = useMemo(() => ({
        background: !noImage && `linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.12)), url(${thumbnailImageSrc}) no-repeat center center / cover`, 
        backgroundSize: 'cover', 
        aspectRatio: thumbnailAspectRatio
    }), [thumbnailImageSrc, noImage, thumbnailAspectRatio]);

    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const titleFont = textSize == 'small' ? 'text-base' : 'text-lg';
    const smallerFont = textSize == 'small' ? 'text-xs' : 'text-sm';

    const IconComponent = icon !== 'none' ? <Icon icon={icon?.toLowerCase()}  className='flex-shrink-0' /> : null;

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={wrapperClasses} style={{maxWidth: width}}
        >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>File Card</div> /* remove line */}
        
        {/* THUMBNAIL */}
        <div className={`relative group w-full aspect-square flex items-center justify-center bg-current-10 ${cornerStyles}`} style={imageStyles}>
            {noImage && <Icon icon={'page'}  className='flex-shrink-0' />}
        </div>
        
        {/* CONTENT BLOCK */}
        <div className={contentClasses}>
            
            {/* Title */}
            <div className={`flex flex-row justify-between gap-2 items-start ${titleFont} group `}>
                {IconComponent}
                <div className='flex-grow flex flex-col gap-1' style={truncateStyle}>
                    <h3 className={`font-semibold`}>
                        {<EditableOnDoubleClick text={title} onBlur={onPropertyUpdate('title')} whiteSpace="nowrap"/> /* replace to '{title}' */}
                    </h3>
                    {description && <span className={`${smallerFont} opacity-70`} style={truncateStyle}>
                        {<EditableOnDoubleClick text={description} onBlur={onPropertyUpdate('description')} whiteSpace="nowrap"/> /* replace to '{description}' */}
                    </span>}
                </div>
                <Icon icon='star' className='flex-shrink-0 transition-all cursor-pointer opacity-0 group-hover:opacity-100 scale-75' />
            </div>
        </div>
        </div>
    );
}

/* ignore rest */


FileCard.definitions = {
    apiName: 'FileCard',
    displayName: 'File Card',
    description: 'Card representing a file in a file management enviroment.',
    ai_instructions: `A card that represents a file in a file management environment. It typically shows file name and a description. It can an icon in the top right corner that can be used for actions like adding to favorites or adding to cart.`,
    type: 'cards',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    status: 'prototype',
    package: 'Pro',
    propDefinitions: {
        title: {
            type: 'string',
            default: "File Name", 
            displayName: 'Title',
        }, 
        description: {
            type: 'string',
            default: "File • 2Mb",
            displayName: 'Description',
            ai_instructions: 'description of the file, typically used to show file type, size or tiemstamp'
        },
        width: {
            type: 'oneOf',
            options: ['100%', '200px', '320px'],
            default: '100%', 
            displayName: 'Width',
            tile: '1/2'
        },
        textSize: {
            type: 'size',
            options: ['small', 'medium'],
            displayName: 'Text Size',
            ai_instructions: 'small sets title, price to text-sm and description to text-xs, medium sets title and price to text-base and description to text-sm',
            default: 'small', 
            tile: '1/2'
        },
        icon: {
            type: 'oneOf',
            options: ['none', ...allIconNames],
            displayName: 'Icon',
            ai_instructions: 'icon that appears left of title, not clickable',
            default: 'page', 
        },
        
        corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg", 'xl'],
            displayName: "Corners",
            default: "none", 
            tile: '1/2'
        },
        thumbnailAspectRatio: {
            type: 'oneOf',
            displayName: 'Thumbnail Aspect',
            options: ['2 / 1', '1 / 1', '3 / 2', '4 / 3'],
            default: '2 / 1', 
            tile: '1/2'
        },
        thumbnailImageSrc: {
            type: 'imageURL',
            displayName: 'Thumbnail Image',
            ai_instructions: 'mini preview of the file as thumbnail, leave null unless user is asking for a preview. if null, a generic file icon will be shown',
            default: null
        },
    }
};

/*
FileCard.propTypes = {
    thumbnailImageSrc: PropTypes.string,
    thumbnailAspectRatio: PropTypes.oneOf(['2 / 1', '1 / 1', '3 / 2', '4 / 3']),
    textSize: PropTypes.oneOf(['small', 'medium']),
    title: PropTypes.string,
    icon: PropTypes.oneOf(['none', ...allIconNames]),
    description: PropTypes.string,
    corners: PropTypes.oneOf(["none", "sm", "md", "lg", 'xl']),
    width: PropTypes.oneOf(['100%', '200px', '320px']),
};
*/