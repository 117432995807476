import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, AreaChart, Area } from 'recharts';
import { Icon } from '../'; /* replace to 'import { Icon } from './index'' */
// Run 'npm install recharts' to install the recharts library


const dummyDataData = {
    keyPairs: ['week', 'sales'],
    valuePairs: [
        ['Aug 12', 150],
        ['Aug 19', 190],
        ['Aug 26', 180],
        ['Sep 2', 210],
        ['Sep 9', 205],
        ['Sep 16', 220],
        ['Sep 23', 240]
    ]
};

type AreaChartProps = {
    title?: string,
    width?: 'auto' | 'full' | '1/2',
    height?: '92px' | '120px' | '160px' | '200px' | '240px' | '360px',
    lineColor?: 'primary' | 'accent' | 'base-content' | 'base-0',
    lineWidth?: '1' | '2' | '3' | '4',
    lineType?: 'wavy' | 'linear',
    showGrid?: boolean,
    showDots?: boolean,
    showLabels?: boolean,
    showYAxis?: boolean,
    showXAxis?: boolean,
    backgroundColor?: 'base-0' | 'base-100' | 'base-200' | string,
    corners?: 'none' | 'sm' | 'md' | 'lg',
    emptyState?: boolean,
    emptyMessage?: string,
    data?: {
        keyPairs: string[],
        valuePairs: any[][]
    },
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function AreaChartComponent({
        backgroundColor = null, 
        width = 'full',
        height = '120px',
        lineColor = 'primary',
        lineWidth = '2',
        lineType = 'wavy',
        showDots = true,
        showLabels = true,
        showYAxis = true,
        showXAxis = true,
        showGrid = true,
        data = dummyDataData,
        title = 'Sales',
        emptyState = false,
        emptyMessage = 'Data may take up to 24 hrs to show',
        attributes,
        listeners, 
        junoProps /* remove line */
      }: AreaChartProps) {
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{}, preview} = junoProps || {} /* remove line */

    const widthStyles = `w-${width}`;
    // const paddingStyles = padding === 'none' ? 'p-0' : `p-${padding}`;
    // const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;

    const bgStyles = backgroundColor && backgroundColor != 'none' ? `bg-${backgroundColor}` : ''
    const fontColorStyles = backgroundColor && backgroundColor != 'none' ? `text-base-content` : 'text-inherit'

    let wrapperClasses = `flex flex-col items-stretch relative ${widthStyles} ${bgStyles} ${fontColorStyles}`
    wrapperClasses += ` ${outlineStyle}`/* remove line */

    // ${paddingStyles} ${cornerStyles}
    
    const emptyStyles = `flex flex-col justify-center items-center px-sm text-sm font-medium gap-2 rounded-md`
    
    function transformData(keyPairs, valuePairs) {
        return valuePairs.map(values => {
            return values.reduce((obj, val, index) => {
                obj[keyPairs[index]] = val;
                return obj;
            }, {});
        });
    }
    
    const sampleData = data ? transformData(data?.keyPairs, data?.valuePairs) : []
    const totalValue = sampleData.reduce((acc, curr) => acc + curr[data?.keyPairs[1]], 0)
    
    const sideMargins = (!showYAxis && !showLabels && !showDots && !showXAxis) ? 0 : 20

    return (
        <div
            {...attributes} {...listeners} 
            {...junoAttributes} /* remove line */
            className={wrapperClasses}
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Area Chart</div> /* remove line */}
            
            {/* Title Block & Menu */}
            <div className="flex flex-row items-start justify-between mb-md">
                <div className="flex flex-col text-sm font-medium gap-2 items-start relative justify-between">
                    <EditableOnDoubleClick text={title} onBlur={onPropertyUpdate('title')} preview={preview} /* replace to '{title}' */ />
                    <span className='text-xl font-semibold'>{totalValue}</span>
                </div>
                {/* implement Kebab here
                <KebabMenu>
                     
                </KebabMenu> 
                */}
            </div>
            
            {/* CHART */}
            {!emptyState ?
            <div className='flex flex-row' style={{ 
                    width: '100%', 
                    pointerEvents: preview ? 'auto' : 'none', /* remove line */
                    height: height,  }}>
                <ResponsiveContainer width={'100%'} height="100%" >
                    <AreaChart data={sampleData} margin={{ top: 20, right: sideMargins, bottom: 0, left: sideMargins }}>
                    {showGrid && <CartesianGrid strokeDasharray="1 3" />}
                    {showXAxis && <XAxis dataKey={data?.keyPairs[0]} tick={{ fontSize: '12px'}}/>}
                    {showYAxis && <YAxis width={20} tick={{ fontSize: '12px'}}/>}
                    <Tooltip />
                    <Area 
                        type={lineType === 'wavy' ? 'monotone' : 'linear'}
                        dataKey={data?.keyPairs[1]}
                        stroke={`var(--${lineColor})`} 
                        fill={`var(--${lineColor})`} 
                        strokeWidth={lineWidth}
                        dot={showDots} 
                        activeDot={{ r: 4 }}
                    >
                        {showLabels && (
                            <LabelList 
                                dataKey={data?.keyPairs[1]} 
                                position="top" 
                                style={{ fill: `var(--${lineColor})`, fontSize: 10 }} 
                            />
                        )}
                    </Area>
                </AreaChart>
            </ResponsiveContainer>
            </div>
        :
        <>
        <div className={emptyStyles} style={{ height: height, backgroundColor: `color-mix(in srgb, currentColor 8%, transparent)`}}>
            <Icon icon='chart-up' />
            <span className='font-normal text-sm'>
                <EditableOnDoubleClick text={emptyMessage} preview={preview} onBlur={onPropertyUpdate('emptyMessage')} /* replace to '                {emptyMessage}' */ />
            </span>
        </div>
        </>
        }
        </div>
    );
}


/* ignore rest */

AreaChartComponent.definitions = {
    apiName: 'AreaChart',
    displayName: 'Area Chart',
    description: 'Visualizes data as area chart, fairly customizable.',
    ai_instructions: 'Use to display changes or trends in data across different categories or times.',
    type: 'charts',
    relativeSize: 'medium to large',
    acceptedChildren: 'none',
    status: 'stable',
    package: 'Pro',
    propDefinitions: {
        title: {
            type: 'string',
            displayName: 'Title',
            default: 'Sales', 
        },
        data: {
            type: 'arrayOfObjects',
            displayName: 'Data',
            ai_instructions: 'Use keyPairs to define the keys for the x and y axis, and valuePairs to define x-axis values and data points. Limit to 30 data points to ensure performance.',
            default: dummyDataData
        },
        
        width: {
            type: 'width',
            options: ['auto', 'full', '1/2'],
            displayName: 'Width',
            default: 'full', 
            tile: '1/2'
        },
        height: {
            type: 'oneOf',
            options: ['92px', '120px', '160px', '200px', '240px', '360px'],
            displayName: 'Height',
            default: '120px',
            ai_instructions: 'small is 92px, medium 148px, large 240px',
            tile: '1/2'
        },
        lineColor: {
            type: 'colors',
            options: ['primary', 'accent', 'base-content', 'base-0'],
            displayName: 'Line Color',
            default: 'primary', 
            tile: '1/2'
        },

        backgroundColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200'],
            displayName: 'Background',
            default: null,
            tile: '1/2'
        },
        lineWidth: {
            type: 'oneOf',
            options: ['1', '2', '3', '4'],
            displayName: 'Line Width',
            tile: '1/2',
            default: '2'
        },

        lineType: {
            type: 'oneOf',
            options: ['wavy', 'linear'],
            displayName: 'Line Type',
            default: 'wavy', 
            tile: '1/2'
        },
        showGrid: {
            type: 'bool',
            displayName: 'Show Grid',
            default: true
        },
        showDots: {
            type: 'bool',
            displayName: 'Show Dots',
            default: true
        },

        showLabels: {
            type: 'bool',
            displayName: 'Show Labels',
            default: true
        },
        showYAxis: {
            type: 'bool',
            displayName: 'Show Y Axis',
            default: true
        },
        showXAxis: {
            type: 'bool',
            displayName: 'Show X Axis',
            default: true
        },
        emptyState: {
            type: 'bool',
            displayName: 'Empty State',
            default: false, 
            ai_instructions: 'will display empty message instead of bars',
        },
        emptyMessage: {
            type: 'longString',
            displayName: 'Empty Message',
            default: 'Data may take up to 24 hrs to show', 
        },
        
    }
};
/*AreaChartComponent.propTypes = {
    title: PropTypes.string,
    width: PropTypes.oneOf(['auto', 'full', '1/2']),
    height: PropTypes.oneOf(['92px', '120px', '240px', '360px']),
    lineColor: PropTypes.oneOf(['primary', 'accent', 'base-content', 'base-0']),
    lineWidth: PropTypes.oneOf(['1', '2', '3', '4']),
    lineType: PropTypes.oneOf(['wavy', 'linear']),
    showGrid: PropTypes.bool,
    showDots: PropTypes.bool,
    showLabels: PropTypes.bool,
    showYAxis: PropTypes.bool,
    showXAxis: PropTypes.bool,
    backgroundColor: PropTypes.oneOf(['base-0', 'base-100', 'base-200']),
    corners: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
    emptyState: PropTypes.bool,
    emptyMessage: PropTypes.string,
    data: PropTypes.shape({
        keyPairs: PropTypes.arrayOf(PropTypes.string),
        valuePairs: PropTypes.arrayOf(PropTypes.array)
    }),
};  */