import React, { useEffect, useState } from 'react';
import { RadioButton } from '../'; /* replace to 'import { RadioButton } from './index'' */

const sampleOptions = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
];

type RadioButtonsProps = {
    direction?: 'flex-row' | 'flex-col',
    width?: 'auto' | '1/2' | 'full',
    style?: 'standard' | 'button',
    state?: 'default' | 'disabled' | 'error' | 'success',
    size?: 'small' | 'medium' | 'large',
    options?: { label: string, value: string }[],
    onSelect?: any,
    selectedOption?: string,
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function RadioButtons({ 
        direction='flex-col',
        options: externalOptions,
        state='default',
        size='medium', 
        style='standard',
        width='auto',
        onSelect, 
        selectedOption: externalSelectedOption,
        attributes, 
        listeners, 
        junoProps /* remove line */
    }: RadioButtonsProps) {
    
    const { junoAttributes, showTag, self, outlineStyle, onPropertyUpdate=() => {}, preview } = junoProps || {} /* remove line */
    
    const [internalOptions, setInternalOptions] = useState(externalOptions || sampleOptions);
    const [internalSelectedOption, setInternalSelectedOption] = useState(externalSelectedOption || null);
    const isControlled = externalOptions !== undefined && onSelect !== undefined;
    const options = isControlled ? externalOptions : internalOptions;
    const selectedOption = isControlled ? externalSelectedOption : internalSelectedOption;

    // Effect to sync internal state with external `tabs` prop
    useEffect(() => {
        if (!isControlled) {
            setInternalOptions(externalOptions || sampleOptions);
        }
    }, [externalOptions, isControlled]);

    let wrapperClasses = `flex ${direction} items-stretch gap-2 w-${width} cursor-default`;
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    
    function handleOptionClick(e, value) {
        e.stopPropagation();
        
        if (isControlled && onSelect) {
            onSelect(value);
        } else {
            setInternalSelectedOption(value);
        }
    }


    const handleLabelUpdate = (index) => (e) => { /* remove line */
        let newOptions = [...options]; /* remove line */
        newOptions[index].label = e.target.innerText; /* remove line */
        const fakeEvent = { target: { innerText: newOptions}}; /* remove line */
        onPropertyUpdate('options')(fakeEvent) /* remove line */ }
    
    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={wrapperClasses} 
        >
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Radio Buttons</div> /* remove line */}
            
            {options.map((option, index) => (
            <RadioButton 
                key={index} 
                size={size}
                style={style}
                width={'full'}
                state={state}
                label={option.label}
                isSelected={selectedOption === option.value}
                onChange={(e) => handleOptionClick(e, option.value)}
                junoProps={{onPropertyUpdate: () => handleLabelUpdate(index) }} /* remove line */
            />
            ))}   
            
        </div>
    );
}

/* ignore rest */

RadioButtons.definitions = {
    apiName: 'RadioButtons',
    displayName: 'Radio Buttons',
    description: 'Radio button group with embedded javascript to manage selected state',
    ai_instructions: 'Radio button group with embedded javascript to manage selected state',
    type: 'inputs',
    acceptedChildren: 'none',
    package: 'Starter',
    relativeSize: 'small',
    propDefinitions: {
        direction: {
            type: 'direction',
            options: ['flex-row', 'flex-col'],
            displayName: 'Direction',
            default: 'flex-col', 
            tile: '1/2'
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2',
        },
        state: {
            type: 'oneOf',
            options: ['default', 'disabled', 'error', 'success'],
            displayName: 'State',
            default: 'default', 
            tile: '1/2'
        },
        style: {
            type: 'oneOf',
            options: ['standard', 'button'],
            default: 'standard',
            tile: '1/2'
        },
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            displayName: 'Size',
            default: 'medium', 
        },
        options: {
            type: 'arrayOfObjects',
            displayName: 'Options',
            default: sampleOptions
        }   
    }
};
/*RadioButtons.propTypes = {
    direction: PropTypes.oneOf(['flex-row', 'flex-col']),
    width: PropTypes.oneOf(["auto", "1/2", "full"]),
    style: PropTypes.oneOf(['standard', 'button']),
    state: PropTypes.oneOf(['default', 'disabled', 'error', 'success']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })),
};*/