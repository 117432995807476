export const invoices = {
    "count": 128,
    "next": "https://sandbox.belvo.com/api/invoices/?link=b335faf2-c84c-4387-9f8e-be6f412f22bc&page=2",
    "previous": null,
    "results": [
      {
        "id": "02d63485-a4ab-47f4-aa4d-d4d940cb8623",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225825Z",
        "type": "INFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 10.3,
          "date_to": "2024-09-08",
          "version": "1.2",
          "date_from": "2024-07-04",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-07-03",
          "other_payments": [],
          "tax_deductions": [],
          "earnings_breakdown": []
        },
        "version": "3.3",
        "currency": null,
        "payments": [],
        "warnings": null,
        "sender_id": "LUFV8005219FJ",
        "tax_amount": 12819.27,
        "receiver_id": "HWI170223EAV",
        "sender_name": "Emisor 18F3",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-06-28",
        "invoice_type": "Ingreso",
        "payment_type": "28",
        "total_amount": 73683.7,
        "exchange_rate": 1,
        "receiver_name": "Receptor 178C",
        "payment_method": "PPD",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 19256,
        "invoice_details": [
          {
            "quantity": 9,
            "unit_code": "E48",
            "tax_amount": 12819.27,
            "description": "Servicios de mensajería.",
            "unit_amount": 8902.27,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 92939.7,
            "payment_method": null,
            "pre_tax_amount": 80120.43,
            "retained_taxes": [],
            "tax_percentage": 16,
            "unit_description": "Producto 86F2",
            "invoice_identification": null,
            "product_identification": "655817912",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 80120.43,
        "cancelation_status": null,
        "certification_date": "2024-07-08T23:13:11",
        "invoice_identification": "cf1a6d65-6af3-e2bb-2164-dd327819552",
        "cancelation_update_date": null,
        "certification_authority": "fe9998fac66d7",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      },
      {
        "id": "0d5cfe3e-735f-4afd-b55b-01a7e3aaf9d3",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225808Z",
        "type": "OUTFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 4155.17,
          "date_to": "2024-07-06",
          "version": "1.2",
          "date_from": "2024-06-21",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-07-21",
          "other_payments": [
            {
              "type": "EMPLOYMENT_SUBSIDY",
              "amount": 9742.35
            },
            {
              "type": "TRAVEL_ALLOWANCES",
              "amount": 2843.45
            }
          ],
          "tax_deductions": [
            {
              "type": "OTHERS",
              "amount": 8890.11
            }
          ],
          "earnings_breakdown": [
            {
              "type": "EXTRA_HOURS",
              "taxable_amount": 8580.72,
              "vat_free_amount": 1883.7
            },
            {
              "type": "BONUS",
              "taxable_amount": 4493.37,
              "vat_free_amount": 8929.07
            },
            {
              "type": "EXTRA_HOURS",
              "taxable_amount": 6953.47,
              "vat_free_amount": 6359.7
            },
            {
              "type": "BONUS",
              "taxable_amount": 8899.25,
              "vat_free_amount": 5863.45
            }
          ]
        },
        "version": "3.3",
        "currency": null,
        "payments": [],
        "warnings": null,
        "sender_id": "QAYH721015XX4",
        "tax_amount": 573.13,
        "receiver_id": "QUW3207065ME",
        "sender_name": "Emisor 6C9D",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-07-21",
        "invoice_type": "Nómina",
        "payment_type": "13",
        "total_amount": 4465.3,
        "exchange_rate": 1,
        "receiver_name": "Receptor BCC9",
        "payment_method": "PUE",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 263,
        "invoice_details": [
          {
            "quantity": 1,
            "unit_code": "ACT",
            "tax_amount": 573.13,
            "description": "Pago de nómina",
            "unit_amount": 3582.04,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 4155.17,
            "payment_method": null,
            "pre_tax_amount": 3582.04,
            "retained_taxes": [],
            "tax_percentage": 16,
            "unit_description": "Producto 6EA9",
            "invoice_identification": null,
            "product_identification": "84111505",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 4155.17,
        "cancelation_status": null,
        "certification_date": "2024-07-26T10:27:18",
        "invoice_identification": "c1ac454f-ad63-32ff-f128-98eb698acdc",
        "cancelation_update_date": null,
        "certification_authority": "aab9ca2bcb6d9",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      },
      {
        "id": "69755e27-8c37-4787-ae32-98e042e6b40d",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225791Z",
        "type": "OUTFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 10.3,
          "date_to": "2024-09-08",
          "version": "1.2",
          "date_from": "2024-07-04",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-07-03",
          "other_payments": [],
          "tax_deductions": [],
          "earnings_breakdown": []
        },
        "version": "3.3",
        "currency": null,
        "payments": [
          {
            "date": "2024-08-15T09:27:04",
            "amount": 4194.94,
            "currency": null,
            "payer_rfc": "A8C7A3C7BE",
            "collected_at": "2024-09-13T19:45:52.531788Z",
            "payment_type": "08",
            "exchange_rate": null,
            "beneficiary_rfc": "326F2475E",
            "payer_bank_name": "BANK",
            "operation_number": "92768476",
            "related_documents": [
              {
                "currency": null,
                "amount_paid": 4194.94,
                "installment": "4",
                "collected_at": "2024-09-13T19:45:52.340875Z",
                "exchange_rate": null,
                "payment_method": "PPD",
                "previous_balance": 5663.87,
                "outstanding_balance": 1468.93,
                "invoice_identification": "57f11668-2265-5362-fa35-de4876e813d"
              }
            ],
            "payer_account_number": "762218937117",
            "beneficiary_account_number": "186871872674"
          }
        ],
        "warnings": null,
        "sender_id": "TUWT090329AVW",
        "tax_amount": 0,
        "receiver_id": "KYX440613HSU",
        "sender_name": "Emisor 5E2C",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-03-16",
        "invoice_type": "Pago",
        "payment_type": "08",
        "total_amount": 0,
        "exchange_rate": 1,
        "receiver_name": "Receptor EEB8",
        "payment_method": "PPD",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 0,
        "invoice_details": [
          {
            "quantity": 1,
            "unit_code": "ACT",
            "tax_amount": 0,
            "description": "Pago",
            "unit_amount": 0,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 0,
            "payment_method": null,
            "pre_tax_amount": 0,
            "retained_taxes": [],
            "tax_percentage": 0,
            "unit_description": "Producto B54B",
            "invoice_identification": null,
            "product_identification": "84111506",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 0,
        "cancelation_status": null,
        "certification_date": "2024-09-03T20:21:22",
        "invoice_identification": "4b19f31d-558b-b491-e823-ed65dffec73",
        "cancelation_update_date": null,
        "certification_authority": "964caa6d3dd92",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      },
      {
        "id": "c3ee319d-3405-467c-b7d0-fa93b35e1b78",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225773Z",
        "type": "INFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 10.3,
          "date_to": "2024-09-08",
          "version": "1.2",
          "date_from": "2024-07-04",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-07-03",
          "other_payments": [],
          "tax_deductions": [],
          "earnings_breakdown": []
        },
        "version": "3.3",
        "currency": null,
        "payments": [],
        "warnings": null,
        "sender_id": "OUOV720427FY3",
        "tax_amount": 8522.17,
        "receiver_id": "AFX8508152FI",
        "sender_name": "Emisor 4F29",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-03-04",
        "invoice_type": "Ingreso",
        "payment_type": "02",
        "total_amount": 50450.73,
        "exchange_rate": 1,
        "receiver_name": "Receptor 62A5",
        "payment_method": "PUE",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 11335,
        "invoice_details": [
          {
            "quantity": 6,
            "unit_code": "E48",
            "tax_amount": 8522.17,
            "description": "Servicios de mensajería.",
            "unit_amount": 8877.26,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 61785.73,
            "payment_method": null,
            "pre_tax_amount": 53263.56,
            "retained_taxes": [],
            "tax_percentage": 16,
            "unit_description": "Producto EED6",
            "invoice_identification": null,
            "product_identification": "275817419",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 53263.56,
        "cancelation_status": null,
        "certification_date": "2024-08-16T09:45:44",
        "invoice_identification": "7d9bf47a-849d-7443-f3f6-fb9391341f6",
        "cancelation_update_date": null,
        "certification_authority": "4591ea192ff47",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      },
      {
        "id": "05a3934e-7f61-483c-953a-e7f6ef7fa60d",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225753Z",
        "type": "OUTFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 10.3,
          "date_to": "2024-09-08",
          "version": "1.2",
          "date_from": "2024-07-04",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-07-03",
          "other_payments": [],
          "tax_deductions": [],
          "earnings_breakdown": []
        },
        "version": "3.3",
        "currency": null,
        "payments": [],
        "warnings": null,
        "sender_id": "QAMI320714ZXA",
        "tax_amount": 7469.21,
        "receiver_id": "WJY3607115BV",
        "sender_name": "Emisor 764E",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-09-10",
        "invoice_type": "Traslado",
        "payment_type": "02",
        "total_amount": 54010.83,
        "exchange_rate": 1,
        "receiver_name": "Receptor 1AE2",
        "payment_method": "PPD",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 141,
        "invoice_details": [
          {
            "quantity": 1,
            "unit_code": "E48",
            "tax_amount": 1291.36,
            "description": "Servicios de mensajería.",
            "unit_amount": 8071.03,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 9362.39,
            "payment_method": null,
            "pre_tax_amount": 8071.03,
            "retained_taxes": [],
            "tax_percentage": 16,
            "unit_description": "Producto CBB2",
            "invoice_identification": null,
            "product_identification": "125329522",
            "payment_type_description": null,
            "payment_method_description": null
          },
          {
            "quantity": 4,
            "unit_code": "E48",
            "tax_amount": 1386.87,
            "description": "Servicios de mensajería.",
            "unit_amount": 2166.99,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 10054.83,
            "payment_method": null,
            "pre_tax_amount": 8667.96,
            "retained_taxes": [],
            "tax_percentage": 16,
            "unit_description": "Producto 748A",
            "invoice_identification": null,
            "product_identification": "367445254",
            "payment_type_description": null,
            "payment_method_description": null
          },
          {
            "quantity": 9,
            "unit_code": "E48",
            "tax_amount": 4790.98,
            "description": "Servicios de mensajería.",
            "unit_amount": 3327.07,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 34734.61,
            "payment_method": null,
            "pre_tax_amount": 29943.63,
            "retained_taxes": [],
            "tax_percentage": 16,
            "unit_description": "Producto 983B",
            "invoice_identification": null,
            "product_identification": "695987496",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 46682.62,
        "cancelation_status": null,
        "certification_date": "2024-07-04T08:50:28",
        "invoice_identification": "1eef1f15-7565-dab3-c3af-8be432b111f",
        "cancelation_update_date": null,
        "certification_authority": "a7cacc2b2d8b3",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      },
      {
        "id": "2439a5d5-f9d8-4f5e-84f4-4e01d281221e",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225736Z",
        "type": "OUTFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 10.3,
          "date_to": "2024-09-08",
          "version": "1.2",
          "date_from": "2024-07-04",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-07-03",
          "other_payments": [],
          "tax_deductions": [],
          "earnings_breakdown": []
        },
        "version": "3.3",
        "currency": null,
        "payments": [
          {
            "date": "2024-06-17T07:27:28",
            "amount": 2443.32,
            "currency": null,
            "payer_rfc": "C25ABF8FA1",
            "collected_at": "2024-09-13T19:45:52.531788Z",
            "payment_type": "13",
            "exchange_rate": null,
            "beneficiary_rfc": "D81542EED",
            "payer_bank_name": "BANK",
            "operation_number": "16947818",
            "related_documents": [
              {
                "currency": null,
                "amount_paid": 2443.32,
                "installment": "8",
                "collected_at": "2024-09-13T19:45:52.340875Z",
                "exchange_rate": null,
                "payment_method": "PUE",
                "previous_balance": 2101.13,
                "outstanding_balance": -342.19,
                "invoice_identification": "19cb6867-86dd-dcce-49db-cdc1c75c894"
              }
            ],
            "payer_account_number": "885533574754",
            "beneficiary_account_number": "437299797428"
          }
        ],
        "warnings": null,
        "sender_id": "NEBG340118EEQ",
        "tax_amount": 0,
        "receiver_id": "TWL960824QYC",
        "sender_name": "Emisor 3CA6",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-05-18",
        "invoice_type": "Pago",
        "payment_type": "13",
        "total_amount": 0,
        "exchange_rate": 1,
        "receiver_name": "Receptor 7A3D",
        "payment_method": "PUE",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 0,
        "invoice_details": [
          {
            "quantity": 1,
            "unit_code": "ACT",
            "tax_amount": 0,
            "description": "Pago",
            "unit_amount": 0,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 0,
            "payment_method": null,
            "pre_tax_amount": 0,
            "retained_taxes": [],
            "tax_percentage": 0,
            "unit_description": "Producto 897F",
            "invoice_identification": null,
            "product_identification": "84111506",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 0,
        "cancelation_status": null,
        "certification_date": "2024-07-20T22:57:20",
        "invoice_identification": "ef845a2c-a753-a1f7-9be5-eb7e3e16eb8",
        "cancelation_update_date": null,
        "certification_authority": "e411726fa58bc",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      },
      {
        "id": "afef5147-3012-4e2d-96dc-f1252736ac4c",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225719Z",
        "type": "OUTFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 10.3,
          "date_to": "2024-09-08",
          "version": "1.2",
          "date_from": "2024-07-04",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-07-03",
          "other_payments": [],
          "tax_deductions": [],
          "earnings_breakdown": []
        },
        "version": "3.3",
        "currency": null,
        "payments": [
          {
            "date": "2024-06-30T01:39:35",
            "amount": 7081.93,
            "currency": null,
            "payer_rfc": "EEDBFA82E5",
            "collected_at": "2024-09-13T19:45:52.531788Z",
            "payment_type": "31",
            "exchange_rate": null,
            "beneficiary_rfc": "8ADC32EF9",
            "payer_bank_name": "BANK",
            "operation_number": "64325425",
            "related_documents": [
              {
                "currency": null,
                "amount_paid": 7081.93,
                "installment": "9",
                "collected_at": "2024-09-13T19:45:52.340875Z",
                "exchange_rate": null,
                "payment_method": "PPD",
                "previous_balance": 3057.51,
                "outstanding_balance": -4024.42,
                "invoice_identification": "6e683cd7-5774-4554-931a-3e49693b25f"
              }
            ],
            "payer_account_number": "972769398712",
            "beneficiary_account_number": "817746565492"
          }
        ],
        "warnings": null,
        "sender_id": "DIZT2110124W2",
        "tax_amount": 0,
        "receiver_id": "GFG180829IRS",
        "sender_name": "Emisor 5BF1",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-02-23",
        "invoice_type": "Pago",
        "payment_type": "31",
        "total_amount": 0,
        "exchange_rate": 1,
        "receiver_name": "Receptor 8DDF",
        "payment_method": "PPD",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 0,
        "invoice_details": [
          {
            "quantity": 1,
            "unit_code": "ACT",
            "tax_amount": 0,
            "description": "Pago",
            "unit_amount": 0,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 0,
            "payment_method": null,
            "pre_tax_amount": 0,
            "retained_taxes": [],
            "tax_percentage": 0,
            "unit_description": "Producto A323",
            "invoice_identification": null,
            "product_identification": "84111506",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 0,
        "cancelation_status": null,
        "certification_date": "2024-09-11T04:48:29",
        "invoice_identification": "a322167e-1dee-41ee-fda9-eed7a624a93",
        "cancelation_update_date": null,
        "certification_authority": "e1dbb7a8d9c17",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      },
      {
        "id": "7bb3ae14-49a7-4280-a100-6bd4d140158c",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225702Z",
        "type": "INFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 10.3,
          "date_to": "2024-09-08",
          "version": "1.2",
          "date_from": "2024-07-04",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-07-03",
          "other_payments": [],
          "tax_deductions": [],
          "earnings_breakdown": []
        },
        "version": "3.3",
        "currency": null,
        "payments": [],
        "warnings": null,
        "sender_id": "IOST4006037BY",
        "tax_amount": 7171.49,
        "receiver_id": "BOQ961016D1F",
        "sender_name": "Emisor 8D68",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-02-26",
        "invoice_type": "Egreso",
        "payment_type": "02",
        "total_amount": 51434.33,
        "exchange_rate": 1,
        "receiver_name": "Receptor 612B",
        "payment_method": "PPD",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 559,
        "invoice_details": [
          {
            "quantity": 6,
            "unit_code": "E48",
            "tax_amount": 4103.88,
            "description": "Servicios de mensajería.",
            "unit_amount": 4274.88,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 29753.16,
            "payment_method": null,
            "pre_tax_amount": 25649.28,
            "retained_taxes": [],
            "tax_percentage": 16,
            "unit_description": "Producto 9289",
            "invoice_identification": null,
            "product_identification": "492998317",
            "payment_type_description": null,
            "payment_method_description": null
          },
          {
            "quantity": 8,
            "unit_code": "E48",
            "tax_amount": 3067.61,
            "description": "Servicios de mensajería.",
            "unit_amount": 2396.57,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 22240.17,
            "payment_method": null,
            "pre_tax_amount": 19172.56,
            "retained_taxes": [],
            "tax_percentage": 16,
            "unit_description": "Producto ECC6",
            "invoice_identification": null,
            "product_identification": "846485643",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 44821.84,
        "cancelation_status": null,
        "certification_date": "2024-06-23T17:17:23",
        "invoice_identification": "e9a8af9c-f24e-c991-e87e-59df9125d11",
        "cancelation_update_date": null,
        "certification_authority": "f4df76578af26",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      },
      {
        "id": "b263d96a-82a6-4980-8618-10180934520b",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225686Z",
        "type": "INFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 10.3,
          "date_to": "2024-09-08",
          "version": "1.2",
          "date_from": "2024-07-04",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-07-03",
          "other_payments": [],
          "tax_deductions": [],
          "earnings_breakdown": []
        },
        "version": "3.3",
        "currency": null,
        "payments": [
          {
            "date": "2024-07-28T08:12:58",
            "amount": 3460.33,
            "currency": null,
            "payer_rfc": "87145DE464",
            "collected_at": "2024-09-13T19:45:52.531788Z",
            "payment_type": "03",
            "exchange_rate": null,
            "beneficiary_rfc": "CEDB81F76",
            "payer_bank_name": "BANK",
            "operation_number": "41477185",
            "related_documents": [
              {
                "currency": null,
                "amount_paid": 3460.33,
                "installment": "6",
                "collected_at": "2024-09-13T19:45:52.340875Z",
                "exchange_rate": null,
                "payment_method": "PPD",
                "previous_balance": 3957.56,
                "outstanding_balance": 497.23,
                "invoice_identification": "2ac955e9-ad3c-f49a-d913-ce25586962d"
              }
            ],
            "payer_account_number": "756667648217",
            "beneficiary_account_number": "215764411693"
          }
        ],
        "warnings": null,
        "sender_id": "AAKD830213ELB",
        "tax_amount": 0,
        "receiver_id": "KFB370608KLS",
        "sender_name": "Emisor 429A",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-02-22",
        "invoice_type": "Pago",
        "payment_type": "03",
        "total_amount": 0,
        "exchange_rate": 1,
        "receiver_name": "Receptor 79AA",
        "payment_method": "PPD",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 0,
        "invoice_details": [
          {
            "quantity": 1,
            "unit_code": "ACT",
            "tax_amount": 0,
            "description": "Pago",
            "unit_amount": 0,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 0,
            "payment_method": null,
            "pre_tax_amount": 0,
            "retained_taxes": [],
            "tax_percentage": 0,
            "unit_description": "Producto DE5D",
            "invoice_identification": null,
            "product_identification": "84111506",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 0,
        "cancelation_status": null,
        "certification_date": "2024-08-09T10:30:23",
        "invoice_identification": "4565fb63-5362-229c-c6ca-c264213dd81",
        "cancelation_update_date": null,
        "certification_authority": "a13b267afe888",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      },
      {
        "id": "3952d2e2-fabf-4a70-835e-3deb8f480c54",
        "link": "b335faf2-c84c-4387-9f8e-be6f412f22bc",
        "created_at": "2024-09-14T14:10:43.225669Z",
        "type": "INFLOW",
        "folio": null,
        "usage": "G03",
        "status": "Vigent",
        "payroll": {
          "days": 7,
          "type": "1",
          "amount": 3756.72,
          "date_to": "2024-08-15",
          "version": "1.2",
          "date_from": "2024-07-31",
          "periodicity": "MONTHLY",
          "collected_at": "2024-09-13T19:45:52.338469Z",
          "payment_date": "2024-08-30",
          "other_payments": [
            {
              "type": "TRAVEL_ALLOWANCES",
              "amount": 5949.57
            },
            {
              "type": "TRAVEL_ALLOWANCES",
              "amount": 1224.06
            },
            {
              "type": "EMPLOYMENT_SUBSIDY",
              "amount": 3635.61
            },
            {
              "type": "EMPLOYMENT_SUBSIDY",
              "amount": 2912.54
            },
            {
              "type": "EMPLOYMENT_SUBSIDY",
              "amount": 6070.17
            },
            {
              "type": "EMPLOYMENT_SUBSIDY",
              "amount": 1423.37
            }
          ],
          "tax_deductions": [
            {
              "type": "OTHERS",
              "amount": 1118.27
            },
            {
              "type": "ALIMONY",
              "amount": 1324.92
            },
            {
              "type": "RENT",
              "amount": 6922.25
            },
            {
              "type": "OTHERS",
              "amount": 9325.06
            },
            {
              "type": "OTHERS",
              "amount": 8987.94
            },
            {
              "type": "ALIMONY",
              "amount": 9497.06
            },
            {
              "type": "ALIMONY",
              "amount": 6217.87
            },
            {
              "type": "ALIMONY",
              "amount": 9848.9
            }
          ],
          "earnings_breakdown": [
            {
              "type": "SALARY",
              "taxable_amount": 1221.88,
              "vat_free_amount": 7073.98
            }
          ]
        },
        "version": "3.3",
        "currency": null,
        "payments": [],
        "warnings": null,
        "sender_id": "YEDD000819MRP",
        "tax_amount": 518.17,
        "receiver_id": "KKK0110030UZ",
        "sender_name": "Emisor 4819",
        "collected_at": "2024-09-13T19:45:52.533142Z",
        "invoice_date": "2024-08-30",
        "invoice_type": "Nómina",
        "payment_type": "24",
        "total_amount": 2701.89,
        "exchange_rate": 1,
        "receiver_name": "Receptor 1854",
        "payment_method": "PUE",
        "place_of_issue": "11000",
        "additional_data": null,
        "discount_amount": 1573,
        "invoice_details": [
          {
            "quantity": 1,
            "unit_code": "ACT",
            "tax_amount": 518.17,
            "description": "Pago de nómina",
            "unit_amount": 3238.55,
            "collected_at": "2024-09-13T19:45:52.339911Z",
            "expense_type": null,
            "payment_type": null,
            "total_amount": 3756.72,
            "payment_method": null,
            "pre_tax_amount": 3238.55,
            "retained_taxes": [],
            "tax_percentage": 16,
            "unit_description": "Producto A5A8",
            "invoice_identification": null,
            "product_identification": "84111505",
            "payment_type_description": null,
            "payment_method_description": null
          }
        ],
        "subtotal_amount": 3756.72,
        "cancelation_status": null,
        "certification_date": "2024-06-24T04:51:47",
        "invoice_identification": "186856bf-a169-a736-4434-d1bcce3572d",
        "cancelation_update_date": null,
        "certification_authority": "489751e587762",
        "sender_tax_fraud_status": null,
        "payment_type_description": null,
        "receiver_tax_fraud_status": null,
        "payment_method_description": null
      }
    ]
  }