export const getIconName = (preformattedName, library) => {
    const libraryName = iconMap[preformattedName]?.[library];
    
    const prefixMap = {
        feather: 'Fi',
        ionic: 'Io',
        fontawesome: 'Fa',
        material: 'MdOutline',
        heroicons: 'HiOutline',  
        iconoir: '',
    };

    const prefix = prefixMap[library];

    // Convert the base icon name to CamelCase with the correct prefix
    let reactIconsName = libraryName?.replace(/-([a-z])/g, (match, letter) => letter?.toUpperCase());
    reactIconsName = prefix + reactIconsName?.charAt(0).toUpperCase() + reactIconsName?.slice(1);
    
    return reactIconsName;
};

export const iconMap = {
    'add': {
        feather: 'plus',
        ionic: 'add', 
        material: 'add',
        heroicons: 'plus',
        iconoir: 'plus',
    }, 
    'plus': {
        feather: 'plus',
        ionic: 'add', 
        material: 'add',
        heroicons: 'plus',
        iconoir: 'plus',
    },
    'edit': {
        feather: 'edit',
        ionic: 'create-outline', 
        material: 'edit',
        heroicons: 'pencil',
        iconoir: 'edit',
    },
    'plus-circle': {
        feather: 'plus-circle',
        ionic: 'add-circle-outline', 
        material: 'add-circle-outline',
        heroicons: 'plus-circle',
        iconoir: 'plus-circle',
    },
    'close': {
        feather: 'x',
        ionic: 'close-outline', 
        material: 'close',
        heroicons: 'x',
        iconoir: 'xmark',
    },
    'xmark': {
        feather: 'x',
        ionic: 'close-outline', 
        material: 'close',
        heroicons: 'x',
        iconoir: 'xmark',
    },
    'x-mark': {
        feather: 'x',
        ionic: 'close-outline', 
        material: 'close',
        heroicons: 'x',
        iconoir: 'xmark',
    },
    'home': {
        feather: 'home',
        ionic: 'home-outline', 
        material: 'home',
        heroicons: 'home',
        iconoir: 'home-simple-door',
    },
    'chevron-left': {
        feather: 'chevron-left',
        ionic: 'chevron-back-outline', 
        material: 'chevron-left',
        heroicons: 'chevron-left',
        iconoir: 'nav-arrow-left',
    },
    'chevron-right': {
        feather: 'chevron-right',
        ionic: 'chevron-forward', 
        material: 'chevron-right',
        heroicons: 'chevron-right',
        iconoir: 'nav-arrow-right',
    },
    'chevron-up': {
        feather: 'chevron-up',
        ionic: 'chevron-up-outline', 
        material: 'keyboard-arrow-up',
        heroicons: 'chevron-up',
        iconoir: 'nav-arrow-up',
    },
    'chevron-down': {
        feather: 'chevron-down',
        ionic: 'chevron-down-outline', 
        material: 'keyboard-arrow-down',
        heroicons: 'chevron-down',
        iconoir: 'nav-arrow-down',
    },
    'send': {
        feather: 'send',
        ionic: 'send-outline', 
        material: 'send',
        heroicons: 'paper-airplane',
        iconoir: 'send',
    },
    'heart': {
        feather: 'heart',
        ionic: 'heart-outline', 
        material: 'favorite-border',
        heroicons: 'heart',
        iconoir: 'heart',
    },
    'bookmark': {
        feather: 'bookmark',
        ionic: 'bookmark-outline',
        material: 'bookmark-border',
        heroicons: 'bookmark',
        iconoir: 'bookmark',
    },
    'copy': {
        feather: 'copy',
        ionic: 'copy-outline', 
        material: 'content-copy',
        heroicons: 'duplicate',
        iconoir: 'copy',
    },
    'check': {
        feather: 'check',
        ionic: 'checkmark-outline', 
        material: 'check',
        heroicons: 'check',
        iconoir: 'check',
    },
    'check-circle': {
        feather: 'check-circle',
        ionic: 'checkmark-circle-outline',
        material: 'check-circle',
        heroicons: 'check-circle',
        iconoir: 'check-circle',
    },
    'truck': {
        feather: 'truck',
        ionic: 'car-outline', 
        material: 'local-shipping',
        heroicons: 'truck',
        iconoir: 'truck',
    },
    'building': {
        feather: 'home',
        ionic: 'business-outline',
        material: 'business',
        heroicons: 'building-office',
        iconoir: 'building',
    },
    'chart-up': {
        feather: 'trending-up',
        ionic: 'trending-up-outline',
        material: 'trending-up',
        heroicons: 'arrow-trending-up',
        iconoir: 'graph-up',
    }, 
    'chart-down': {
        feather: 'trending-down',
        ionic: 'trending-down-outline',
        material: 'trending-down',
        heroicons: 'arrow-trending-down',
        iconoir: 'graph-down',
    },
    'warning': {
        feather: 'alert-triangle',
        ionic: 'warning-outline',
        material: 'warning',
        heroicons: 'exclamation',
        iconoir: 'warning-triangle',
    },
    'calendar': {
        feather: 'calendar',
        ionic: 'calendar-outline',
        material: 'event',
        heroicons: 'calendar',
        iconoir: 'calendar',
    }, 
    'box': {
        feather: 'box',
        ionic: 'cube-outline',
        material: 'inbox',
        heroicons: 'cube',
        iconoir: 'box-iso',
    },
    'refresh': {
        feather: 'rotate-cw',
        ionic: 'refresh-outline',
        material: 'refresh',
        heroicons: 'refresh',
        iconoir: 'refresh',
    },
    'bell': {
        feather: 'bell',
        ionic: 'notifications-outline',
        material: 'notifications',
        heroicons: 'bell',
        iconoir: 'bell',
    },
    'dashboard': {
        feather: 'grid',
        ionic: 'grid-outline',
        material: 'dashboard',
        heroicons: 'view-grid',
        iconoir: 'stats-up-square',
    },
    'activity': {
        feather: 'activity',
        ionic: 'pulse-outline',
        material: 'timeline',
        heroicons: 'chart-pie',
        iconoir: 'activity',
    },
    'people': {
        feather: 'users',
        ionic: 'people-outline',
        material: 'people',
        heroicons: 'user-group',
        iconoir: 'group',
    },
    'log-out': {
        feather: 'log-out',
        ionic: 'log-out-outline',
        material: 'logout',
        heroicons: 'logout',
        iconoir: 'log-out',
    },
    'settings': {
        feather: 'settings',
        ionic: 'settings-outline',
        material: 'settings',
        heroicons: 'cog',
        iconoir: 'settings',
    },
    'help': {
        feather: 'help-circle',
        ionic: 'help-circle-outline',
        material: 'help',
        heroicons: 'question-mark-circle',
        iconoir: 'help-circle'
    },
    'support': {
        feather: 'life-buoy',
        ionic: 'help-buoy-outline',
        material: 'support',
        heroicons: 'support',
        iconoir: 'lifebelt',
    },
    'link': {
        feather: 'link',
        ionic: 'link-outline',
        material: 'link',
        heroicons: 'link',
        iconoir: 'link',
    }, 
    'google': {
        feather: 'google',
        ionic: 'logo-google',
        material: 'google',
        heroicons: 'logo-google',
        iconoir: 'google',
    },
    'apple': {
        feather: 'apple',
        ionic: 'logo-apple',
        material: 'apple',
        heroicons: 'logo-apple',
        iconoir: 'apple',
    },
    'facebook': {
        feather: 'facebook',
        ionic: 'logo-facebook',
        material: 'facebook',
        heroicons: 'logo-facebook',
        iconoir: 'facebook',
    },
    'twitter': {
        feather: 'twitter',
        ionic: 'logo-twitter',
        material: 'twitter',
        heroicons: 'logo-twitter',
        iconoir: 'twitter',
    },
    'instagram': {
        feather: 'instagram',
        ionic: 'logo-instagram',
        material: 'instagram',
        heroicons: 'logo-instagram',
        iconoir: 'instagram',
    },
    'linkedin': {
        feather: 'linkedin',
        ionic: 'logo-linkedin',
        material: 'linkedin',
        heroicons: 'logo-linkedin',
        iconoir: 'linkedin',
    },
    'youtube': {
        feather: 'youtube',
        ionic: 'logo-youtube',
        material: 'youtube',
        heroicons: 'logo-youtube',
        iconoir: 'youtube',
    },
    'github': {
        feather: 'github',
        ionic: 'logo-github',
        material: 'github',
        heroicons: 'logo-github',
        iconoir: 'github',
    },
    'microsoft': {
        feather: 'microsoft',
        ionic: 'logo-windows',
        material: 'microsoft',
        heroicons: 'logo-windows',
        iconoir: 'windows',
    },
    'folder': {
        feather: 'folder',
        ionic: 'folder-outline',
        material: 'folder',
        heroicons: 'folder',
        iconoir: 'folder',
    },
    'search': {
        feather: 'search',
        ionic: 'search-outline',
        material: 'search',
        heroicons: 'search',
        iconoir: 'search',
    },
    'globe': {
        feather: 'globe',
        ionic: 'globe-outline',
        material: 'public',
        heroicons: 'globe',
        iconoir: 'globe',
    },
    'language': {
        feather: 'globe',
        ionic: 'globe-outline',
        material: 'public',
        heroicons: 'globe',
        iconoir: 'language',
    },
    'message': {
        feather: 'message-square',
        ionic: 'chatbubble-ellipses-outline',
        material: 'chat',
        heroicons: 'chat-alt',
        iconoir: 'chat-bubble',
    },
    'more-horiz': {
        feather: 'more-horizontal',
        ionic: 'ellipsis-horizontal-outline',
        material: 'more-horiz',
        heroicons: 'dots-horizontal',
        iconoir: 'more-horiz',
    },
    'arrow-down': {
        feather: 'arrow-down',
        ionic: 'arrow-down-outline',
        material: 'arrow-down',
        heroicons: 'arrow-down',
        iconoir: 'arrow-down',
    },
    'arrow-up': {
        feather: 'arrow-up',
        ionic: 'arrow-up-outline',
        material: 'arrow-up',
        heroicons: 'arrow-up',
        iconoir: 'arrow-up',
    },
    'arrow-left': {
        feather: 'arrow-left',
        ionic: 'arrow-back-outline',
        material: 'arrow-left',
        heroicons: 'arrow-left',
        iconoir: 'arrow-left',
    },
    'arrow-right': {
        feather: 'arrow-right',
        ionic: 'arrow-forward-outline',
        material: 'arrow-right',
        heroicons: 'arrow-right',
        iconoir: 'arrow-right',
    },
    'hourglass': {
        feather: 'clock',
        ionic: 'time-outline',
        material: 'hourglass',
        heroicons: 'clock',
        iconoir: 'hourglass',
    },
    'clock': {
        feather: 'clock',
        ionic: 'time-outline',
        material: 'clock',
        heroicons: 'clock',
        iconoir: 'clock',
    },
    'favorite': {
        feather: 'heart',
        ionic: 'heart-outline',
        material: 'favorite-border',
        heroicons: 'heart',
        iconoir: 'heart',
    },
    'star': {
        feather: 'star',
        ionic: 'star-outline',
        material: 'star-border',
        heroicons: 'star',
        iconoir: 'star',
    },
    'image': {
        feather: 'image',
        ionic: 'image-outline',
        material: 'image',
        heroicons: 'image',
        iconoir: 'media-image',
    },
    'mail': {
        feather: 'mail',
        ionic: 'mail-outline',
        material: 'mail',
        heroicons: 'mail',
        iconoir: 'mail',
    },
    'phone': {
        feather: 'phone',
        ionic: 'call-outline',
        material: 'phone',
        heroicons: 'phone',
        iconoir: 'phone',
    },

    'trash': {
        feather: 'trash',
        ionic: 'trash-outline',
        material: 'delete',
        heroicons: 'trash',
        iconoir: 'trash',
    },
    'archive': {
        feather: 'archive',
        ionic: 'archive-outline',
        material: 'archive',
        heroicons: 'archive',
        iconoir: 'archive',
    },
    'translate': {
        feather: 'globe',
        ionic: 'language-outline',
        material: 'translate',
        heroicons: 'language',
        iconoir: 'translate',
    },
    'arrow-up-circle': {
        feather: 'arrow-up-circle',
        ionic: 'arrow-up-circle-outline',
        material: 'arrow-upward',   
        heroicons: 'arrow-up-circle',
        iconoir: 'arrow-up-circle-solid',
    },
    'arrow-down-circle': {
        feather: 'arrow-down-circle',
        ionic: 'arrow-down-circle-outline',
        material: 'arrow-downward',
        heroicons: 'arrow-down-circle',
        iconoir: 'arrow-down-circle-solid',
    },
    'arrow-left-circle': {
        feather: 'arrow-left-circle',
        ionic: 'arrow-back-circle-outline',
        material: 'arrow-back',
        heroicons: 'arrow-left-circle',
        iconoir: 'arrow-left-circle-solid',
    },
    'arrow-right-circle': {
        feather: 'arrow-right-circle',
        ionic: 'arrow-forward-circle-outline',
        material: 'arrow-forward',
        heroicons: 'arrow-right-circle',
        iconoir: 'arrow-right-circle-solid',
    },
    'shopping': {
        feather: 'shopping-bag',
        ionic: 'bag-handle-outline',
        material: 'shopping-cart',
        heroicons: 'shopping-bag',
        iconoir: 'shopping-bag',
    },
    'cart': {
        feather: 'shopping-cart',
        ionic: 'cart-outline',
        material: 'shopping-cart',
        heroicons: 'shopping-cart',
        iconoir: 'cart',
    },
    'post': {
        feather: 'file-text',
        ionic: 'document-text-outline',
        material: 'article',
        heroicons: 'document-text',
        iconoir: 'post',
    },
    'page': {
        feather: 'file-text',
        ionic: 'document-text-outline',
        material: 'article',
        heroicons: 'document-text',
        iconoir: 'page',
    },
    'graduation-cap': {
        feather: 'book-open',
        ionic: 'school-outline',
        material: 'school',
        heroicons: 'academic-cap',
        iconoir: 'graduation-cap',
    },
    'info': {
        feather: 'info',
        ionic: 'information-circle-outline',
        material: 'info',
        heroicons: 'information-circle',
        iconoir: 'info-circle',
    },
    'cloud': {
        feather: 'cloud',
        ionic: 'cloud-outline',
        material: 'cloud',
        heroicons: 'cloud',
        iconoir: 'cloud',
    },
    'cloud-upload': {
        feather: 'upload',
        ionic: 'cloud-upload-outline',
        material: 'cloud-upload',
        heroicons: 'cloud-upload',
        iconoir: 'cloud-upload',
    },
    'upload': {
        feather: 'upload',
        ionic: 'cloud-upload-outline',
        material: 'cloud-upload',
        heroicons: 'cloud-upload',
        iconoir: 'upload',
    },
    'flower': {
        feather: 'loader',
        ionic: 'flower-outline',
        material: 'filter-vintage',
        heroicons: 'sun',
        iconoir: 'flower',
    },
    'moon' : {
        feather: 'moon',
        ionic: 'moon-outline',
        material: 'dark-mode',
        heroicons: 'moon',
        iconoir: 'half-moon',
    },
    'sun': {
        feather: 'sun',
        ionic: 'sunny-outline',
        material: 'light-mode',
        heroicons: 'sun',
        iconoir: 'sun-light',
    },
    'smartphone': {
        feather: 'smartphone',
        ionic: 'phone-portrait-outline',
        material: 'smartphone',
        heroicons: 'device-mobile',
        iconoir: 'smartphone-device',
    },
    'code': {
        feather: 'code',
        ionic: 'code-outline',
        material: 'code',
        heroicons: 'code',
        iconoir: 'code',
    },
    'smiley': {
        feather: 'smile',
        ionic: 'happy-outline',
        material: 'sentiment-satisfied',
        heroicons: 'emoji-happy',
        iconoir: 'emoji',
    },
    'list': {
        feather: 'list',
        ionic: 'list-outline',
        material: 'list',
        heroicons: 'view-list',
        iconoir: 'list',
    },
    'rocket': {
        feather: 'navigation',
        ionic: 'rocket-outline',
        material: 'rocket',
        heroicons: 'rocket',
        iconoir: 'rocket',
    },
    'circle': {
        feather: 'circle',
        ionic: 'ellipse-outline',
        material: 'circle',
        heroicons: 'circle',
        iconoir: 'circle',
    },
    'stack-overflow': {
        feather: 'layers',
        ionic: 'logo-stack-overflow',
        material: 'stack-overflow',
        heroicons: 'stack',
        iconoir: 'stackoverflow',
    },
    'layers': {
        feather: 'layers',
        ionic: 'layers-outline',
        material: 'layers',
        heroicons: 'square-3-stack-3d',
        iconoir: 'apple-shortcuts',
    },
    'cube': {
        feather: 'box',
        ionic: 'cube-outline',
        material: 'square',
        heroicons: 'cube',
        iconoir: 'cube',
    },
    'sparkles' : {
        feather: 'zap',
        ionic: 'sparkles-outline',
        material: 'flare',
        heroicons: 'sparkles',
        iconoir: 'sparks',
    },
    'flare': {
        feather: 'zap',
        ionic: 'flare-outline',
        material: 'flare',
        heroicons: 'sparkles',
        iconoir: 'flare',
    },
    'cursor': {
        feather: 'mouse-pointer',
        ionic: 'hand-right-outline',
        material: 'mouse',
        heroicons: 'cursor-click',
        iconoir: 'cursor-pointer',
    },
    'play': {
        feather: 'play',
        ionic: 'play-outline',
        material: 'play-arrow',
        heroicons: 'play',
        iconoir: 'play-solid',
    },
    'flash': {
        feather: 'zap',
        ionic: 'flash-outline',
        material: 'bolt',
        heroicons: 'lightning-bolt',
        iconoir: 'flash',
    },
    'grid': {
        feather: 'grid',
        ionic: 'grid-outline',
        material: 'grid-view',
        heroicons: 'view-grid',
        iconoir: 'view-grid',
    },
    'book' :{
        feather: 'book',
        ionic: 'book-outline',
        material: 'book',
        heroicons: 'book',
        iconoir: 'book',
    },
    'layout': {
        feather: 'layout',
        ionic: 'grid-outline',
        material: 'layout',
        heroicons: 'view-columns',
        iconoir: 'layout-left',
    },
    'tray': {
        feather: 'grid',
        ionic: 'grid-outline',
        material: 'tray',
        heroicons: 'inbox',
        iconoir: 'box',
    },
    'video': {
        feather: 'video',
        ionic: 'videocam-outline',
        material: 'video',
        heroicons: 'video-camera',
        iconoir: 'video',
    },
    'shirt': {
        feather: 't-shirt',
        ionic: 'shirt-outline',
        material: 'shirt',
        heroicons: 't-shirt',
        iconoir: 'shirt',
    },
    't-shirt': {
        feather: 't-shirt',
        ionic: 'shirt-outline',
        material: 'shirt',
        heroicons: 't-shirt',
        iconoir: 'shirt',
    },
    'colors': {
        feather: 'droplet',
        ionic: 'color-palette-outline',
        material: 'palette',
        heroicons: 'color-swatch',
        iconoir: 'color-wheel',
    },
    'palette': {
        feather: 'droplet',
        ionic: 'color-palette-outline',
        material: 'palette',
        heroicons: 'color-swatch',
        iconoir: 'palette',
    },
    'share': {
        feather: 'share',
        ionic: 'share-social-outline',
        material: 'share',
        heroicons: 'share',
        iconoir: 'open-new-window',
    },
    'sad': {
        feather: 'frown',
        ionic: 'sad-outline',
        material: 'sentiment-dissatisfied',
        heroicons: 'emoji-sad',
        iconoir: 'emoji-sad',
    },
    "arrows-up-down": {
        feather: 'move',
        ionic: 'swap-vertical-outline',
        material: 'swap-vert',
        heroicons: 'arrows-expand',
        iconoir: 'arrow-separate-vertical'
    }, 
    'frame': {
        feather: 'maximize',
        ionic: 'expand-outline',
        material: 'frame',
        heroicons: 'frame',
        iconoir: 'frame-alt-empty',
    },
    'download': {
        feather: 'download',
        ionic: 'download',
        material: 'download',
        heroicons: 'download',
        iconoir: 'download',
    }, 
     
    'align-left': {
        feather: 'align-left',
        ionic: 'align-left',
        material: 'format-align-left',
        heroicons: 'align-left',
        iconoir: 'comp-align-left-solid',
    },
    'align-right': {
        feather: 'align-right',
        ionic: 'align-right',
        material: 'format-align-right',
        heroicons: 'align-right',
        iconoir: 'comp-align-right-solid',
    },
    'align-hor-center': {
        feather: 'align-center',
        ionic: 'align-horizontal-center',
        material: 'format-align-center',
        heroicons: 'align-horizontal-center',
        iconoir: 'align-horizontal-centers-solid',
    },
    'align-hor-stretch': {
        feather: 'align-justify',
        ionic: 'align-justify',
        material: 'format-align-justify',
        heroicons: 'align-justify',
        iconoir: 'align-horizontal-spacing-solid',
    },
    'align-top': {
        feather: 'align-left',
        ionic: 'align-top',
        material: 'format-align-top',
        heroicons: 'align-top',
        iconoir: 'comp-align-top-solid',
    },
    'align-bottom': {
        feather: 'align-right',
        ionic: 'align-bottom',
        material: 'format-align-bottom',
        heroicons: 'align-bottom',
        iconoir: 'comp-align-bottom-solid',
    },
    'align-vert-center': {
        feather: 'align-center',
        ionic: 'align-vertical-center',
        material: 'format-align-vertical-center',
        heroicons: 'align-vertical-center',
        iconoir: 'align-vertical-centers-solid',
    },
    'align-vert-stretch': {
        feather: 'align-justify',
        ionic: 'align-vertical-center',
        material: 'format-align-vertical-center',
        heroicons: 'align-vertical-center',
        iconoir: 'align-vertical-spacing-solid',
    },
    'figma': {
        feather: 'figma',
        ionic: 'logo-figma',
        material: 'figma',
        heroicons: 'figma',
        iconoir: 'figma',
    },
    'pause': {
        feather: 'pause',
        ionic: 'pause-outline',
        material: 'pause',
        heroicons: 'pause',
        iconoir: 'pause-solid',
    },
    'arrow-separate-vertical': {
        feather: 'move',
        ionic: 'swap-vertical-outline',
        material: 'swap-vert',
        heroicons: 'arrows-expand',
        iconoir: 'arrow-separate-vertical'
    },
    'arrow-separate': {
        feather: 'move',
        ionic: 'swap-horizontal-outline',
        material: 'swap-horiz',
        heroicons: 'arrows-expand',
        iconoir: 'arrow-separate'
    },
    'minus': {
        feather: 'minus',
        ionic: 'remove-outline',
        material: 'remove',
        heroicons: 'minus',
        iconoir: 'minus'
    },
    'eye': {
        feather: 'eye',
        ionic: 'eye-outline',
        material: 'visibility',
        heroicons: 'eye',
        iconoir: 'eye'
    },
    'eye-off': {
        feather: 'eye-off',
        ionic: 'eye-off-outline',
        material: 'visibility-off',
        heroicons: 'eye-off',
        iconoir: 'eye-closed'
    },
    'card': {
        feather: 'credit-card',
        ionic: 'card-outline',
        material: 'credit-card',
        heroicons: 'credit-card',
        iconoir: 'credit-card'
    },
    'lock': {
        feather: 'lock',
        ionic: 'lock-closed-outline',
        material: 'lock',
        heroicons: 'lock-closed',
        iconoir: 'lock'
    },
    'shield': {
        feather: 'shield',
        ionic: 'shield-checkmark-outline',
        material: 'security',
        heroicons: 'shield-check',
        iconoir: 'shield'
    },
    
}   


