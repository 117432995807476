import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, InputText, MiniSnippet, TabGroup, TextArea } from "../../../../../ui-kit/exports/react";
import { makeImportString, prepareHybridJSX } from "./helpers";
import pretty from 'pretty';
import Fiddle from "./Fiddle";

export default function ShowCode({ selector, view, setView }) {

    const codeBlock = useRef(null);

    const tabs = [
      {value: 'inspector', label: 'Inspect', icon: 'cursor'},
      {value: 'fiddle', label: 'Create', icon: 'flare'}
    ]
    
    const [selectedTab, setSelectedTab] = useState('inspector')

    const formatObjects = selectedTab == 'inspector' ? selector : {...selector, object: null}
    let importString = makeImportString(formatObjects, true)
    const modifiedSelector = selectedTab == 'inspector' ? selector : {...selector, object: null}
    let jsx = prepareHybridJSX(modifiedSelector)
    jsx = pretty(jsx, {ocd: true});
    jsx = selectedTab != 'inspector' && importString ? importString + `\n\n` + jsx : jsx;

    

    return (
      <div
        className={`flex flex-col text-sm gap-2 rounded-l-xl overflow-hidden`}
        style={{ maxHeight: "100%" }}
      >
        <div className="px-2.5 pt-3 pb-2 bg-base-50 border-b border-base-200 flex flex-col w-full gap-2">
        
        <div className={`gap-2 w-full flex flex-row items-center justify-between text-xs`}>
        <div className="w-auto flex flex-row items-center gap-2">
          
        <TabGroup
            tabs={tabs}
            selectColor={'primary'}
            value={selectedTab}
            onChange={(value) => setSelectedTab(value)}
            size={'small'}
            style={'buttons'}
          />
          </div>
          {
            <div className="w-auto flex flex-row items-center gap-2 relative">
            <Button size={'small'} text={'Docs'} style="ghost" color={'base-700'} rightIcon="arrow-right"
              onClick={() => setView({...view, overview: true, inOverview: 'designSystem'})}
            />
            </div>
          }

        </div>
        </div>

        
  
      
        
        <div className="overflow-y-auto select-text text-xs rounded-lg flex flex-col gap-2 px-2">
        {importString && selectedTab == 'inspector' &&
        <div className="w-full flex flex-col gap-1">
        <MiniSnippet
        whiteSpace={'pre'}
        bgColor={'base-50'}
        hightlightSyntax={true}
        text={importString}
        />
        
        </div>}
        <div id="code-block" ref={codeBlock} className="flex flex-col w-full gap-2 code-block">
          

          {selectedTab == 'fiddle' ?
            <Fiddle
            jsx={jsx}
            selector={selector}
            />
            :
            <MiniSnippet
              whiteSpace={'pre'}
              bgColor={'base-50'}
              hightlightSyntax={true}
              maxHeight={500}
              text={jsx}
              />}
          </div>
        </div>
        </div>
    );
  };
  
  
