import React, { useContext, useEffect, useRef, useState } from 'react';
import * as IconoirIcons from 'iconoir-react';
import { EditorContext } from "../EditorContext";
import { Button, ButtonIcon } from '../../../ui-kit/exports/react';

export default function ThemeSwitcher({tooltipStyle}) {
    const {project, ds, goDark, handleAction, selectTheme} = useContext(EditorContext)
    const themes = ds?.themes;
    const defaultTheme = themes?.find(theme => theme.id == ds.designSystem?.default_theme_id)
    const currentTheme = project.project?.current_theme_id ? themes?.find(theme => theme.id == project.project?.current_theme_id) : defaultTheme
    
    const [isOpen, setIsOpen] = useState(false);  // State to manage dropdown visibility
    
    const dropdownRef = useRef(null);  // Ref to help with detecting outside clicks

    useEffect(() => {
        // Function to handle clicking outside the dropdown
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            // Attach the listener if the dropdown is open
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Clean up
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className='relative flex flex-row items-center font-medium text-xs transition-all duration-75 rounded-lg'
        ref={dropdownRef}
        >
            <ButtonIcon 
            icon={'palette'} 
            state={isOpen ? 'active' : 'default'}
            color={'base-200'} onClick={() => setIsOpen(!isOpen)} size={'small'} 
                />
            {isOpen && (  
                <div className='absolute -bottom-3 translate-y-full w-[240px] bg-white right-0 flex flex-col p-1 rounded-lg border border-slate-200 text-gray-800 font-medium gap-1'>
                
                    <ThemePreview 
                key={defaultTheme.id} 
                theme={defaultTheme} 
                isDefault={true}
                isSelected={currentTheme?.id == defaultTheme.id}
                onSelect={selectTheme}
                />    
                {themes
                .filter(theme => theme.id != defaultTheme.id)
                // .slice(0, 5)
                .map(theme=>
                <ThemePreview 
                key={theme.id} 
                theme={theme} 
                isDefault={false}
                isSelected={currentTheme?.id == theme.id}
                onSelect={selectTheme}
                />    )}
                
                </div>
            )}
        </div>
    );
}



function ThemePreview({theme, isSelected=false, isDefault, onSelect,}) {
    const variables = theme?.variables || []
    
    const baseColor = variables.find(v=>v.api_name == 'base-0')
    const base200 = variables.find(v=>v.api_name == 'base-200')
    const baseText = variables.find(v=>v.api_name == 'base-content')
    const primaryColor = variables.find(v=>v.api_name == 'primary')
    const accentColor = variables.find(v=>v.api_name == 'accent')
    const fontFamily = variables.find(v=>v.api_name == 'mainFont')
    const fontWeight = theme.fontPrefence == 'slim' ? 300 : theme.fontPrefence == 'bold' ? 700 : 500
    const borderBase = variables.find(v=>v.api_name == 'rounded-base')
    const borderLarge = variables.find(v=>v.api_name == 'rounded-lg')

    return (
        <div className={`w-full flex flew-row items-center justify-between font-medium text-xs p-1 rounded-md
        transition-all ${isSelected ? 'border-primary' : 'border-slate-200'}
        `}
        style={{
            height: 40, 
            
            boxSizing: 'border-box',
            backgroundColor: baseColor?.value,  
            borderWidth: isSelected ? 1 : 1,
            color: baseText?.value, 
            fontFamily: fontFamily?.value || 'Inter', 
            fontWeight: fontWeight,
    }}  
        onClick={(e)=>{e.stopPropagation(); onSelect(theme.id)}}
        
        >
        <div className="flex flex-row w-auto h-full gap-1 items-center">
            <div 
            className="h-full w-6 rounded"
            style={{
                backgroundColor: primaryColor?.value, 
                // borderRadius: borderBase?.value || '0.5rem'
                }} />
            <div 
            className="h-full w-3"
            style={{
                backgroundColor: accentColor?.value, 
                borderRadius: borderBase?.value || '0.5rem'
                
                }} />
                <span className="ml-2"
                style={{
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 88

                }}
                >{theme.display_name} </span>
        </div>
        </div>
    )

}