import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */
import { Icon } from '../'; /* replace to 'import { Icon } from './index'' */
import { iconMap } from '../media/iconMap'; /* replace to 'import { iconMap } from './iconMap'' */
import { useMemo } from 'react';

const allIconNames = Object.keys(iconMap) || []

type BlogPostCardProps = {
    imageSrc?: string,
    width?: '100%' | '200px' | '320px' | '400px',
    corners?: 'none' | 'sm' | 'md' | 'lg' | 'xl',
    imageAspectRatio?: '1 / 1' | '2 / 1' | '3 / 1' | '4 / 1',
    textSize?: 'small' | 'medium',
    title?: string,
    icon?: 'none' | 'calendar' | 'post',
    posted_date?: string,
    author_name?: string,
    description?: string,
    defaultIconSet?: string,
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function BlogPostCard({
        imageSrc = null,
        width = '100%',
        corners = 'md',
        imageAspectRatio = '2 / 1',
        textSize = 'small',
        title = "Post Title",
        posted_date = "Jun 2, 2023",
        author_name = "Author Name",
        description = "Short description that should be about 80-100 characters long.",
        defaultIconSet,
        attributes,
        listeners, 
        junoProps /* remove line */

    }: BlogPostCardProps) {
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{}} = junoProps || {} /* remove line */

    
    const sizeStyles = `w-full h-auto ${textSize == 'small' ? 'text-sm' : 'text-base'}`
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    let wrapperClasses = `flex flex-col items-stretch justify-start gap-3 ${sizeStyles}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */

    const noImage = !imageSrc;
    const imageStyles = useMemo(() => ({
        background: !noImage && `linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.12)), url(${imageSrc}) no-repeat center center / cover`, 
        backgroundSize: 'cover', 
        aspectRatio: imageAspectRatio
    }), [imageSrc, noImage, imageAspectRatio]);

    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const titleFont = textSize == 'small' ? 'text-base' : 'text-lg';
    const smallerFont = textSize == 'small' ? 'text-xs' : 'text-sm';

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={wrapperClasses} style={{maxWidth: width}}
        >
        {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Post Card</div> /* remove line */}
        
        {/* IMAGE / THUMBNAIL */}
        <div className={`relative w-full aspect-square flex items-center justify-center bg-current-10 ${cornerStyles}`} style={imageStyles}>
            {noImage && <Icon icon={'post'} className='flex-shrink-0' />}
        </div>
        
        {/* CONTENT BLOCK */}
        <div className={`flex flex-col flex-grow gap-1.5`} style={truncateStyle}>
            
            {/* Title */}
            <h3 className={`${titleFont} font-semibold `} style={truncateStyle}>
                {<EditableOnDoubleClick text={title} onBlur={onPropertyUpdate('title')} whiteSpace="nowrap"/> /* replace to '{title}' */}
            </h3>

            {/* post info */}
            {(author_name || posted_date ) && 
            <div className={`flex flex-row justify-between w-full gap-2 items-end ${smallerFont}`} style={{maxWidth: '100%'}}>
            <span className='font-medium truncate' style={truncateStyle}>
                {<EditableOnDoubleClick text={author_name} onBlur={onPropertyUpdate('author_name')} whiteSpace="nowrap"/> /* replace to '{author_name}' */}
            </span>
            {posted_date && <div className='flex-shrink-0 flex flex-row items-center gap-1.5'>
                {<EditableOnDoubleClick text={posted_date} onBlur={onPropertyUpdate('posted_date')} whiteSpace="nowrap"/> /* replace to '{posted_date}' */}
                <Icon icon='calendar' className='flex-shrink-0 scale-75' />
            </div>
            }</div>}
            {/* Description Lines */}
            {description && <div className={`${smallerFont} text-justify `} >
                {<EditableOnDoubleClick text={description} onBlur={onPropertyUpdate('description')} /> /* replace to '{description}' */}
            </div>}
        </div>
        </div>
    );
}


/* ignore rest */


BlogPostCard.stories = [
    {
        name: 'Default',
        wrapperClasses: 'flex flex-col w-[320px] h-auto p-4 bg-white !bg-base-0 text-base-content',
        props: {
            width: '100%',
        }
    }, 
    {
        name: 'Filled', 
        wrapperClasses: 'flex flex-col w-[320px] h-auto p-4 bg-white !bg-base-0',
        props: {
            width: '100%',
            corners: 'md',
            imageSrc: 'https://images.unsplash.com/photo-1528137871618-79d2761e3fd5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0OTQ5NTB8MHwxfHNlYXJjaHwxfHxhJTIwZGVsaWNpb3VzJTIwcGl6emF8ZW58MHx8fHwxNzIwNTQ5NzI5fDA&ixlib=rb-4.0.3&q=80&w=1080',
            title: 'The Ultimate Guide to Pizza',
            icon: 'calendar',
            posted_date: 'Oct 10, 2023',
            author_name: 'Pizza Lover',
            description: 'Secrets to making the perfect pizza.'

            
        }
    }

]

BlogPostCard.definitions = {
    apiName: 'BlogPostCard',
    displayName: 'Blog Post Card',
    description: 'Card used to display information about content post record, e.g. blog post or news post.',
    ai_instructions: 'Use this card to display information about a record, e.g. recipe, event. For things that sell preferrably use ProductCard.',
    type: 'cards',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    status: 'prototype',
    package: 'Pro',
    propDefinitions: {
        title: {
            type: 'string',
            default: "Post Title", 
            displayName: 'Title',
            required: true
        }, 
        description: {
            type: 'string',
            default: "Short description that should be about 80-100 characters long.",
            displayName: 'Description',
            ai_instructions: 'max 100 chatacters, will be trimmed if longer', 
        },
        posted_date: {
            type: 'string',
            default: "Jun 2, 2023",
            displayName: 'Date',
            ai_instructions: 'appears below title', 
            tile: '1/2'
        }, 
        author_name: {
            type: 'string',
            default: "Author Name", 
            displayName: 'Author',
            tile: '1/2'
        },
        
        width: {
            type: 'oneOf',
            options: ['100%', '200px', '320px', '400px'],
            default: '100%', 
            displayName: 'Width',
            tile: '1/2'
        },
        textSize: {
            type: 'size',
            options: ['small', 'medium'],
            ai_instructions: 'small sets title, price to text-sm and description to text-xs, medium sets title and price to text-base and description to text-sm',
            default: 'small', 
            displayName: 'Text Size',
            tile: '1/2'
        },
        corners: {
            type: 'corners',
            options: ["none", "sm", "md", "lg", 'xl'],
            default: 'md',
            ai_instructions: 'rounded corners for the thumbnail image',
            displayName: 'Corners',
            tile: '1/2'
        },
        imageAspectRatio: {
            type: 'oneOf',
            options: ['1 / 1', '2 / 1', , '3 / 1', '4 / 1'],
            default: '3 / 1', 
            displayName: 'Image Apect',
            tile: '1/2'
        },
        imageSrc: {
            type: 'imageURL',
            default: null
        },
        
    }
};

/*
BlogPostCard.propTypes = {
    imageSrc: PropTypes.string,
    imageAspectRatio: PropTypes.oneOf(['1 / 1', '2 / 1', '3 / 1', '4 / 1']),
    textSize: PropTypes.oneOf(['small', 'medium']),
    title: PropTypes.string,
    icon: PropTypes.oneOf(['none', ...allIconNames]),
    posted_date: PropTypes.string,
    author_name: PropTypes.string,
    description: PropTypes.string,
    width: PropTypes.oneOf(['100%', '200px', '320px']),
    corners: PropTypes.oneOf(['none', 'sm', 'md', 'lg', 'xl'])
};*/