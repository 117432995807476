import { useContext } from "react"
import { EditorContext } from "./EditorContext"
import { NavArrowRight } from "iconoir-react"

export default function LeftSide() {
    const { view, project, pages, setView, selector, setSelector } = useContext(EditorContext)
    
    const folders = project.folders?.filter(f => !f.is_archived) || []
    const folder = selector?.folder || {}
    
    function handleClick(e, page) {
        if (page.id == selector.page.id) return
        e.stopPropagation();
        const frames = page.frames || []
        setSelector({ ...selector, folder, page, frame: frames[0], object: null})
        setView({...view, showAllFolders: false, inOverview: 'folders', showFeatures: false })
      }

    return (
        <div className={`
            ${!view.showFeatures ? 'hidden' : 'flex'}
            absolute top-12 left-3 z-50 max-h-[400px] min-w-[180px] max-w-[240px] overflow-y-scroll rounded-xl
            flex-col gap-2 p-2 backdrop-blur bg-base-0/50 ring-[0.5px] ring-base-100
            transition-all duration-150
            `}
        
            style={{animation: 'fadeInGrow 0.1s'}}
        >
            {folders.map(folder => (
                <div key={folder.id} className="w-full flex flex-col select-none gap-2">
                    <h3 className="text-xs font-normal text-base-600">{folder.name}</h3>
                    {pages.filter(p => p.folder_id == folder.id)
                    .filter(p => !p.is_archived)
                    .map(page => (
                        <div key={page.id} 
                        className="w-full bg-base-0 ring-[0.5px] ring-base-100 px-3 py-2 rounded-lg font-medium
                        bg-base-0 backdrop-blur relative group hover:shadow
                        select-none text-xs hover:scale-105 transition-all duration-150 cursor-pointer"
                        onClick={(e) => handleClick(e, page)}
                        >{page.name}
                        <NavArrowRight 
                        width={16} height={16}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-60 transition-all duration-150"
                        />
                        </div>

                    ))}
                </div>
            ))}
            
        </div>
    )
}
/*
*/