import React from 'react'
import EditableOnDoubleClick from '../../../components/EditableOnDoubleClick'; /* remove line */

type BadgeProps = {
    style?: 'filled' | 'outline' | 'light',
    text?: string,
    color?: 'base-200' | 'base-700' | 'primary' | 'accent' | 'info' | 'success' | 'warning' | 'error',
    size?: 'small' | 'medium',
    isPill?: boolean,
    alignSelf?: 'auto' | 'start' | 'end' | 'center',
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function Badge({
        style = 'filled',
        text = 'Badge',
        color = 'success',
        size = 'medium',
        isPill = false,
        alignSelf = 'auto',
        attributes,
        listeners, 
        junoProps /* remove line */
      }: BadgeProps) {
    
    const { junoAttributes, showTag, outlineStyle, onPropertyUpdate=()=>{} } = junoProps || {} /* remove line */
    
    const styleMap = {
        'filled': `bg-${color} text-${color == 'base-200' ? 'base-content' : 'base-0'} `,
        'outline': `text-${color}-content ring-1 ring-inset ring-${color}`,
        'light': color == 'base-200' ? `bg-base-100 text-base-content` : color == 'base-700' ? `bg-base-200 text-base-content` : `text-${color}-content bg-${color}-surface`
    }
    
    const sizeStyleMap = {
        small: `text-xs py-0.5 gap-1.5 ${isPill ? 'rounded-full px-2' : 'rounded px-1.5'}`,
        medium: `text-sm py-1 gap-2 ${isPill ? 'rounded-full px-3' : 'rounded-md px-2'}`,
    };
    
    const sizeStyles = sizeStyleMap[size]

    let wrapperClasses = `flex font-semibold items-center flex-shrink-0 justify-center leading-tight self-${alignSelf} ${sizeStyles} ${styleMap[style]}`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: 120 }

    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
            className={wrapperClasses}
        >   
            {showTag && <div className='selected-element-above' style={{zIndex: 2000}}>Badge</div>  /* remove line */}
            <span className='w-full' style={{...truncateStyle}} >
            {<EditableOnDoubleClick text={text} onBlur={onPropertyUpdate('text')} whiteSpace="nowrap"/>  /* replace to '            {text}' */}
            </span>
        </div>
    ); 
}


/* ignore rest */

Badge.definitions = {
    apiName: 'Badge',
    displayName: 'Badge',
    description: 'A badge component for displaying short text snippets with various styling options, including color, size, type, and rounded corners. The component is customizable and supports interactive editing.',
    ai_instructions: 'typical;y appears next to small inline elements.',
    type: 'indicators',
    relativeSize: 'small',
    acceptedChildren: 'none',
    status: 'in review',
    package: 'Starter',
    propDefinitions: {
        style: {
            type: 'oneOf',
            options: ['filled', 'outline', 'light'],
            default: 'filled', 
            displayName: 'Type',
            tile: '1/2'
        },

        size: {
            type: 'size',
            options: ['small', 'medium'],
            default: 'medium', 
            displayName: 'Size',
            tile: '1/2'
        },
        text: {
            type: 'string',
            displayName: 'Text',
            default: 'Badge', 
        },
        color: {
            type: 'colors',
            options: ['base-200', 'base-700',  'primary', 'accent', 'info', 'success', 'warning', 'error'],
            displayName: 'Color',
            default: 'success-content'
        },
        alignSelf: {
            type: 'oneOf',
            options: ['auto', 'start', 'end', 'center'],
            default: 'auto', 
            displayName: 'Align Self',
        },
        isPill: {
            type: 'bool',
            displayName: 'Make rounded',
            default: false
        },
        
    }
};

/*Badge.propTypes = {
    type: PropTypes.oneOf(['filled', 'outline', 'light']),
    text: PropTypes.string,
    color: PropTypes.oneOf(['base-200', 'base-700',  'primary', 'accent', 'info', 'success', 'warning', 'error']),
    size: PropTypes.oneOf(['small', 'medium']),
    isPill: PropTypes.bool,
    alignSelf: PropTypes.oneOf(['auto', 'start', 'end', 'center'])
};
*/