import React, { useState, useEffect, useContext, useRef } from 'react';
import { EditorContext } from '../../EditorContext';
import DnDContext from '../../dragNdrop/DnDContext';
import Keypad from './Keypad';
import ComponentTemplates from './templates/ComponentTemplates';

export default function UIKit({combinedCategories}) {
  const { selector, assets, effectiveVariables, handleAction } = useContext(EditorContext);
  const { handleDragStart, handleDragEnd } = useContext(DnDContext);
  

  const junoControls = {
    assets,
    selector, 
    frameId: selector.frame?.id,
    effectiveVariables,
    handleAction,
    handleDragStart,
    handleDragEnd, 
  }

  return (
    <div className='w-full h-full flex flex-col justify-start gap-2' style={{   userSelect: 'none' }}>
      <Keypad {...junoControls} />
      
      
    </div>
  );
}
