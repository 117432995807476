import React from 'react';
import { useState, useEffect } from 'react'; 
import * as IconoirIcons from 'iconoir-react';
import { spacingMap } from '../helpers'; /* replace to 'import { spacingMap } from './helpers';' */

type KebabMenuProps = {
    bgColor?: 'base-0' | 'base-100',
    padding?: '8x' | '12px' | '16px',
    gap?: '4px' | '6px' | '8x' | '12px' | '16px',
    icon?: 'MoreHoriz' | 'MoreVert' | 'MoreHorizCircle' | 'MoreVertCircle' | 'NavArrowDown' | 'Plus' | 'PlusCircle',
    buttonBgColor?: 'base-0' | 'base-100',
    buttonOutline?: boolean,
    size?: '16px' | '20px' | '24px' | '32px',
    showMenu?: boolean,
    menuMinWidth?: 'fitContent' | '120px' | '160px' | '240px' | '320px',
    corners?: 'none' | 'sm' | 'md' | 'lg',
    openDirection?: 'downward-right' | 'downward-left' | 'upward-right' | 'upward-left',
    children: React.ReactNode,
    attributes?: any,
    listeners?: any,
    junoProps?: any /* remove line */
}

export default function KebabMenu({
        bgColor = 'base-0',

        padding = null,
        gap = null,

        icon = 'MoreHoriz',
        buttonBgColor = null,
        buttonOutline = true,
        size = '20px',
        showMenu = false,
        menuMinWidth = '120px',
        corners = 'md',
        openDirection = 'downward-right',
        children,
        attributes,
        listeners, 
        junoProps /* remove line */
      }: KebabMenuProps) {
    const { junoAttributes,  outlineStyle, eventListeners } = junoProps || {} /* remove line */
    
    const [ open, setOpen ] = useState(showMenu)
    useEffect(() => {
        setOpen(showMenu);
    }, [showMenu]);

    /* Kebab Button */
    const iconColor = !buttonBgColor ? '' : parseInt(buttonBgColor.replace('base-', ''), 10) > 301 ? 'text-base-0' : 'text-base-content'
    const borderStyles = buttonOutline ? 'border border-base-300' : ''
    const menuPositionStyle = getMenuPositionStyle(openDirection)
    let wrapperClasses = `relative flex-shrink-0 flex items-center justify-center`
    wrapperClasses += ` ${outlineStyle}` /* remove line */
    
    /* Icon */
    const iconSize = parseInt(size.replace('px', ''), 10) - 4
    const IconComponent = IconoirIcons[icon] || IconoirIcons['MoreHoriz'];
    

    /* Drop Area */
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    const paddingStyles = padding ? `gap-${spacingMap[padding]}` : '';
    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const borderClasses = `border border-base-300` 
    let optionsClasses = `w-auto flex flex-col items-start justify-start mt-2 bg-${bgColor} z-5 absolute ${borderClasses} ${gapStyles} ${paddingStyles} ${cornerStyles} `
    optionsClasses += ` ${outlineStyle}` /* remove line */
    
    function getMenuPositionStyle(direction) {
        switch (direction) {
            case 'upward-right':
                return { bottom: '100%', left: 0 };
            case 'upward-left':
                return { bottom: '100%', right: 0 };
            case 'downward-left':
                return { top: '100%', right: 0 };
            default: // 'downward-right'
                return { top: '100%', left: 0 };
        }
    }

    
    return (
        <div 
        {...attributes} {...listeners} 
        {...junoAttributes} /* remove line */
        className={wrapperClasses}
        /* replace to '        onClick={() => setOpen(!open) }' */
    >
        <div className={`p-[2px] rounded bg-${buttonBgColor} hover:brightness-95 transition-all ${borderStyles}`}>
        {IconComponent && <IconComponent 
            style={{height: iconSize, width: iconSize, strokeWidth: parseInt(size.replace('px', ''), 10) > 20 ? 2 : 1}} 
            className={`flex-shrink-0 flex-grow-0 ${iconColor}`}
            />}</div>
        
        {open && (<div 
                    style={{ 
                        ...menuPositionStyle,
                        position: 'absolute', 
                        zIndex: 5000, 
                        minWidth: menuMinWidth
                    }}
                    className={optionsClasses}
                    >
                        {children}
        </div>)}
    </div>
);  
}



/* ignore rest */

KebabMenu.definitions = {
    apiName: 'Kebab',
    displayName: 'Kebab Menu',
    description: 'A dropdown select component allowing users to choose from a list of options. Features include customizable size, background color, and the ability to show or hide options.',
    ai_instructions: 'dropdown list. show/hides options on click',
    type: 'interactives',
    relativeSize: 'small',
    status: 'prototype',
    package: 'Pro',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        icon: {
            type: 'oneOf',
            options: ['MoreHoriz', 'MoreVert', 'MoreHorizCircle', 'MoreVertCircle', 'NavArrowDown', 'Plus', 'PlusCircle'],
            displayName: 'Icon',
            tile: '1/2',
            default: 'MoreHoriz'
        },
        buttonBgColor: {
            type: 'colors',
            options: [ 'base-0', 'base-100', 'base-50'],
            displayName: 'Button Color',
            tile: '1/2',
            default: 'none'
        },
        size: {
            type: 'oneOf',
            options: ['16px', '20px', '24px', '32px'],
            displayName: 'Size',
            tile: '1/2',
            default: '20px'
        },
        menuMinWidth: {
            type: "oneOf",
            options: ["fitContent", '120px', '160px', '240px', '320px'],
            displayName: "Menu Width",
            default: "fitContent", 
            tile: '1/2',
        },
        openDirection: {
            type: 'oneOf',
            options: ['downward-right', 'downward-left', 'upward-right', 'upward-left'],
            displayName: 'Direction',
            default: 'downward-right'
        }, 
        bgColor: {
            type: "colors",
            options: ["base-0", 'base-200', "base-100", "none"],
            displayName: "Background",
            tile: '1/2',
            default: "base-0"
        },
        corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg",],
            displayName: "Corners",
            tile: '1/2',
            default: "md"
        },
        padding: {
            type: "spacing",
            options: ['8x', '12px', '16px'],
            displayName: "Padding",
            tile: '1/2',
            default: null,
        },
        gap: {
            type: "gap",
            options: ["4px", '6px', '8x', '12px', '16px'],
            displayName: "Gap",
            tile: '1/2',
            default: null,
        },

        showMenu: {
            type: 'bool',
            displayName: 'Show Options',
            default: false
        },
        buttonOutline: {
            type: 'bool',
            displayName: 'Outline',
            default: true
        }
        
    }
}



/*KebabMenu.propTypes = {
    icon: PropTypes.oneOf(['MoreHoriz', 'MoreVert', 'MoreHorizCircle', 'MoreVertCircle', 'NavArrowDown', 'Plus', 'PlusCircle']),
    buttonBgColor: PropTypes.oneOf(['base-0', 'base-100']),
    buttonOutline: PropTypes.bool,
    size: PropTypes.oneOf(['16px', '20px', '24px', '32px']),
    showMenu: PropTypes.bool,
    menuMinWidth: PropTypes.oneOf(['fitContent', '120px', '160px', '240px', '320px']),
    padding: PropTypes.oneOf(['8x', '12px', '16px']),
    bgColor: PropTypes.oneOf(['base-0', 'base-100']),
    corners: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
    gap: PropTypes.oneOf(['4px', '6px', '8x', '12px', '16px']),
    openDirection: PropTypes.oneOf(['downward-right', 'downward-left', 'upward-right', 'upward-left']),
};
*/