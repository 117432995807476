import React from 'react';
import Icon from '../../../../../components/icons/Icon';

export default function SelectJustify({ propKey, options, value, onChange, label, direction='flex-col' }) {

  const optionsIcons = {
    'start': direction === 'flex-row' ? 'justify-left' : 'justify-top',
    'end': direction === 'flex-row' ? 'justify-right' : 'justify-bottom',
    'center': direction === 'flex-row' ? 'justify-horizontal-center' : 'justify-vertical-center',
    'between': direction === 'flex-row' ? 'justify-horizontal-space-between' : 'justify-vertical-space-between',
    'around': direction === 'flex-row' ? 'justify-horizontal-space-around' : 'justify-vertical-space-around',
    'evenly': direction === 'flex-row' ? 'justify-horizontal-space-evenly' : 'justify-vertical-space-evenly',
  };

  

  return (
    <>
    <span className='font-normal text-xs'>
      {label}
    </span>
    <div 
        className='items-center flex flex-row flex-grow w-full rounded
        p-0.5 bg-current-5 text-sm transition-all duration-150'
        style={{userSelect: 'none'}}
      >
        {options.map((option) => (
          <button
            key={option}
            className={`w-full pointer text-sm flex items-center justify-center rounded-sm py-1  transition-all duration-150 lowercase
            ${value === option ? `bg-base-0 ring-1 ring-base-200 `
            : `opacity-80 hover:opacity-100`}`}
            style={{
              fontWeight: 500,
              flexGrow: 1,
              zIndex: 2
            }}
            onClick={() =>onChange(option)}
          >
            <Icon name={optionsIcons[option]} width={16} height={16} fill={'currentColor'}/>
          </button>
        ))}
      </div>
      </>
  );
};
