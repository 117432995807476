import { Icon } from '../../../../../ui-kit/local/index';
import { Xmark } from 'iconoir-react';

export default function SmartIcon({ propKey, icons, currentIcon, onChange, label }) {

  return (
    <div className='flex flex-col w-full gap-1 items-end text-xs'>
    <div className='flex flex-row gap-1 justify-between items-end w-full'>
      {label}
      <button className='hover:underline opacity-70 hover:opacity-100 transition-all duration-75'
      onClick={() => onChange(propKey, 'none')}
      ><Xmark /></button>
    </div>
    <div className='flex flex-wrap w-full gap-1.5 text-xs bg-base-100 hover:bg-transparent ring-1 ring-base-200 p-1.5 rounded-md transition-all duration-150
    '>
        {icons
        .filter(icon => icon !== 'none' && icon !== 'auto')
        .map((icon, index) => (
          <div className={`w-5 h-5 ${icon === currentIcon ? 'text-primary bg-white rounded scale-110 ring-1 ring-base-300' : 'text-base-700'} flex items-center justify-center hover:scale-110
          transition-all duration-75 hover:z-10 relative group
          `} 
          key={index}
          onClick={() => onChange(propKey, icon)}>
            <Icon 
              icon={icon} 
              key={index} 
            />
            <div className='absolute -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full opacity-0 group-hover:opacity-100 
            text-xs whitespace-nowrap bg-base-100 text-base-700 p-1 rounded-md pointer-events-none
            ' 
            >{icon}</div>
          </div>
          
        ))  
        }
        
      </div>
      
      </div>
  );
};
