import { useContext, useEffect, useState } from "react";
import { EditorContext } from "../EditorContext";
import { Alert } from "ui-kit/exports/react";

export default function Notifications() {
    const { notifications, setNotifications } = useContext(EditorContext);
    // console.log(notifications)
    // Function to remove a notification
    const removeNotification = (id) => {
        const newNotifications = [...notifications].filter(notification => notification.id !== id);
        setNotifications(newNotifications);
    };

    const lastNotification = notifications[notifications.length - 1];
    return (
        <div className="flex" style={{ zIndex: 2000 }}>
            {lastNotification && (
                <><Notification
                    
                    id={lastNotification.id}
                    message={lastNotification.message}
                    state={lastNotification.state} // passing state
                    timeout={lastNotification.timeout}
                    removeNotification={() => removeNotification(lastNotification.id)}
                />
                
            </>
            )}
            
        </div>
    );
}

function Notification({ id, message, state, timeout, removeNotification }) {
    useEffect(() => {
        const timer = setTimeout(() => {
            removeNotification(id); // Pass the id here
        }, timeout);

        // Cleanup function
        return () => clearTimeout(timer);
    }, [id, timeout, removeNotification])
    
    return (

        <Alert
            text={message}
            size="small"
            icon="bell"
            type={state}
        />);
        
    
}

{/*
        <div className={`text-right h-7  whitespace-nowrap text-xs font-medium rounded-md flex-row gap-2 px-2.5 items-center justify-end
        ${getNotificationStyle()}`}>
            {message}

            <div className="flex items-center text-right justify-center rounded-md cursor-pointer hover:bg-slate-200 transition-colors -mr-1">
            <Bell width={16} height={16} className="stroke-[2]"/>
            </div>  
        </div>*/}