import { useContext, useEffect, useState } from "react";
import UserContext from "../../UserContext";

export default function UsageSettings() {
    const { user, setUser } = useContext(UserContext);

    const fetchUserData = async () => {
        try {
            const response = await fetch('/api/user/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}` // Assuming token is still valid
                },
                body: JSON.stringify({ id: user.id })
            });
            const data = await response.json();
            if (response.ok) {
                setUser({...data, token: user.token})
            } else {
                console.log('failed to refresh user')
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    // useEffect to run once on component mount
    useEffect(() => {
        fetchUserData();
    }, []);

    
    return (
        <div className="flex flex-col h-full w-full items-start gap-3 text-sm">
        <h2 className="text-lg font-semibold"
        >Usage</h2>
                {user.currentMonthUsage && <UsageBlock user={user}/>}
            
        </div>
    )
}

export function UsageBlock({ user }) {
    const { currentMonthUsage, usagesoftlimit, usagehardlimit } = user;
    const currentUsage = Math.round((currentMonthUsage?.total_cost * 100) || 0) / 100
    // Calculate the usage percentage
    const usagePercentage = Math.round((currentUsage / usagehardlimit) * 100,2)

    // Determine the color based on the usage thresholds
    let progressBarColor = 'bg-green-600'; // Default green for under soft limit
    if (currentUsage >= usagesoftlimit && currentUsage < usagehardlimit) {
        progressBarColor = 'bg-yellow-400'; // Yellow if between soft and hard limit
    } else if (currentUsage >= usagehardlimit) {
        progressBarColor = 'bg-red-500'; // Red if exceeding hard limit
    }
    
    return (
        <div className="w-full flex flex-col gap-2">
            <div className="w-full flex flex-row justify-between items-end font-medium">
                Free AI credits used
                <span className="text-xs flex-shrink-0">{(currentUsage / usagehardlimit*100).toFixed(0)}%</span>
            </div>
            <div className="h-3 bg-slate-200 rounded-full overflow-hidden">
                <div className={`h-full ${progressBarColor} rounded-full`} style={{ 
                    width: `${Math.min(100, usagePercentage)}%`,
                    minWidth: '4px'
                    }}></div>
            </div>
        </div>
    );
}
