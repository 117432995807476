import React from 'react';
import Icon from '../../../../../components/icons/Icon';
import { SegmentedSwitch } from '../../../../../ui-kit/exports/react';

export default function SelectWidth({ propKey, options, value, onChange, label }) {

  const allOptions = [
    { value: 'auto', label: 'auto' },
    { value: '1/2', label: '1/2' },
    { value: 'full', label: 'full' },
    { value: '100%', label: 'full' },
  ]
  const modifiedOptions = options.map(value => {
    const foundOption = allOptions.find(option => option.value === value);
    return foundOption || { 
      value: value, 
      label: value.charAt(0).toUpperCase() + value.slice(1) 
    };
  });
  
  return (
    <>
    <SegmentedSwitch
        size={'small'}
        width={'full'}
        label={label}
        options={modifiedOptions}
        value={value}
        onChange={(value) => onChange(propKey, value)}
      />
      {/*
    <div 
        className='items-center flex flex-row flex-grow w-full rounded-md p-0.5  bg-base-100 hover:bg-base-200 text-sm transition-all duration-150
        ring-1 ring-base-200
        '
        style={{userSelect: 'none'}}
      >
        {widthOptions.map((width) => (
          <button
            key={width}
            className={`w-full pointer text-sm flex items-center justify-center rounded-md py-1  transition-all duration-150 lowercase
            ${currentWidth === width ? `bg-base-0 ring-1 ring-base-200 `
            : `opacity-80 hover:opacity-100`}`}
            style={{
              fontWeight: 500,
              flexGrow: 1,
              zIndex: 2
            }}
            onClick={() => handleWidthSelect(width)}
          >
            {width}
          </button>
        ))}
      </div>*/}</>
  );
};
