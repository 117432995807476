import { useContext, useState } from "react";
import { Alert, Button, Icon, MiniSnippet } from "../../../../../ui-kit/exports/react";
import { makeTailwindVars } from "./helpers/makeTailwindVars";
import { makeCssVars } from './helpers/makeCssVars';
import { safelist } from './helpers/safelist';
import getFontEmbedURL from "./helpers/getFontEmbedURL";
import { EditorContext } from "../../../EditorContext";

export default function Install({appliedTheme}) {


    const { effectiveVariables, project } = useContext(EditorContext)
    const iconSet = appliedTheme.iconSet || 'iconoir'

    const varObject = effectiveVariables.reduce((acc, current) => {
      acc[current.api_name] = current;
      return acc;
    }, {})
    
    
    // add iconset
    varObject.iconset = {value: iconSet}
    const projectLogo = project?.assets?.find(a =>a.api_name === 'logo')?.file_url
    const projectSymbol = project?.assets?.find(a =>a.api_name === 'symbol')?.file_url
    if (projectLogo) {varObject.logo = {value: projectLogo}}
    if (projectSymbol) {varObject.symbol = {value: projectSymbol}}
    

    const tailwindVars = makeTailwindVars({varObject: varObject})
    const cssVars = makeCssVars({varObject: varObject})

    const handleDownload = async () => {
        try {
            // Fetch the ZIP file from the server
            const response = await fetch('/download-zip', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/zip',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            // Convert the response to a Blob
            const blob = await response.blob();

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'junokit.zip'; // Specify the filename

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger the download by clicking the link
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href); // Revoke the object URL
        } catch (error) {
            console.error('Error downloading the ZIP file:', error);
        }
    };

    const tabs = [
        {
            order: 1,
            label: 'Install components', 
            description: 'extract .zip into your React project folder',
            instruction: 
            <>
                <Button 
                    size={'small'}
                    text="Download UI Kit"
                    onClick={handleDownload}
                />
            </>
        },
        {
            order: 2,
            label: 'Install dependencies', 
            description: 'Components depend on some external libraries, so you may need to add them to your project',
            snippet: `npm i date-fns iconoir-react react-icons recharts`
        },
        {   order: 3,
            label: 'Configure Tailwind CSS', 
            description: `Configure the theme object in your tailwind.config.js file.`,
            snippet: tailwindVars
        }, 
        {
            order: 4,
            label: 'Customize Theme Variables',
            description: `Add the following CSS variables to your global stylesheet. These variables are used in your currently selected theme${appliedTheme?.display_name ? ` (${appliedTheme.display_name})` : ''}.`,
            snippet: cssVars
        },
        {/*
        {
            order: 5,
            label: 'Add Brand Assets',
            description: `Add the logo files to your project in the public/brand folder.`,
            snippet: null,
            instruction: <>
            <Button size={'small'} text='Brand Assets' rightIcon={'download'} />
            <MiniSnippet 
                width='full'
                size={'small'}
                hasOutline={false}
                bgColor={'base-50'}
                text={`your_project/
├── public/
│   ├── brand/
│   |   ├── logo.svg
│   |   ├── symbol.svg
│   |   ├── logo_inverted.svg
│   |   ├── symbol_inverted.svg
├── src/
│   ├── App.jsx/
│   ├── styles.css/
│   ├── other
`}
            />
            </>
        }*/}
    ]

    return (
        <div className="w-full flex flex-col gap-3 h-full pl-3 overflow-y-scroll" 
        style={{ height: '100%', maxHeight: '100%', minHeight: '100%'}}>
        <div className="mb-5 text-xs text-gray-500">
            <Alert text='JUNO UI-Kit uses Tailwind CSS for styling and TypeScript. You need to install Tailwind CSS to use JUNO Design System.'
            size='small' type='info' icon='auto' style={'light'}
            onActionClick={() => window.open('https://tailwindcss.com/docs/installation', '_blank')}
            actionText='Install Tailwind CSS'
            actionType={'link'}
            />
        </div>
        
        {tabs.map((tab, index) => (
        <div className="w-full flex flex-row gap-6" key={index}>
            {/* Number and Bar */}
            <div className="flex flex-col flex-grow-0 flex-shrink-0 items-center gap-3">
                <div className="w-6 h-6 rounded-md ring-[0.5px] ring-base-100 bg-base-50 font-semibold flex items-center justify-center text-2xs">
                    {tab.order}
                </div>
                
                <div className={`flex-grow w-px bg-base-100 ${index == tabs.length-1 ? 'hidden' : ''}`}></div>
            </div>

            {/* Title & Description */}
            <div className="flex flex-col w-[280px] flex-shrink-0 gap-1 pb-2 items-start mr-12">
                <div className="text-sm font-semibold text-base-content">
                    {tab.label}
                </div>
                <div className="text-sm text-slate-600 font-normal">
                    {tab.description}
                </div>
            </div>

            {/* Snippet Block */}
            <div className="flex flex-col flex-grow gap-2 pb-8 items-start text-sm w-full max-w-[720px]"> 
                {tab.snippet &&
                <MiniSnippet 
                    text={tab.snippet} 
                    width='full'
                    size={'small'}
                    hasOutline={false}
                    bgColor={'base-50'}
                    maxHeight={320}
                    />}
                {tab.instruction}
            </div>

        </div>))}
          
        </div>
    )
}



/*
 
        {
            order: 5,
            label: 'Add Brand Assets',
            description: `Add the logo files to your project in the public/brand folder.`,
            snippet: null,
            instruction: <>
            <Button size={'small'} text='Brand Assets' rightIcon={'download'} />
            <MiniSnippet 
                width='full'
                size={'small'}
                hasOutline={false}
                bgColor={'base-50'}
                text={`your_project/
├── public/
│   ├── brand/
│   |   ├── logo.svg
│   |   ├── symbol.svg
│   |   ├── logo_inverted.svg
│   |   ├── symbol_inverted.svg
├── src/
│   ├── App.jsx/
│   ├── styles.css/
│   ├── other
`}
            />
            </>
        } */