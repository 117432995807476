import React, { useState, useEffect, useContext } from 'react';
import ProjectCard from './ProjectCard';
import { Archive, Bookmark, Star } from 'iconoir-react';
import { Pagination } from '../../ui-kit/exports/react';
import { prepareProject } from './helpers';
import UserContext from '../../UserContext';

export default function Projects({
  projects=[],
  openProject,
  renameProject,
  dataLoaded,
  archiveProject,
  updateFavoriteStatus,
  selectedTab,
  restoreProject,
  sets, 
  addProject
}) {
  
  const {user} = useContext(UserContext);
  const userId = user.id;
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 12

  // Calculate total pages
  const totalPages = Math.ceil(projects.length / projectsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    
    setCurrentPage(page);
  };

  // Get current projects for the page
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

  function addEmptyProject() {
    const setName = 'empty'
    const selectedSet = sets?.find(s => s.name == setName)
    // console.log(selectedSet)
    const projectName = setName
    if (!userId || !selectedSet) return
    // console.log('calling', userId, selectedSet, setName, projectName)
    const payload = selectedSet && prepareProject(userId, selectedSet, setName, projectName)
    // console.log('payload', payload)
    payload && addProject(payload);
  }



  // Return null if data isn't loaded yet
  if (!projects || !dataLoaded) return null;

  // Render empty state if no projects are available
  if (projects.length === 0) return (
    <EmptyFolder 
      selectedTab={selectedTab} 
      addProject={addEmptyProject}
    />
  );

  return (
    <>
      <div className='grid gap-2 grid-cols-[repeat(auto-fill,minmax(320px,1fr))] h-auto w-full'>
        {currentProjects
          .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
          .map(project => (
            <ProjectCard 
              key={project.id}
              project={project}
              addProject={addProject}
              currentTab="Projects"
              openProject={openProject}
              renameProject={renameProject}
              archiveProject={archiveProject}
              restoreProject={restoreProject}
              updateFavoriteStatus={updateFavoriteStatus} 
            />
        ))}
      </div>
      {projects.length > 0 && totalPages > 1 && (
        <div className='flex flex-row justify-center items-center w-full mt-5 text-xs'>
          <Pagination 
            type='mini'
            textSize={'sm'}
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}

function EmptyFolder({selectedTab, addProject}) {

  const contentMap = {
    'All': {
      title: 'No apps yet',
      description: 'Create a new project to get started',
      action: 'Create App',
      onclick: () => addProject()
    },
    'Favorites': {
      icon: <Star height={48} width={48} className='text-primary stroke-[1.2px]'/>,
      title: 'No favorite projects yet',
      description: 'Mark projects as favorites to access them here',
      
    },
    'Archive': {
      icon: <Archive  height={48} width={48} className='text-primary stroke-[1.2px]'/>,
      title: 'No archived projects yet',
      description: 'Archive projects will be stored here', 
    }
  }
  return (
    <div className='flex flex-col w-full items-center justify-center px-4 '>
      <div className='flex flex-col w-full min-h-[360px] bg-base-50 items-center justify-center gap-4 rounded-lg'>
          {contentMap[selectedTab]?.icon}
          <h2 className='font-medium text-2xl'
          >{contentMap[selectedTab]?.title}</h2>
          <p className='text-sm text-base-500'
          >{contentMap[selectedTab]?.description}</p>
          {contentMap[selectedTab]?.action &&
          <button className='bg-primary text-sm font-medium text-base-0 px-3 py-1.5 rounded-lg mt-3
          hover:brightness-110 transition-all duration-75 active:brightness-100'
          onClick={contentMap[selectedTab]?.onclick}
          >
            {contentMap[selectedTab]?.action}
          </button>}
      </div>
    </div>
  )

}
