export function makeCssVars({ varObject }) {
  
    
    const colorsArray = `
    --primary: ${varObject['primary']?.value};
    --primary-content: ${varObject['primary-content']?.value};
    --primary-focus: ${varObject['primary-focus']?.value};
    --primary-surface: ${varObject['primary-surface']?.value};
    
    --accent: ${varObject['accent']?.value};
    --accent-content: ${varObject['accent-content']?.value};
    --accent-focus: ${varObject['accent-focus']?.value};
    --accent-surface: ${varObject['accent-surface']?.value};
    
    --base-0: ${varObject['base-0']?.value};
    --base-50: ${varObject['base-50']?.value};
    --base-100: ${varObject['base-100']?.value};
    --base-200: ${varObject['base-200']?.value};
    --base-300: ${varObject['base-300']?.value};
    --base-400: ${varObject['base-400']?.value};
    --base-500: ${varObject['base-500']?.value};
    --base-600: ${varObject['base-600']?.value};
    --base-700: ${varObject['base-700']?.value};
    --base-800: ${varObject['base-800']?.value};
    --base-900: ${varObject['base-900']?.value};
    --base-content: ${varObject['base-content']?.value};

    --info: ${varObject['info']?.value};
    --info-content: ${varObject['info-content']?.value};
    --info-focus: ${varObject['info-focus']?.value};
    --info-surface: ${varObject['info-surface']?.value};

    --success: ${varObject['success']?.value};
    --success-content: ${varObject['success-content']?.value};
    --success-focus: ${varObject['success-focus']?.value};
    --success-surface: ${varObject['success-surface']?.value};

    --warning: ${varObject['warning']?.value};
    --warning-content: ${varObject['warning-content']?.value};
    --warning-focus: ${varObject['warning-focus']?.value};
    --warning-surface: ${varObject['warning-surface']?.value};
    
    --error: ${varObject['error']?.value};
    --error-content: ${varObject['error-content']?.value};
    --error-focus: ${varObject['error-focus']?.value};
    --error-surface: ${varObject['error-surface']?.value};
    `;
    
  
    const cornersArray = `
    --border-radius-xs: ${varObject['border-radius-xs']?.value};
    --border-radius-sm: ${varObject['border-radius-sm']?.value};
    --border-radius-base: ${varObject['border-radius-base']?.value};
    --border-radius-md: ${varObject['border-radius-md']?.value};
    --border-radius-lg: ${varObject['border-radius-lg']?.value};
    --border-radius-xl: ${varObject['border-radius-xl']?.value};
    --border-radius-2xl: ${varObject['border-radius-2xl']?.value};
    --border-radius-3xl: ${varObject['border-radius-3xl']?.value};`;
    
  
    const mainFont = varObject['mainFont']?.value || 'Inter';
    const logo = varObject['logo']?.value
    const symbol = varObject['symbol']?.value
  
    const cssVars = `${fontMap[mainFont] || ''}\n
    
:root {
    --iconset: ${varObject['iconset']?.value};
    --mainFont: ${mainFont}; 
    ${logo ? `--logo: url(${logo});` : ``}${symbol ? `\n    --symbol: url(${symbol});` : ``}
    ${colorsArray}
    ${cornersArray}    
}
    `;
  
    return cssVars;
  }
  

  const fontMap = {
    'Geologica': `@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@300;400;500;600;700&display=swap');`,
    'Inter': `@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')   ;`,
    'Merriweather': `@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap');`,
    'Poppins': `@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');`,
    'Handlee': `@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');`,
    'Montserrat': `@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,100&display=swap');`,
    'Lato': `@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;600;700;800;900&display=swap');`,
    'Geist': `@import url('https://fonts.googleapis.com/css2?family=Geist:wght@400;700&display=swap');`,
  }