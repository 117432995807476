import { v1 as uuidv1 } from 'uuid';
import * as IconoirIcons from 'iconoir-react';
import { useContext } from 'react';
import { EditorContext } from '../../../EditorContext';
import { createNewFrame, createNewIds } from '../../../../../utilities/helpers';

export default function AddPlus({  columnWidth, page }) {
    const {handleAction, selector, setNotifications, notifications } = useContext(EditorContext)

    function addFrame() {
        const frames = page.frames
          const insertIndex = frames?.length > 0 
          ? Math.max(...frames.map(frame => frame.index)) + 1 // add as last to the page
          : 1;
      
          let newFrame = createNewFrame({ ...page, type: 'page' }, [], 'Untitled frame', { width: 800, height: 800 });
          newFrame = { ...newFrame, index: insertIndex };;
          
          handleAction({type: 'INSERT_FRAME', frame: newFrame });
        
        
        const newNotification = {
          id: uuidv1(), 
          message: 'Page added', 
          timeout: 2000
        };
      
        // Update the notifications array
        setNotifications([...notifications, newNotification]);
  
      };
  
  
      const duplicateFrame = (frame) => {
        const newId = uuidv1(); // will use for frame.id and object.frame
        
        let newObjects = [...frame.objects]; // copy existing objects
        
        newObjects = createNewIds(newObjects, frame.id, newId); // create new Ids for these objects
        newObjects = newObjects.map(obj => ({...obj,frame: newId})); // update frame property
      
        const newFrame = {
            ...frame,
            id: newId,
            index: frame.index + 1,
            objects: newObjects
        };
        
        handleAction({type: 'INSERT_FRAME', frame: newFrame });
      }
  
      function handleClick() {
        let frames = selector.page?.frames || []
        frames = frames.sort((a, b) => a.index - b.index)
        const lastFrame = frames[frames.length - 1]
        if (lastFrame) duplicateFrame(lastFrame)
        else addFrame()
      }
    return (
        <div 
            className={`pointer gap-2 flex-shrink-0 flex flex-col items-center justify-center transition-all
            rounded-lg text-base-500 hover:text-base-600  transition-all duration-75
            opacity-100 bg-base-50 hover:bg-base-100`}
            style={{
                width: columnWidth - 4,
                height: columnWidth * 820 / 1440 - 4,
            }} 
            onClick={addFrame}
        >
         
                <IconoirIcons.Plus  height={48} width={48} style={{strokeWidth: 1}} 
                className='hover:scale-110 transition-all duration-75 hover:stroke-[1.2px]' />
         
        </div>
        
    );
  }